import _ from "lodash";
import React from "react";
import DataTable from "react-data-table-component";
import Spinner from 'react-bootstrap/Spinner';
import ManageDataSet from "../../requestProcessors/manageDataSets";
import {AddIcon} from "../../utils/IconContainer";
import "../../stylesheets/views/dataset.css";
import "../../stylesheets/customFileInput.css";
import {Link} from "react-router-dom";
import {SimpleSelect} from "react-selectize";
import NEXDCP30Parameters from "../../utils/NEXDCP30Parameters";
import {Row, Col} from "react-bootstrap";

class DataSets extends React.Component {
  constructor(props) {
    super(props);
    this.canvasHeight = this.props.canvasHeight;
    this.canvasWidth = this.props.canvasWidth;
    this.default = {
      columns: [
        { name: 'Name', selector: rows => <Link to={"/data-selection?dataset="+rows.location}>{rows.name}</Link>, sortable: true},
        { name: 'Location', selector: 'location'},
        { name: "Tags", selector: 'tags', cell: (row) => {
          return _.map(row.tags.split(","), i => {
            return <span className={'data-tag'}>{i.toUpperCase()}</span>;
          })
          }
        },
        { name: "Data Added", selector: 'created', sortable: true}
      ],
      NEXDCP30Parameters: {
        experiment: null,
        variable: null,
        model: null,
        year: null,
        month: null
      }
    };
    this.NEXDCP30SelectedParameters = _.clone(this.default.NEXDCP30Parameters);
    this.state = {
      data: [],
      // below state variables for data file upload
      selectedFileToUpload: null,
      uploadStatus: 1,
    };
    this.datasetProcessor = new ManageDataSet();
    this.alertController = this.props.alertController;
  }

  componentDidMount() {
    this.retrieveDataSetList();
  }

  retrieveDataSetList = () => {
    this.datasetProcessor.getDataSetList().then(data => {
      this.setState({data: data})
    });
  };

  onFileSelect = (event) => {
    let dataFile = event.target.files[0];
    // console.log(dataFile);
    this.setState({
      selectedFileToUpload: dataFile,
      uploadStatus: 0, // data file is just selected, not uploaded yet
    });
    this.dataUploadLabelRef.innerText = dataFile.name;
  };

  onDataFileUpload = () => {
    let dataFile = new FormData();
    dataFile.append("file", this.state.selectedFileToUpload);
    this.dataUploadActionButtonsRef.style.display = "none";
    this.dataUploadLoadingRef.style.display = "";
    this.datasetProcessor.saveDataFile(dataFile).then(res => {
      if (res.data.status === "success" && res.data.relative_file_path !== "") {
        this.alertController.closeAlert();
        this.retrieveDataSetList();
        this.alertController.setSuccessAlert("Success", "The data file is uploaded");
      }
    });

    this.setState({
      uploadStatus: 1,
      selectedFileToUpload: null
    });
  };

  onNEXDCPAttributeChange = (attribute, value) => {
    this.NEXDCP30SelectedParameters[attribute] = value !== undefined? value.value: null;
  };


  dataUploadPopup = () => {
    let alertProps = {title: "Data Upload"};
    let alertContent = <div className={"data-upload-popup"}>
      <input className="custom-file-input" type="file" id="files" name="files[]" onChange={this.onFileSelect} multiple/>
      <label className="custom-file-label"
             htmlFor="files"
             id="file-name-label"
             ref={ref => this.dataUploadLabelRef=ref}
      >{""}</label>
      <div className={"action-buttons"} ref={ref=>this.dataUploadActionButtonsRef=ref}>
        <button className={"buttonR btn btn-primary"} onClick={() => this.onDataFileUpload()}>Upload</button>
        <button className={"btn btn-danger"} onClick={() => this.alertController.onAlertCancel()}>Cancel</button>
      </div>
      <div className={"spinner-loading"}
           ref={ref=>this.dataUploadLoadingRef=ref}
           style={{display: "none"}}
      >
        <Spinner animation="border" />
      </div>
    </div>;
    this.alertController.setAlert(alertProps, alertContent, "w800");
  };

  addNEXDCP30Popup = () => {
    let renderOptions = (options) => {
      return _.map(options, dimension => {
        return <option value={dimension}>{dimension.toString()}</option>
      });
    };

    let renderMonths = () => {
      let months = [ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December" ];
      return _.map(months, (dimension, index) => {
        return <option value={index+1}>{dimension.toString()}</option>
      });
    };

    let alertProps = {title: "Add NEX DCP30 Data"};
    let alertContent = <div className={"data-upload-popup"}>
      <Row>
        <Col>
          <div className={"attribute-selection"}>
            <SimpleSelect
              placeholder="Select experiment"
              onValueChange={value => this.onNEXDCPAttributeChange("experiment", value)}
            >
              {renderOptions(NEXDCP30Parameters.experiment)}
            </SimpleSelect>
          </div>
          <div className={"attribute-selection"}>
            <SimpleSelect
              placeholder="Select model"
              onValueChange={value => this.onNEXDCPAttributeChange("model", value)}
            >
              {renderOptions(NEXDCP30Parameters.model)}
            </SimpleSelect>
          </div>
          <div className={"attribute-selection"}>
            <SimpleSelect
              placeholder="Select a year"
              onValueChange={value => this.onNEXDCPAttributeChange("year", value)}
            >
              {renderOptions(_.range(NEXDCP30Parameters.year.min, NEXDCP30Parameters.year.max+1, 1))}
            </SimpleSelect>
          </div>
        </Col>
        <Col>
          <div className={"attribute-selection"}>
            <SimpleSelect
              placeholder="Select variable"
              onValueChange={value => this.onNEXDCPAttributeChange("variable", value)}
            >
              {renderOptions(NEXDCP30Parameters.variable)}
            </SimpleSelect>
          </div>
          <div className={"attribute-selection"}>
            <SimpleSelect
              placeholder="Select a month"
              onValueChange={value => this.onNEXDCPAttributeChange("month", value)}
            >
              {renderMonths()}
            </SimpleSelect>
          </div>
        </Col>
      </Row>
      <div className={"action-buttons"}>
        <button className={"buttonR btn btn-primary"} onClick={this.onNEXDCP30DataFetch}>Fetch Data</button>
        <button className={"btn btn-danger"} onClick={() => this.alertController.onAlertCancel()}>Cancel</button>
      </div>

    </div>;
    this.alertController.setAlert(alertProps, alertContent, "w800");
  };

  onNEXDCP30DataFetch = () => {
    this.datasetProcessor.fetchNEXDCP30(this.NEXDCP30SelectedParameters).then(data => {

    });
    this.NEXDCP30SelectedParameters = _.clone(this.default.NEXDCP30Parameters);
    this.alertController.onAlertCancel();
  };

  render() {
    return(
      <div style={{ height: this.canvasHeight, width: this.canvasWidth}} >
        <button className={"btn btn-primary dataset-add"} onClick={this.addNEXDCP30Popup}>Add NEX DCP30 <AddIcon/></button>
        <button className={"btn btn-primary dataset-add"} onClick={this.dataUploadPopup}>Data Upload <AddIcon/></button>
        <DataTable
          // title={"Datasets"}
          columns={this.default.columns}
          data={this.state.data}
          selectableRows={true}
          defaultSortAsc={false}
          defaultSortField={"created"}
        />
      </div>
    )
  }
}

export default DataSets;
