import _ from "lodash";
import React from "react";
import "../../stylesheets/SliderNavigator.css";
import DataServerConfig from "../../manage/requestProcessor/dataServer";


class SliderNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.defaultSelected = this.props.selected || 0;
    this.activeClassName = "active";
    this.state = {
      width: this.props.width,
      height: this.props.height,
      count: this.props.count || 60
    }
  }

  onSlideClick = (e, index) => {
    _.forEach(e.target.parentNode.parentNode.childNodes, n => n.classList.remove(this.activeClassName));
    e.target.parentNode.classList.add(this.activeClassName);
    this.props.onTimeChange(index);
  };

  onMouseEnterSlide = (index) => {
    // this.tooltipInfo.innerText = this.props.onHoverInfo(index);
  };

  onMouseLeaveSlide = (index) => {
    // this.tooltipInfo.innerText = this.props.info;
  };

  drawSlides = () => {
    let image_url = DataServerConfig.Server + DataServerConfig.ServerAPI.FetchNEXDCP30Thumbnail.path;
    return _.map(this.props.timestamps, (item, index) => {
      let abs_url = image_url+"?file_name="+item.filePrefix+"&index_name="+item.filename;
      return <div className={"slide"}
        onClick={(e) => this.onSlideClick(e, index)}
        onMouseEnter={() => this.onMouseEnterSlide(index)}
        onMouseLeave={() => this.onMouseLeaveSlide(index)}
      >
        <div
          className={"slide-background"}
          style={{backgroundImage: "url("+abs_url+")"}}
        >
        </div>
        <div className={"label"}>
          {item.date.substring(0, item.date.length-3) + "-[" + item.index + "]"}
        </div>
      </div>
    })
  };


  render() {
    return(
      <div
        className={"slider-navigator"}
        ref={ref => this.navigatorContainer = ref}
        style={{height: this.state.height}}
      >
        <div className={"slideshow"}>
          {this.drawSlides()}
        </div>
        {/*<span*/}
        {/*  ref={ref => this.tooltipInfo = ref}*/}
        {/*  className={"slider-tooltip"}*/}
        {/*>{this.props.info}</span>*/}
      </div>
    )
  }
}

export default SliderNavigator;