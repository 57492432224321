import React from "react";

/*
 * modified codes from
 * https://www.quirksmode.org/js/cookies.html
*/

class CookieManager {
  constructor(props) {
    this.cookie = document.cookie;
    this.userCookieName = "username";
  }

  retrieveCookieString() {
    this.cookie = document.cookie;
  }

  createCookie(name,value,days) {
    this.retrieveCookieString();
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 *1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  readCookie(name) {
    this.retrieveCookieString();
    let nameEQ = name + "=";
    let ca = this.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1,c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }
    return null;
  }

  eraseCookie(name) {
    this.createCookie(name,"",-1);
  }

  getUsername() {
    return this.readCookie(this.userCookieName);
  }

}

export default CookieManager;
