import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "react-selectize/themes/base.css"; // react-selectize styles
import "react-selectize/themes/index.css";
import "react-selectize/themes/default.css";
import "./stylesheets/override.css";
import "./stylesheets/colorPicker.css";
import "./stylesheets/input.css";

import SideNavigation from "./components/navigation/SideNavigation";
import Routes from "./Routes";
import SideNavigationIcon from "./components/navigation/SideNavigationIcon";

function App() {
  const defaultPageTitle = "Home";
  const [pageTitle, setPageTitle] = useState(defaultPageTitle);
  const [alert, setAlert] = useState(false);
  const onRouteChangeComponentMount = (title, args = {}) => {
    setPageTitle(title);
  };

  return (
    <BrowserRouter key={"app-browserrouter"}>
      <div className="App">
        <Container fluid={"xs"}>
          <Row className={"main"}>
            {/* <Col lg={0.5} md={0.5} className={"side-icon-navigation"}>
              <SideNavigationIcon />
            </Col> */}
            <Col lg={12} md={12} className={"content-container content"} >
              <div className={"page-container"}>
                <Routes
                  onRouteChangeComponentMount={onRouteChangeComponentMount}
                  setAlert={setAlert}
                />
              </div>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  /!* left side nav bar *!/*/}
          {/*  /!*<Col className={"side-nav-bar"} sm={2}>*!/*/}
          {/*  /!*  <SideNavigation/>*!/*/}
          {/*  /!*</Col>*!/*/}
          {/*  /!* right side content *!/*/}
          {/*  <Col className={"content"} sm={12}>*/}
          {/*    /!*<div className={"header"}>{pageTitle}</div>*!/*/}
          {/*    <div className={"page-container"}>*/}
          {/*      /!* main routes *!/*/}
          {/*      <Routes onRouteChangeComponentMount={onRouteChangeComponentMount} setAlert={setAlert}/>*/}
          {/*    </div>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Container>
        {alert}
      </div>
    </BrowserRouter>
  );
}

export default App;
