import _, { isUndefined } from "lodash";
import { DropdownButton, Form } from "react-bootstrap";
import ChartWrapper from "../wrapper/ChartWrapper";
import "leaflet/dist/leaflet.css";
import "../../stylesheets/charts/geoSpatial.css";
import { UserIcon_md_white, UserIcon_md_blue } from "../../utils/IconContainer";

import {
  Map,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  withLeaflet
} from "react-leaflet";
import {latLng, latLngBounds} from "leaflet";
import Control from "react-leaflet-control";
import * as topojson from "topojson-client";
import ManageDataSet from "../../requestProcessors/manageDataSets";
import ColorRangeLegend from "./legends/ColorRange";
import { IconDefault } from "leaflet/src/layer/marker/Icon.Default";
import AnnotationIcon from "../../utils/LeafletIconContainer";
import ManageUserData from "../../requestProcessors/manageUserData";
import CookieManager from "../../utils/CookieManager";
import stateGeoLocation from "../../data/geoData/state-geo-location";
import regionGeoLocation from "../../data/geoData/region-geo-location";
import regionDivisions from "../../data/geoData/us-region-divisions";
import USStates from "../../data/geoData/us-states";
import { Container, Row, Col } from "react-bootstrap";

import VectorGridDefault from "react-leaflet-vectorgrid";

// [DY] UNknown issue calling GeoJSON2 from the server
import geoJson2_data from "../../data/geoData/nca_mask.json";

// import L from 'leaflet';
const VectorGrid = withLeaflet(VectorGridDefault);

const React = require("react");

class GeoSpatial extends React.Component {
  constructor(props) {
    super(props);
    this.default = {
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [102, 0.5],
            },
            properties: {
              prop0: "value0",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [
                [102, 0],
                [103, 1],
                [104, 0],
                [105, 1],
              ],
            },
            properties: {
              prop0: "value0",
              prop1: 0,
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [100, 0],
                  [101, 0],
                  [101, 1],
                  [100, 1],
                  [100, 0],
                ],
              ],
            },
            properties: {
              prop0: "value0",
              prop1: {
                this: "that",
              },
            },
          },
        ],
      },
      layerOptions: {
        Standard: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        WikiMedia: "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png",
        Humanitarian: "http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        "OSM France": "http://a.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
      },
      interactionModes: {
        select: "select",
        inspect: "inspect",
        annotate: "annotate",
      },
      views: { region: "region", state: "state", county: "county" },
      position: {
        lat: this.props.lat || 34.102707993174874,
        lng: this.props.lng || -101.63658142089845,
        zoom: this.props.zoom || 4,
      },
      mapSelection: {
        region: null,
        state: null,
      },
      labelInProps: { region: "name", state: "name", county: "NAME" },
      // [DY]: added 2020.12.23
      tilestacheLayerOptions: {
        type: "protobuf",
        url: "http://idev_server01.ncat.edu/nex_dcp30_49991/{z}/{x}/{y}.pbf",
        buffer: 500,
        vectorTileLayerStyles: {
          default: function (properties, zoom) {
            return {
              color: properties.color,
              stroke: true,
              // color: "black",
              weight: 0.1,
              margin: "0 0 0 0",
              fill: true,
              fillColor: properties.color,
              fillOpacity: 0.7,
            };
          },
        },
        updateInterval: 1000,
        subdomains: "abcd",
        zIndex: 2,
        interactive: true,
        updateWhenIdle: true,
        updateWhenZooming: false,
        getFeatureId: function (f) {
          return f.properties.osm_id;
        },
        onMouseover: (e) => {
          // console.log(e);
          // // this.onGeoJSONOver(e);
          // e.layer.setStyle({ opacity: 0.8 });
          // e.layer.setStyle({ fillOpacity: 0.8 });
          // e.layer.setStyle({ color: "black" });
          // let newMarkerPosition = [e.latlng.lat, e.latlng.lng];
          // // e.layer.bindTooltip("Detailed description will be here");
          // e.layer.bindTooltip(
          //   "<b>Precipitation</b> " + e.layer.properties.title + "mm/day"
          // );
          // e.layer.openTooltip(newMarkerPosition);
        },
        onMouseout: (e) => {
          // e.layer.setStyle({
          //   opacity:
          //     this.state.opacity ||
          //     e.layer.feature.properties["stroke-opacity"],
          // });
          // e.layer.setStyle({
          //   fillOpacity:
          //     this.state.fillOpacity ||
          //     e.layer.feature.properties["fill-opacity"],
          // });
          // e.layer.setStyle({ color: e.layer.feature.properties["stroke"] });
          // this.onGeoJSONOut(e);
          // e.layer.unbindTooltip();
          // e.layer.closeTooltip();
        },
        onClick: (e) => {
          //   let highlight = e.layer.properties.osm_id;
          //   console.log(e.layer);
          console.log("please add interaction here");
          console.log(e);
        },
      },
    };
    this.state = {
      height: this.props.height,
      width: this.props.width,
      legendSizeToWidth: this.props.legendSizeToWidth || 1 / 3,
      headerW: this.props.width,
      display: true,
      mapURL: this.props.mapURL || this.default.layerOptions.Standard,

      data: this.props.data || this.default.data,
      labelItems: [],

      labelItems1: [],
      labelItems2: [],

      lat: this.default.position.lat,
      lng: this.default.position.lng,
      zoom: this.default.position.zoom,
      markerOpacity: 1,

      weight: 0.2,
      opacity: null,
      fillOpacity: 0.4,

      GeoJSON: null,
      GeoJSON2: null,
      GeoJSON3: {},

      GeoJSONComponent: null,
      GeoJSONComponent2: null,
      GeoJSONComponent3: null,

      view: this.default.views.region,
      interactionMode: this.default.interactionModes.select,
      mapSelection: this.default.mapSelection,

      displayControl: {
        topLeft: this.props.controlTopLeft !== false,
        bottomLeft: this.props.controlBottomLeft !== false,
        bottomRight: this.props.controlBottomRight !== false,
      },
      regionBorder: this.props.regionBorder,
      markers: this.props.markers || [],

      // [DY]: 2021.02.02
      setSelectedRegion: this.props.setSelectedRegion || null,
      selectedRegionStateList: [],
      setSelectedSeason: this.props.setSelectedSeason || null,
      setSelectedSeason_Region: this.props.setSelectedSeason_Region || null,
      tilestacheLayerOptions:
        this.props.tilestacheLayerOptions ||
        this.default.tilestacheLayerOptions,

      tilestachLayer: <VectorGrid {...this.default.tilestacheLayerOptions} />,

      // expanding or shrinking
      isExpanded: true,
      // annotation panel
      showReplyPannel: false,
      showWhichReplyPannel: null,
    };

    this.dataSourceOptions = {
      external: "external",
      file: "file",
      tilestache: "tilestache",
    };

    this.dataSourceType =
      this.props.dataSourceOption || this.dataSourceOptions.file;
    this.dataSource = this.dataSource || this.props.dataFileName;
    this.dataProcessor = new ManageDataSet();
    this.userDataManager = new ManageUserData();
    this.state.markerPosition = [this.state.lat, this.state.lng];

    this.geoJsonLayer = React.createRef();
    this.onAction = this.props.onAction || {};
    this.onAction.toggleSettings = this.toggleDropDown;
    this.chartWrapper = new ChartWrapper(this, "geo-spatial", this.props);
    this.margin = _.cloneDeep(this.chartWrapper.getFixedMargin(10));
    this.onViewPortChanged = this.onViewPortChanged.bind(this);

    this.geoJSONTableID = -1;
    this.cookieManager = new CookieManager();
  }

  componentDidMount() {
    if (this.dataSource) {
      this.loadData();
      if (this.props.enableComment) this.loadMarkers();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.width !== this.props.width) {
      let multiplier = this.props.width / prevProps.width;
      // let zoom = multiplier < 1 ? 3 : 4;
      let zoom = multiplier < 1 ? this.state.zoom - 3 : this.state.zoom + 3;
      // let zoom=this.state.zoom * multiplier>

      this.setState({ width: this.props.width, zoom: zoom });
    }

    if (prevProps.lng !== this.props.lng)
      this.setState({ lng: this.props.lng });

    if (prevProps.dataFileName !== this.props.dataFileName) {
      this.dataSource = this.props.dataFileName;
      this.loadData();
      if (this.props.enableComment) this.loadMarkers();
    }

    if (prevProps.mapURL !== this.props.mapURL)
      this.setState({ mapURL: this.props.mapURL });

    if (
      prevState.GeoJSON !== this.state.GeoJSON ||
      prevState.GeoJSON2 !== this.state.GeoJSON2 ||
      prevState.interactionMode !== this.state.interactionMode
    ) {
      this.setState(
        {
          GeoJSONComponent: null,
          GeoJSONComponent2: null,
        },
        () => {
          if (
            this.state.interactionMode ===
              this.default.interactionModes.select &&
            this.state.GeoJSON
          ) {
            this.setState(
              {
                GeoJSONComponent: (
                  <GeoJSON
                    data={this.state.GeoJSON}
                    ref={(ref) => (this.geoJsonLayer_1 = ref)}
                    // ref={this.geoJsonLayer}
                    style={this.geoJSONStyle}
                    onMouseover={this.onGeoJSONOver}
                    onMouseout={this.onGeoJSONOut}
                  />
                ),
              },
              () => {
                this.setState({
                  GeoJSONComponent2: (
                    <GeoJSON
                      data={this.state.GeoJSON2}
                      ref={(ref) => (this.geoJsonLayer_2 = ref)}
                      // ref={this.geoJsonLayer}
                      style={this.geoJSONBorderStyle}
                      onMouseover={this.onGeoJSONBorderMouseOver}
                      onMouseout={this.onGeoJSONBorderMouseOut}
                      onEachFeature={(f, l) =>
                        this.onEachFeature(f, l, this.state.view)
                      }
                    />
                  ),
                });
              }
            );
          } else if (this.state.GeoJSON2) {
            // for both inspect and annotation
            this.setState(
              {
                GeoJSONComponent2: (
                  <GeoJSON
                    data={this.state.GeoJSON2}
                    // ref={this.geoJsonLayer}
                    ref={(ref) => (this.geoJsonLayer_2 = ref)}
                    style={this.geoJSONBorderStyle}
                    onMouseover={this.onGeoJSONBorderMouseOver}
                    onMouseout={this.onGeoJSONBorderMouseOut}
                    onEachFeature={(f, l) =>
                      this.onEachFeature(f, l, this.state.view)
                    }
                  />
                ),
              },
              () => {
                this.setState({
                  GeoJSONComponent: (
                    <GeoJSON
                      data={this.state.GeoJSON}
                      ref={(ref) => (this.geoJsonLayer_1 = ref)}
                      // ref={this.geoJsonLayer}
                      style={this.geoJSONStyle}
                      onMouseover={this.onGeoJSONOver}
                      onMouseout={this.onGeoJSONOut}
                    />
                  ),
                });
              }
            );
          }
        }
      );
    }

    if (this.state.interactionMode === this.default.interactionModes.annotate) {
      let markers = this.state.markers;

      let currentMarkerLength = markers.length;
      if (currentMarkerLength > 0) {
        let lastMarker = markers[currentMarkerLength - 1];

        if (lastMarker.comments.length == 0) {
          this.lastMarker.leafletElement.openPopup();
        }
      }
    }
  }

  loadData = () => {
    // []DY
    this.setState({
      tilestachLayer: null,
    });

    if (this.dataSourceType === this.dataSourceOptions.file && this.dataSource)
      this.readTextFile(this.dataSource);
    else if (
      this.dataSourceType === this.dataSourceOptions.external &&
      this.dataSource
    )
      this.getGeoJSON(this.dataSource);
    else if (
      this.dataSourceType === this.dataSourceOptions.tilestache &&
      this.dataSource
    )
      this.getTileStache(this.dataSource);
    // this.renderControlItems();
  };

  loadMarkers = () => {
    // this.userDataManager.commentMarkersOnGeoJSON().then((res) => {
    //   console.log(res);
    // });
    this.userDataManager
      .commentMarkersOnGeoJSON(this.dataSource)
      .then((res) => {
        console.log(res);
        this.geoJSONTableID = res.geojson_table_id;

        let markers = res.markers.filter(marker=>marker.position != "[None, None]");
        this.setState({ markers: markers });
      });
  };

  ////[DY]: added 2020.12.23
  getTileStache = (geoJSONLocation) => {
    if (this.legendRef) this.legendRef.appendLoading();
    if (
      this.state.regionBorder
      // && this.state.interactionMode === this.default.interactionModes.inspect
    )
      this.drawRegionBorderIfEnabled();

    let fileNameParts = geoJSONLocation.split("/");
    let dataFileName = _.nth(fileNameParts, -1);
    let dataSourceName = dataFileName.split(".")[0].toLowerCase();

    let tilestacheURL = `http://idev_server01.ncat.edu/${dataSourceName}/{z}/{x}/{y}.pbf`;

    let newTilestacheLayerOptions = this.state.tilestacheLayerOptions;
    newTilestacheLayerOptions["url"] = tilestacheURL;

    window.setTimeout(() => {
      this.setState({
        tilestacheLayerOptions: newTilestacheLayerOptions,
        tilestachLayer: <VectorGrid {...newTilestacheLayerOptions} />,
        GeoJSON: this.default.data,
      });
    }, 100);
  };

  getGeoJSON = (geoJSONLocation) => {
    if (
      this.state.regionBorder
      // && this.state.interactionMode === this.default.interactionModes.inspect
    )
      this.drawRegionBorderIfEnabled();

    if (this.legendRef) this.legendRef.appendLoading();
    this.dataProcessor.fetchNEXDCP30(geoJSONLocation).then((res) => {
      let gData = res.data;
      let controlItems = this.renderControlItems(gData);

      this.setState({
        data: gData,
        labelItems: controlItems,
        labelItems1: controlItems,
        GeoJSON: gData,
      });

    });
  };

  drawRegionBorderIfEnabled = () => {
    // this.dataProcessor.fetchNEXDCP30("nca_mask.geojson").then((res) => {
    //   console.log(res)
    //   let gData = res.data;
    //   this.setState({ GeoJSON2: gData });

    // });
    this.setState({ GeoJSON2: geoJson2_data });
  };

  onEachFeature = (feature, layer, type) => {
    // layer._leaflet_id = feature.title;
    let mapState = this.state;

    layer.on("click", (e) => {
      // console.log(type);
      // console.log(mapState.view);
      if (type === this.default.views.region) {
        this.setStateView(e.target.feature.properties.name);
        // mapState.state.setSelectedRegion(e.target.feature.properties.name);
      } else if (type === this.default.views.state) {
        let name = e.target.feature.properties.name;
        let stateCode = e.target.feature.properties.abbr;
        this.setCountyView(name, stateCode);
      }
    });
  };

  setRegionView = () => {
    this.drawRegionBorderIfEnabled();

    // [DY] 2020.02.02
    // // if you want to clear region
    // this.state.setSelectedRegion(null);
    // // if you want to clear both season and region
    // this.state.setSelectedSeason_Region(null, null);

    this.setState({
      lat: this.default.position.lat,
      lng: this.default.position.lng,
      zoom: this.default.position.zoom,
      view: this.default.views.region,
      selectedRegionStateList: [],
      mapSelection: this.default.mapSelection,
    }, () => console.log(this.state.view))
  };

  setStateView = (name, loading = true) => {
    if (this.state.setSelectedRegion && loading) {
      this.state.setSelectedRegion(name);
    }

    let regionLocation = _.find(regionGeoLocation, (rl) => rl.region === name);
    let regionsStateList = _.map(
      _.filter(regionDivisions, (s) => s.region === name),
      (d) => d.state
    );
    let stateGeoJSON = { type: "FeatureCollection", features: [] };
    let USStateFeatures = _.cloneDeep(USStates.features);
    stateGeoJSON.features = _.map(USStateFeatures, (f) => {
      if (_.indexOf(regionsStateList, f.properties.name) !== -1) return f;
      else {
        let cf = _.clone(f);
        cf.properties.invisible = true;
        return cf;
      }
    });
    let mapSelection = _.clone(this.state.mapSelection);
    mapSelection.region = name;
    mapSelection.state = null;

    let zoom = 5;

    this.setState({
      lat: regionLocation.latitude,
      lng: regionLocation.longitude,
      // GeoJSON2: stateGeoJSON,
      // zoom: 5,
      zoom: zoom,
      view: this.default.views.state,
      mapSelection: mapSelection,
      selectedRegionStateList: regionsStateList
    }, () => {
      setTimeout(() => {
        this.setState({GeoJSON2: stateGeoJSON})
      }, 1000)
    });
  };

  setCountyView = (name, stateCode) => {
    // if the user selects state outside this (selected) region
    if (_.indexOf(this.state.selectedRegionStateList, name) === -1) return;

    let width =
      this.mapRef.leafletElement.getBounds().getEast() -
      this.mapRef.leafletElement.getBounds().getWest();
    let height =
      this.mapRef.leafletElement.getBounds().getNorth() -
      this.mapRef.leafletElement.getBounds().getSouth();
    // let height = this.props.height;
    // let width = this.props.width;
    let zoom = this.state.zoom;

    let stateLocation = _.find(stateGeoLocation, (sl) => sl.name === name);
    let stateWidth = stateLocation.xmax - stateLocation.xmin;
    let stateHeight = stateLocation.ymax - stateLocation.ymin;

    // check only bigger dimension to find the ratio
    let stateZoom = Math.floor(
      (zoom * stateHeight * stateWidth) / (height * width)
    );
    if (stateWidth > 10) stateZoom = 6;
    else stateZoom = 7;

    this.dataProcessor
      .fetchNEXDCP30("us-states/" + stateCode + ".geojson")
      .then((res) => {
        let gData = res.data;
        let geoJSONData = topojson.feature(
          gData,
          gData.objects[
            "cb_2015_" + _.lowerCase(name).replace(" ", "_") + "_county_20m"
          ]
        );
        let mapSelection = _.clone(this.state.mapSelection);
        mapSelection.state = name;

        // this.mapRef.leafletElement.fitBounds([
        //   stateLocation.ymin, stateLocation.ymax,
        //   stateLocation.xmin, stateLocation.xmax
        // ]);

        this.setState({
          lat: stateLocation.latitude,
          lng: stateLocation.longitude,
          // GeoJSON2: geoJSONData,
          zoom: stateZoom,
          view: this.default.views.county,
          mapSelection: mapSelection,
        }, () => {
          setTimeout(() => {
            this.setState({GeoJSON2: geoJSONData})
          }, 1000)
        });
      });
  };

  readTextFile = (file) => {
    let rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          // let allText = rawFile.responseText;
          // let data = JSON.parse(allText);
          // let featureData = topojson.feature(data);
          // this.setState({
          //     GeoJSONComponent3: <GeoJSON
          //       data={data} ref={this.geoJsonLayer} style={(feature) => {
          //         return {
          //             stroke: true,
          //             color: '#a8a8a8',
          //             weight: this.state.weight || feature.properties["stroke-width"],
          //             opacity: this.state.opacity || feature.properties["stroke-opacity"],
          //             fill: false,
          //             fillColor: feature.properties.fill,
          //             fillOpacity: 0,
          //         };
          //     }}
          //     />
          // });
        }
      }
    };
    rawFile.send(null);
  };

  renderControlItems(data) {
    let labels = [];
    let labelItems = _.map(data.features, (item, index) => {
      if (labels.indexOf(item.properties.title) === -1) {
        labels.push(item.properties.title);
        return (
          <div className={"controlItem"}>
            <div
              className={"itemColor"}
              style={{ backgroundColor: item.properties.fill }}
            >
              {" "}
            </div>
            <div className={"itemLabel"}>{item.properties.title}</div>
          </div>
        );
      }
    });
    labelItems.unshift(<b>Precipitation</b>);
    return labelItems;
  }

  onViewPortChanged = (viewport) => {
    // console.log("lat: " + this.state.lat);
    // console.log("lng: " + this.state.lng);
    // console.log(viewport);
  };

  onMapClick = (e) => {
    if (this.state.interactionMode === this.default.interactionModes.annotate) {
      let newMarker = {
        position: e.latlng.lat + "," + e.latlng.lng,
        comment_count: 0,
        comments: [],
        marker_table_id: undefined,
      };
      let currentMarkers = this.state.markers;
      currentMarkers.push(newMarker);
      this.setState({ markers: currentMarkers, showReplyPannel: false });
    }
  };

  onGeoJSONClick = (e) => {
    // console.log("clicked!");
  };

  geoJSONStyle = (feature) => {
    return {
      stroke: true,
      color: feature.properties.stroke,
      weight: this.state.weight || feature.properties["stroke-width"],
      opacity: this.state.opacity || feature.properties["stroke-opacity"],
      fill: true,
      fillColor: feature.properties.fill,
      fillOpacity: this.state.fillOpacity,
    };
  };

  geoJSONBorderStyle = (feature, index) => {
    return {
      stroke: true,
      color: feature.properties.invisible ? "black" : "#858585",
      weight: feature.properties.invisible
        ? 0
        : 2 || feature.properties["stroke-width"],
      opacity: this.state.opacity || feature.properties["stroke-opacity"],
      fill: "#FFF",
      fillColor: feature.properties.invisible
        ? "black"
        : feature.properties.fill,
      fillOpacity: feature.properties.invisible ? 0.25 : 0,
    };
  };

  mapURLChange = (mapURL) => {
    this.setState({ mapURL: mapURL });
  };

  featureStyleResetToDefault = () => {
    this.setState({
      weight: null,
      opacity: null,
      fillOpacity: 0.5,
    });
  };

  onGeoJSONBorderMouseOver = (e) => {
    // console.log(e.layer.feature.properties);
    let newMarkerPosition = [e.latlng.lat, e.latlng.lng];
    // e.layer.bindTooltip("Detailed description will be here");
    let labelKey = this.default.labelInProps[this.state.view];
    e.layer.bindTooltip(
      "<b> " +
        _.upperFirst(this.state.view) +
        "</b>: " +
        e.layer.feature.properties[labelKey]
    );
    e.layer.openTooltip(newMarkerPosition);
  };

  onGeoJSONBorderMouseOut = (e) => {};

  onGeoJSONOver = (e) => {
    e.target.setStyle({ opacity: 0.2 });
    e.target.setStyle({ fillOpacity: 0.2 });
    e.layer.setStyle({ opacity: 0.8 });
    e.layer.setStyle({ fillOpacity: 0.8 });
    e.layer.setStyle({ color: "black" });

    let newMarkerPosition = [e.latlng.lat, e.latlng.lng];
    // e.layer.bindTooltip("Detailed description will be here");
    e.layer.bindTooltip(
      "<b>Precipitation</b> " + e.layer.feature.properties.title + "mm/day"
    );
    e.layer.openTooltip(newMarkerPosition);
  };

  onGeoJSONOut = (e) => {
    e.target.setStyle({
      opacity:
        this.state.opacity || e.layer.feature.properties["stroke-opacity"],
    });
    e.target.setStyle({
      fillOpacity:
        this.state.fillOpacity || e.layer.feature.properties["fill-opacity"],
    });
    e.layer.setStyle({
      opacity:
        this.state.opacity || e.layer.feature.properties["stroke-opacity"],
    });
    e.layer.setStyle({
      fillOpacity:
        this.state.fillOpacity || e.layer.feature.properties["fill-opacity"],
    });
    e.layer.setStyle({ color: e.layer.feature.properties["stroke"] });
    e.layer.unbindTooltip();
    e.layer.closeTooltip();
  };

  renderMarkers = () => {
    let geoContainer = this;

    return _.map(this.state.markers, (marker, i) => {
      let positionParts = marker.position.split(",");
      let markerLat = parseFloat(_.trim(positionParts[0]));
      let markerLng = parseFloat(_.trim(positionParts[1]));
      let commentInputRef = null;

      const onCommentSubmit = (e) => {
        let markerID = marker.marker_table_id;

        console.log(markerID)
        let currentLoggedInUser = this.cookieManager.getUsername();
        let comment = commentInputRef.value;

        if (comment.length == 0) {
          alert("Please add the content");
          return;
        }

        this.userDataManager
          .postUserComment(
            currentLoggedInUser,
            markerID,
            comment,
            marker.position,
            this.geoJSONTableID,
            this.dataSource
          )
          .then((res) => {
            commentInputRef.value = "";
            let markers = this.state.markers;
            let markerData = markers[i];

            if (markerID === undefined && res.data.marker_table_id)
              markerData.marker_table_id = res.data.marker_table_id;

            markerData.comments.push({
              comment: res.data.comment,
              user: res.data.user,
              posted: res.data.posted,
            });

            // update the circle colors in the thumbnail grid
            this.userDataManager
              .getAnnotatedGeoJSON(geoContainer.props.curModel)
              .then((res) => {
                geoContainer.props.updateAnnotationOverview(res.data);
              });

            this.setState({
              markers: markers,
              showReplyPannel: false,
            });
          });
      };

      let currentLoggedInUser = this.cookieManager.getUsername();
      let today = new Date().toJSON().slice(0, 16).replace(/-/g, "/");

      today = today.replace("T", " ");
      let curDataSource = this.dataSource.split("/")[1].split(".")[0];

      const renderPopupInput = (color) => {
        if (currentLoggedInUser)
          return (
            <Container
              className="annotation-panel"
              style={{ width: "100%", height: "100%" }}
            >
              <hr
                style={{
                  borderTop: "2px solid white",
                  marginTop: "0.3rem",
                  marginBottom: "0.5rem",
                }}
              ></hr>

              <Row style={{ height: "70%", width: "100%" }}>
                <Col
                  lg={2}
                  md={2}
                  style={{
                    padding: "0 0 0 0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "space-between",
                  }}
                >
                  <Row
                    style={{
                      margin: "0 auto 3% auto",
                      padding: "0  0 auto",
                    }}
                  >
                    {color == "white"? <UserIcon_md_white /> : <UserIcon_md_blue />}
                    
                  </Row>
                  <Row>{currentLoggedInUser}</Row>
                </Col>

                <Col lg={10} md={10} style={{ padding: "0 0 0 2%" }}>
                  <textarea
                    className={"form-control input-sm"}
                    ref={(ref) => (commentInputRef = ref)}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Col lg={7} md={7}></Col>
                <Col lg={5} md={5}>
                  <Row
                    style={{
                      height: "20%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className={"btn btn-primary btn-sm popup-button"}
                      type={"button"}
                      onClick={() => {
                        console.log("close");
                      }}
                    >
                      Close
                    </button>
                    <button
                      className={"btn btn-primary btn-sm popup-button"}
                      type={"button"}
                      onClick={onCommentSubmit}
                    >
                      Submit
                    </button>
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        else return "";
      };

      let renderComments = _.map(marker.comments, (c, i) => {
        return (
          <div className={"comment"}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"comment-username"}>{c.user}</div>
              <div className={"comment-time"}>{c.posted}</div>
            </div>
            <div className={"comment-text"}>{c.comment}</div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"comment-time"}>
                <a
                  href={"#"}
                  style={{ color: "white" }}
                  onClick={(e) => {
                    this.setState({
                      showReplyPannel: true,
                      showWhichReplyPannel: `${marker.marker_table_id}-${i}`,
                    });
                  }}
                >
                  Reply
                </a>
              </div>
              <div className={"comment-time"}>
                <a
                  href={"#"}
                  style={{ color: "white" }}
                  onClick={(e) => {
                    console.log("Delete the Annotation");
                  }}
                >
                  Delete
                </a>
                <a
                  href={"#"}
                  style={{ color: "white", marginLeft: "1rem" }}
                  onClick={(e) => {
                    console.log("Update the annotation");
                  }}
                >
                  Update
                </a>
              </div>
            </div>
            {/* <div id={`marker-reply-panel-${marker.marker_table_id}-${i}`} style={{display:"none"}}>
            {renderPopupInput()}
              </div> */}
            {this.state.showReplyPannel &&
            this.state.showWhichReplyPannel == `${marker.marker_table_id}-${i}`
              ? renderPopupInput("white")
              : null}
          </div>
        );
      });

      return (
        <Marker
          position={[markerLat, markerLng]}
          icon={AnnotationIcon}
          title={"View " + marker.comment_count + " comments"}
          ref={(ref) =>
            i == this.state.markers.length - 1 ? (this.lastMarker = ref) : null
          }
        >
          <Popup>
            <Row
              style={{
                height: "20%",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "1%",
              }}
            >
              <h7>{curDataSource}</h7>
              <div style={{ margin: "auto 0 auto 0" }}>{today}</div>
            </Row>

            <hr style={{ marginTop: "0.3rem", marginBottom: "0.1rem" }}></hr>

            {/* <div>{this.renderComments(marker.comments)}</div> */}
            <div>{renderComments}</div>
            {marker.comments.length == 0 ? renderPopupInput("blue") : null}
          </Popup>
        </Marker>
      );
    });
  };

  onMapLayerOrderChange = (interaction) => {
    this.setState({ interactionMode: interaction });
  };

  render() {
    let chartClassName = "chart geo-spatial " + this.props.eClassName;
    let position = [this.state.lat, this.state.lng];

    let viewport = { center: position, zoom: this.state.zoom };
    let mapHeight = this.state.height - this.margin.top - this.margin.bottom;
    let mapWidth = this.state.width - this.margin.right - this.margin.left;

    let controlTopLeftDisplay = this.state.displayControl.topLeft ? "" : "none";
    let controlBottomLeftDisplay = this.state.displayControl.bottomLeft
      ? ""
      : "none";
    // let controlBottomRightDisplay = this.state.displayControl.bottomRight? "": "none";

    return (
      <div>
        <div
          className={chartClassName}
          ref={(el) => (this.geoSpatial = el)}
          style={{
            display: this.state.display,
            paddingTop: this.margin.top,
            paddingRight: this.margin.right,
            paddingBottom: this.margin.bottom,
            paddingLeft: this.margin.left,
          }}
        >
          <Map
            ref={(ref) => (this.mapRef = ref)}
            viewport={viewport}
            zoomControl={!this.props.disableZoomControl}
            minZoom={3}
            maxBounds={latLngBounds(
              latLng(5.499550, -167.276413), //Southwest
              latLng(83.162102, -52.233040)  //Northeast
            )}
            style={{ height: mapHeight, width: mapWidth }}
            onViewportChanged={this.onViewPortChanged}
            // onClick={this.onMapClick}
            onPopupClose={() => {
              // check the last one has at least one element or not
              // if no content -> remove

              let markers = this.state.markers;
              let currentMarkerLength = markers.length;

              if(currentMarkerLength>0){
              let lastMarker = markers[currentMarkerLength - 1];

              if (lastMarker.comments!= undefined && lastMarker.comments.length == 0) {
                markers.pop();
              }
            }

              this.setState({ showReplyPannel: false, markers: markers });
            }}
            oncontextmenu={this.onMapClick}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={this.state.mapURL}
            />
            {this.state.tilestachLayer}

            {this.state.interactionMode ===
              this.default.interactionModes.annotate && this.renderMarkers()}
            <Control position="bottomleft">
              <div
                className={"legendControl"}
                ref={(legendControl) => (this.legendControl = legendControl)}
                style={{
                  width:
                    "" + this.state.width * this.state.legendSizeToWidth + "px",
                  height: 80,
                  display: controlBottomLeftDisplay,
                }}
              >
                {/*<div style={{width: this.state.width * this.state.legendSizeToWidth}}>{this.props.label}</div>*/}
                {this.props.label}
                {!this.props.removeLegend && (
                  <ColorRangeLegend
                    ref={(ref) => (this.legendRef = ref)}
                    label={"Precipitation (PR) mm/day"}
                    scale={this.props.scale}
                    data={this.state.data}
                    width={this.state.width * this.state.legendSizeToWidth}
                    parseData={true}
                  />
                )}
              </div>
            </Control>

            <Control position={"bottomright"}>
              <div
                className={"legendControl"}
                style={{ height: 205, display: controlTopLeftDisplay }}
              >
                <b>Interaction Mode</b>
                <Form.Check
                  inline
                  defaultChecked={this.state.interactionMode === this.default.interactionModes.inspect}
                  label={"Inspect"}
                  type={"radio"}
                  name={"select-interaction"}
                  onClick={(e) =>
                    this.onMapLayerOrderChange(
                      this.default.interactionModes.inspect
                    )
                  }
                />
                <Form.Check
                  inline
                  defaultChecked={this.state.interactionMode === this.default.interactionModes.select}
                  label={"Select"}
                  type={"radio"}
                  name={"select-interaction"}
                  onClick={(e) =>
                    this.onMapLayerOrderChange(
                      this.default.interactionModes.select
                    )
                  }
                />
                <Form.Check
                  inline
                  defaultChecked={this.state.interactionMode === this.default.interactionModes.annotate}
                  label={"Annotate"}
                  type={"radio"}
                  name={"select-interaction"}
                  onClick={(e) =>
                    this.onMapLayerOrderChange(
                      this.default.interactionModes.annotate
                    )
                  }
                />
                <div className={"map-selection"}>
                  <b>View</b>
                  <div
                    className={
                      "md-title " +
                      (this.state.view === this.default.views.region
                        ? "active"
                        : "")
                    }
                    onClick={() => this.setRegionView()}
                  >
                    United States
                  </div>
                  {this.state.mapSelection.region && (
                    <div
                      className={
                        "md-title " +
                        (this.state.view === this.default.views.state
                          ? "active"
                          : "")
                      }
                      onClick={() =>
                        this.setStateView(this.state.mapSelection.region)
                      }
                    >
                      {_.upperFirst(this.state.mapSelection.region)}
                    </div>
                  )}
                  {this.state.mapSelection.state && (
                    <div
                      className={
                        "md-title " +
                        (this.state.view === this.default.views.county
                          ? "active"
                          : "")
                      }
                      onClick={() =>
                        this.setCountyView(this.state.mapSelection.state)
                      }
                    >
                      {this.state.mapSelection.state}
                    </div>
                  )}
                </div>
              </div>
            </Control>

            {/* testing */}
            <div className={"region-border"}>
              {this.state.GeoJSONComponent2}
            </div>
            <div>{this.state.GeoJSONComponent}</div>
          </Map>
        </div>
      </div>
    );
  }
}

export default GeoSpatial;
