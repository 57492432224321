import "../stylesheets/AlertController.css";
import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";

class AlertController {
  constructor(props) {
    this.props = props;
    this.default = {
      widthClass: "w480",
      confirmButtonText: "OK"
    };

    this.onAlertCancel = this.onAlertCancel.bind(this);
    this.onAlertDelete = this.onAlertDelete.bind(this);
    this.triggerSetAlert = this.props.setAlert;
  }

  setAlert = (renderProps, renderContent, widthClass) => {
    widthClass = widthClass || this.default.widthClass;

    this.triggerSetAlert(this.renderAlert(renderProps, renderContent, widthClass));
  };

  setSuccessAlert = (title, text, widthClass="") => {
    this.triggerSetAlert(this.renderSuccessAlert(title, text, widthClass));
  };

  closeAlert() {
    // this.props.setState({ alert: null });
    this.onAlertCancel();
  }

  // onAlertSubmit() {
  //
  // }

  onAlertDelete(value) {
    this.triggerSetAlert(false);
  }

  onAlertCancel() {
    this.triggerSetAlert(false);
  }

  renderAlert(renderProps, renderContent, widthClass) {
    // temp
    renderContent =
      renderContent || "You will not be able to recover this imaginary file!";
    return (
      <div id={"modal-container"} className={"hide-actions " + widthClass}>
        <SweetAlert
          {...renderProps}
          // width={''+this.state.width+'px'}
          // width={'800px'}
          // showCancel
          // confirmBtnText="OK"
          // confirmBtnBsStyle="default"
          // cancelBtnBsStyle="default"
          // title="Are you sure?"
          onConfirm={(value) => {
            if (renderProps.onAlertDelete)
              renderProps.onAlertDelete(value);
            this.onAlertDelete(value);
          }}
          onCancel={this.onAlertCancel}
        >
          {renderContent}
        </SweetAlert>
      </div>
    );
  }

  renderSuccessAlert(title, text, widthClass="") {
    console.log("HERE");
    return (
      <div id={"modal-container"} className={widthClass}>
        <SweetAlert
          success
          title={title}
          onConfirm={this.onAlertCancel}
          onCancel={this.onAlertCancel}
        >
          {text}
        </SweetAlert>
      </div>
    )
  }
}

export default AlertController;
