import React from "react";
import LineChart from "../components/charts/LineChart";
import HeatMap from "../components/charts/heatmap";
import ParallelCoordinates from "../components/charts/parallelcoordinates";
import BoxPlot from "../components/charts/BoxPlot";
import GeoSpatial from "../components/charts/GeoSpatial";
import Tabled from "../components/charts/confusionMatrix";
import BarChart from "../components/charts/BarChart";
import AreaChart from "../components/charts/AreaChart";
import DoughnutChart from "../components/charts/DoughnutChart";
import LineChartController from "../components/charts/controllers/LineChart";


const charts = {
  lineChart: {
    component: LineChart,
    controller: LineChartController,
    label: "Line Chart",
    key: "lineChart"
  },
  barChart: {
    component: BarChart,
    label: "Bar Chart",
    key: "barChart"
  },
  heatMap: {
    component: HeatMap,
    label: "HeatMap",
    key: "heatMap"
  },
  parallelCoordinates: {
    component: ParallelCoordinates,
    label: "Parallel Coordinates",
    key: "parallelCoordinates"
  },
  boxPlot: {
    component: BoxPlot,
    label: "Box Plot",
    key: "boxPlot"
  },
  geoSpatial: {
    component: GeoSpatial,
    label: "Geo Spatial",
    key: "geoSpatial"
  },
  confusionMatrix: {
    component: Tabled,
    label: "Confusion Matrix",
    key: "confusionMatrix"
  },
  areaChart: {
    component: AreaChart,
    label: "Area Chart",
    key: "areaChart"
  },
  doughnutChart: {
    component: DoughnutChart,
    label: "Doughnut Chart",
    key: "doughnutChart"
  }
};

export default charts;
