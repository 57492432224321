import _ from "lodash";
import sample_data from '../../data/vehicledata.json';
import ChartWrapper from "../wrapper/ChartWrapper";
import '../../stylesheets/charts/parallelcoordinates.css';
import DimensionParser from "../../utils/DimensionParser";
import DataServerConfig from "../../manage/requestProcessor/dataServer";

let React = require('react');
let d3 = require("d3");

class ParallelCoordinates extends React.Component {
    constructor(props) {
        super(props);
        this.node = null;
        this.default = {
            colorScheme: this.props.colorScheme || "schemePaired"
        };
        // console.log('from pcoords', this.props.data);
        this.data = this.props.data || sample_data.slice(0, 50);
        this.parentRef = props.parentRef;
        this.sign = props.sign;
        this.onDelete = props.onDelete;
        this.line = d3.line();
        this.state = {
            display: true,
            width: this.props.width || 500,
            height: this.props.height || 350,
            headerW: this.props.width || 500,
            showPop: false,
            dimension: new DimensionParser(this.data),
            colorAttribute: this.props.colorAttribute || "cylinders",
            color: "cadetblue",
            title: "Test Title",
            table: false,
            colorConstraint: this.props.colorConstraint || {
                1994: "#388e3c",
                1995: "#388e3c",
                1996: "#2196f3",
                1997: "#00bcd4",
                1998: "#ffb300",
                1999: "#ff7043"
            }
        };

        this.tooltip = d3.select("body").append("div")
          .attr("class", "image-tooltip").style("left","100px").style("top", "-100px");

        this.chartWrapper = new ChartWrapper(this, "p-coords", this.props);
        this.margin = _.cloneDeep(this.chartWrapper.margin);
        let temp = this.margin.top;
        this.margin.top = this.margin.bottom;
        this.margin.bottom = temp;


        this.state.x = this.state.dimension.dims[0];
        this.state.y = this.state.dimension.dims[1];

        this.dimensions = null;
        this.createParallel = this.createParallel.bind(this);
        this.handlePopout = this.handlePopout.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        // this.path = this.path.bind(this);
    }

    componentDidMount() {
        this.createParallel();
    }

    createParallel() {
        let data = this.data;
        let colorScheme = d3[this.default.colorScheme];
        let colorAttribute = this.state.colorAttribute;
        let colorMap = {};
        _.forEach(data, (datum, index) => {
            if(colorMap[datum[colorAttribute]] === undefined)
                colorMap[datum[colorAttribute]] = colorScheme[index];
        });
        let colorConstraint = colorMap;
        let margin = this.margin;
        let width = this.state.width - margin.left - margin.right;
        let height = this.state.height - margin.top - margin.bottom;

        let x = d3.scalePoint().range([0, width]).padding(0),
            y = {};

        let line = d3.line(),
            axis = d3.axisLeft(),
            background,
            foreground;

        let dimensions = null;

        const svg = d3.select(this.graph).append('svg').attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom);
        const svg_adjusted = svg.append("g").attr("transform", "translate(" + margin.left + " ," + margin.top + ")");

        let getValue = (v) => {return v.value !== undefined ? v.value: v};
        let findTimeStampByDate = (date) => {
            let timestamp = null;
            let index = null;
            _.forEach(this.props.timestamps, (t, i) => {
                if (t.time === date) {
                    timestamp = t;
                    index = i;
                    return false;
                }
            });
            return {t: timestamp, i: index};
        };

        // Extract the list of dimensions and create a scale for each.
        x.domain(dimensions = d3.keys(this.data[0]).filter(d => {
            if (d!== "name") {
                if (d=== "year")
                    return y[d] = d3.scaleLinear()
                      .domain(d3.extent(this.data, function (p) {
                          return +getValue(p[d]);
                      }))
                      .range([height, 0]);
                else
                    return y[d] = d3.scaleLinear()
                      .domain([0, 2])
                      .range([height, 0])
            }
            // return d !== "name" && (y[d] = d3.scaleLinear()
            //     .domain(d3.extent(this.data, function (p) {
            //         console.log(p[d]);
            //         return +getValue(p[d]);
            //     }))
            //     .range([height, 0]));
        }));

        // Add grey background lines for context.
        background = svg_adjusted.append("g")
            .attr("class", "background")
            .selectAll("path")
            .data(this.data)
            .enter().append("path")
            .attr("d", path);

        // Add blue foreground lines for focus.
        foreground = svg_adjusted.append("g")
            .attr("class", "foreground")
            .selectAll("path")
            .data(this.data)
            .enter().append("path")
            .attr("stroke",  function (d) {
              return colorConstraint[d[colorAttribute]];
            })
            .attr("d", path)
            .style('opacity', 0.6)
            .style('stroke-width', 2);

        // Add a group element for each dimension.
        const g = svg_adjusted.selectAll(".dimension")
            .data(dimensions)
            .enter().append("g")
            .attr("class", "dimension")
            .attr("transform", function (d) {
                return "translate(" + x(d) + ")";
            });

        // Add an axis and title.
        g.append("g")
            .attr("class", "axis")
            .each(function (d) {
                if (d === "year")
                    d3.select(this).call(axis.scale(y[d]).ticks([5]));
                else
                    d3.select(this).call(axis.scale(y[d]).ticks([10]));
            })
            .append("text")
            .style("text-anchor", "middle")
            .attr("y", -9)
            .text(function (d) {
                return d;
                // if (d === "year") return d;
                // else getValue(d)
            });

        // Add and store a brush for each axis.
        g.append("g")
            .attr("class", "brush")
            .each(function (d) {
                d3.select(this).call(y[d].brush = d3.brushY()
                    .extent([[-10, 0], [10, height]])
                    .on("brush", brush)
                    .on("end", brush)
                )
            })
            .selectAll("rect")
            .attr("x", -8)
            .attr("width", 16);

        // Add circle for each data point
        if (this.props.dataPointCircle) {
            _.forEach(_.keys(this.data[0]), (key, i) => {
                svg_adjusted.selectAll(".dot"+i)
                  .data(data)
                  .enter().append("circle") // Uses the enter().append() method
                  .attr("class", "dot") // Assign a class for styling
                  .attr("cx", (d, i) => {
                      return x(key)
                  })
                  .attr("cy", function (d, i) {
                      return y[key](getValue(d[key]));
                  })
                  .attr("r", 4)
                  .style('fill', (d) => colorConstraint[d[colorAttribute]])
                  .on("mouseover", (d, i) => {
                      let timestamp = null;
                      if (d[key].date) {
                          timestamp = findTimeStampByDate(d[key].date).t;

                          let image_url = DataServerConfig.Server + DataServerConfig.ServerAPI.FetchNEXDCP30Thumbnail.path;
                          let abs_url = image_url + "?file_name=" + this.props.dataFilePrefix + "&index_name=" + timestamp.filename;

                          this.tooltip.transition()
                            .duration(200)
                            .style("opacity", .8);
                          let tooltipContent = "<div class='preview-info'>" +
                            "<span class='btn btn-sm btn-secondary'>Date: "+ timestamp.time +" </span>" +
                            "<span class='btn btn-sm' style='background: "+ colorConstraint[d[colorAttribute]] +"'> Mean: "+ d[key].value.toFixed(2) +" </span>" +
                            "</div>";
                          this.tooltip.html(tooltipContent)
                            .style("background-image", "url(" + abs_url + ")")
                            .style("left", (d3.event.pageX) + "px")
                            .style("top", (d3.event.pageY - 28) + "px");
                      }
                  })
                  .on("mouseout", () => {
                      this.tooltip.transition()
                        .duration(500)
                        .style("opacity", 0);
                  })
                  .on("mousedown", (d, i) => {
                      let timestampIndex = null;
                      if (d[key].date) {
                          timestampIndex = findTimeStampByDate(d[key].date).i;
                          this.props.onCircleClick(timestampIndex);
                      }

                      // this.tooltip.transition()
                      //   .duration(500)
                      //   .style("opacity", 0);
                  });
            });
        }

        // Returns the path for a given data point.
        function path(d) {
            return line(dimensions.map(function (p) {
                return [x(p), y[p](getValue(d[p]))];
            }));
        }

        // Handles a brush event, toggling the display of foreground lines.
        function brush() {
            let actives = [];
            svg.selectAll(".brush")
                .filter(function (d) {
                    y[d].brushSelectionValue = d3.brushSelection(this);
                    return d3.brushSelection(this);
                })
                .each(function (d) {
                    // Get extents of brush along each active selection axis (the Y axes)
                    actives.push({
                        dimension: d,
                        extent: d3.brushSelection(this).map(y[d].invert)
                    });
                });

            let selected = [];
            // Update foreground to only display selected values
            foreground.style("display", function (d) {
                return actives.every(function (active) {
                    let activeDimensionValue = d[active.dimension].value !== undefined ? d[active.dimension].value: d[active.dimension];
                    let result = active.extent[1] <= activeDimensionValue && activeDimensionValue <= active.extent[0];
                    if (result) selected.push(d);
                    return result;
                }) ? null : "none";
            });
            // (actives.length&#x3E;0)?out.text(d3.tsvFormat(selected.slice(0,24))):out.text(d3.tsvFormat(this.data.slice(0,24)));;
        }
    }

    // path(d) {
    //     let x = this.x;
    //     let y = this.y;
    //     let dimensions = this.dimensions;
    //     //console.log(dimensions.map(function(p) { return x(p)}));
    //     return this.line((dimensions.map(function (p) {
    //         return [x(p), y[p](d[p])];
    //     })));
    // }

    removeParallel() {
        d3.select(this.graph).select('svg').remove();
    }

    componentDidUpdate() {
        this.removeParallel();
        this.createParallel();
    }

    handlePopout() {
        let pop = this.state.showPop;
        this.setState({
            showPop: !pop
        });
    }


    handleTitle(input) {
        console.log(input.target.value);

        this.setState({
            title: input.target.value,
        })

    }

    onDimChange(dim) {
        console.log(dim);

        if (dim[1] === "1") {
            this.setState({
                x: dim[0]
            })
        }
        else {
            this.setState({
                y: dim[0]
            })
        }
    }

    handleShowTable() {
        let table = this.state.table;
        this.setState({
            table: !table
        });

        console.log(this.state.table);
    }

    render() {
        const chartClassName = "chart pcoords resizable ui-widget-content ui-resizable ui-draggable ui-draggable-handle"
            + " " + this.props.eClassName;

        return(
        <div>
            {/*{this.chartHeaderController.getChartHeader(this.state.headerW + 13, null, this.props.headerLabel)}*/}
            <div ref={el => this.el = el}
                 className={chartClassName}
                 id={this.sign}
                 style={{width: this.state.width, height: this.state.height}}
            >
                <div ref={graph => this.graph = graph}
                     style={{height: this.state.height, width: this.state.width}}
                     className={"graph-child ScatterPlot"}>

                </div>
            </div>
        </div>
        )
    }

}

export default ParallelCoordinates;
