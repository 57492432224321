import _ from "lodash";
import React from "react";
import {Row, Col} from "react-bootstrap";
import "../../stylesheets/views/chartCollection.css";
import charts from "../../manage/charts";
import {EnterIcon} from "../../utils/IconContainer";
import {Link} from "react-router-dom";


class ChartCollection extends React.Component {
  constructor(props) {
    super(props);
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    let canvasWidth = windowWidth - windowWidth*0.2;
    let canvasHeight = windowHeight - windowHeight*0.15 - 60;
    this.chartWidth = canvasWidth/3;
    this.chartHeight = canvasHeight/3;
    this.chartList = _.map(charts);
    this.numCol = 3;
    // this.numRows = _.size(this.chartList)/this.numCol;
  }
  renderCharts () {
    return _.map(_.chunk(this.chartList, this.numCol), (list => {
      return (
        <Row className={"chart-grid-row"}>
          {
            _.map(list, (c) => {
              return (
                <Col className={"chart-grid-col"} md={4} lg={4} sm={2}>
                  <div className={"chart-collection-wrapper"}
                  >
                    <div className={"curtain"}>
                      <span>{c.label}</span>
                      <span className={"enter-icon"}><Link to={"/explore-chart?chart="+c.key}><EnterIcon/></Link></span>
                    </div>
                    <div className={"chart"}><c.component height={this.chartHeight} width={this.chartWidth}/></div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      );
    }));
  }

  render() {
    return(
      <div>
        <div className={"chart-grid"}>
          {this.renderCharts()}
        </div>
      </div>
    );
  }
}

export default ChartCollection;