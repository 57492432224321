import React from "react";

const UpdateURLParameter = (param, paramVal, url) => {
    url = url || window.location.href;
    let TheAnchor = null;
    let newAdditionalURL = "";
    let tempArray = url.split("?");
    let baseURL = tempArray[0];
    let additionalURL = tempArray[1];
    let temp = "";

    if (additionalURL)
    {
        let tmpAnchor = additionalURL.split("#");
        let TheParams = tmpAnchor[0];
        TheAnchor = tmpAnchor[1];
        if(TheAnchor)
            additionalURL = TheParams;

        tempArray = additionalURL.split("&");

        for (let i=0; i<tempArray.length; i++)
        {
            if(tempArray[i].split('=')[0] !== param)
            {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }
    else
    {
        let tmpAnchor = baseURL.split("#");
        let TheParams = tmpAnchor[0];
        TheAnchor  = tmpAnchor[1];

        if(TheParams)
            baseURL = TheParams;
    }

    if(TheAnchor)
        paramVal += "#" + TheAnchor;

    let rows_txt = temp + "" + param + "=" + paramVal;
    let newURL = baseURL + "?" + newAdditionalURL + rows_txt;
    window.history.replaceState("", "", newURL);
};

/**
 * @return {string}
 */
const GetURLParameter = function (param) {
    let url_string = window.location.href;
    let url = new URL(url_string);
    return url.searchParams.get(param);
};

export default {
    UpdateURLParameter: UpdateURLParameter,
    GetURLParameter: GetURLParameter
};
