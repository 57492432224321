import React from "react";

class DataTransformation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div>data transformation</div>
    );
  }
}

export default DataTransformation;