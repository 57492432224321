import React from "react";
import SeasonalRegionalSmallMultiples from "../../components/charts/TestComponentExp.SeasonalRegionalSmallMultiples";
import HorizontalStackedBarChart from "../../components/charts/TestComponentExp.HorizontalStackedBarChart";
import TreeMap from "../../components/charts/TestComponentExp.TreeMap";

export const returnLineChart = (args, seasonalRegionalData = null) => {
  let height = args.summaryChartHeight();
  let width = args.rightChartWidth() - 10;

  seasonalRegionalData =
    args.state.seasonalRegionalResponseData == null
      ? seasonalRegionalData
      : args.state.seasonalRegionalResponseData;

  // let lineChartSmallMultiplesWidth = width / args.seasonList.length;
  let lineChartSmallMultiplesWidth = width;
  let lineChartSmallMultiplesHeight = (height - 20) / args.seasonList.length;

  return (
    <SeasonalRegionalSmallMultiples
      sChartWidth={width}
      regions={args.regionList}
      seasons={args.seasonList}
      seasonalRegionalResponseData={seasonalRegionalData}
      lineChartSmallMultiplesHeight={lineChartSmallMultiplesHeight}
      lineChartSmallMultiplesWidth={lineChartSmallMultiplesWidth}
      minMeanPrecipitation={args.minMeanPrecipitation}
      maxMeanPrecipitation={args.maxMeanPrecipitation}
      seasonalRegionalParameters={args.state.seasonalRegionalParameters}
      lineChartSetting={args.lineChartSetting}
      seasonColorScale={args.seasonColorScale}
      alertController={args.alertController}
      regionAcronyms={args.regionAcronyms}
      onChartChange={[args.onClickLinechart, args.onClickBarchart, args.onClickTreemap]}
      //[DY]
      currentRegion={args.regionList[args.state.currentView]}
    />
  );
};

export const returnBarchart = (args) => {
  let height = args.summaryChartHeight();
  let width = args.rightChartWidth() - 10;

  return (
    <HorizontalStackedBarChart
      height={height}
      width={width}
      colorScale={args.seasonColorScale}
      predictionModel={args.PredictionModel}
      predictionScenarios={args.PredictionScenarios}
      onChartChange={[args.onClickLinechart, args.onClickBarchart, args.onClickTreemap]}
      currentRegion={args.regionList[args.state.currentView]}
    />
  );
};

export const returnTreemap = (args, setSeasonFunction_parent, regionalSeasonalData = null, seasonalRegionalData = null) => {
  let height = args.summaryChartHeight() - 20;
  let width = args.rightChartWidth() - 10 - 15;

  regionalSeasonalData =
    args.state.regionalSeasonalResponseData == null
      ? regionalSeasonalData
      : args.state.regionalSeasonalResponseData;
  seasonalRegionalData =
    args.state.regionalSeasonalResponseData == null
      ? seasonalRegionalData
      : args.state.regionalSeasonalResponseData;

  return (
    <TreeMap
      height={height}
      width={width}
      doDrawRegionFirst={true}
      regionalSeasonalData={regionalSeasonalData}
      // colorScale={args.state.seasonColorScale}
      data={seasonalRegionalData}
      margin={{ top: 20, right: 15, bottom: 0, left: 10 }}
      doReordering={true}
      regionalLevel={args.regionList[args.state.currentView]}
      // seasonalLevel={null}
      seasonalLevel={args.state.treeMap_prev_seasonalLevel}
      isExpand={!args.state.expand}
      onChartChange={[args.onClickLinechart, args.onClickBarchart, args.onClickTreemap]}
      setSeasonFunction_parent={setSeasonFunction_parent}
    />
  );
};
