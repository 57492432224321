import _ from "lodash";
import axios from "axios";
import React from "react";
import DataServerConfig from "../manage/requestProcessor/dataServer";


class ManageUserData {
  constructor(props) {
    this.dataServer = DataServerConfig.Server;
    this.dataServerAPI = DataServerConfig.ServerAPI;
  }

  getHeaders() {
    return new Headers({
      credentials: 'same-origin',
      'Access-Control-Allow-Origin': '*',
    });
  }

  async userAuthentication(username, password) {
    return await axios.post(
      this.dataServer + this.dataServerAPI.UserAuthentication.path,
      // "http://localhost:8000" + this.dataServerAPI.UserAuthentication.path,
      {username: username, password: password}, {
        headers: {
          credentials : 'same-origin',
          'Access-Control-Allow-Origin': '*'
        },
      }
    ).catch(error => {
      console.log(error);
    });
  }

  async commentMarkersOnGeoJSON(geojsonLocation) {
    let file_path_parts = _.split(geojsonLocation, "/");
    let file_name = file_path_parts[file_path_parts.length-1];
    let parent_directory = file_path_parts[file_path_parts.length-2];

    let response = await fetch(
      this.dataServer + this.dataServerAPI.FetchGeoJSONMarkers.path +
      "?data_directory="+parent_directory+"&file_name="+file_name,
      {
        headers: this.getHeaders(),
      }
    ).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  async getAnnotatedGeoJSON(model) {
    let response = await fetch(
      this.dataServer + this.dataServerAPI.GetAnnotatedGeoJSON.path +
      "?model="+model,
      {
        headers: this.getHeaders(),
      }
    ).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  async postUserComment(username, markerID, comment_text, position, geojsonID, geojsonLocation) {
    let file_path_parts = _.split(geojsonLocation, "/");
    let file_name = file_path_parts[file_path_parts.length-1];
    let parent_directory = _.join(_.slice(file_path_parts, 0, file_path_parts.length-1), "/");

    return await axios.post(
      this.dataServer + this.dataServerAPI.PostGeoJSONMarkerComments.path,
      {
        username: username, marker_id: markerID, comment: comment_text,
        position: position, geojson_id: geojsonID,
        file_name: file_name, data_directory: parent_directory
      }, {
        headers: {
          credentials : 'same-origin',
          'Access-Control-Allow-Origin': '*'
        },
      }
    ).catch(error => {
      console.log(error);
    });
  }
}

export default ManageUserData;
