// import BaseChartHeaderController from "../common/controller/ChartHeaderController";
import _ from "lodash";
import '../../stylesheets/charts/axis.css';
import '../../stylesheets/charts/heatmap.css';
import '../../stylesheets/charts/tooltips.css'
import tip from 'd3-tip';
import data from '../../data/global-temperature.json';
import ChartWrapper from "../wrapper/ChartWrapper";

const React = require("react");
const d3 = require("d3");


class HeatMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerW: this.props.width || 917,
            width: this.props.width || 917,
            height: this.props.height || 408,
            display: true,

            startDate: new Date(),
            endDate: new Date(),
            data: null
        };
        
        this.shape = {
            width: 50,
            height: 65
        };

        this.chartWrapper = new ChartWrapper(this, "heatMap", this.props);
        this.removeHeatMap = this.removeHeatMap.bind(this);
        this.createHeatMap = this.createHeatMap.bind(this);
        this.margin = _.cloneDeep(this.chartWrapper.margin);

        // this.chartHeaderController = new BaseChartHeaderController(this, "scatter-plot", this.props);
        // this.margin = _.cloneDeep(this.chartHeaderController.margin);


        //create tip based of the y dimmension
    }

    async loadData() {
        const url = '../data/global-temperature.json';
        let response = await fetch(
            url,
        ).catch(error => console.log(error));
        try {
            return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
        } catch (e) {
            return [];
        }

    }

    componentWillMount() {
    /*    this.loadData()
            .then(data => {
                this.setState({
                    data: data
                })
            });
    */    this.setState({
            data: data
        })
    }

    componentDidMount() {
        if (this.state.data !== null)
            this.createHeatMap();
    }

    componentDidUpdate() {
        if (this.state.data !== null) {
            this.removeHeatMap();
            this.createHeatMap();
        }
    }

    removeHeatMap() {
        d3.select(this.graph).select('svg').remove();
    }


    createHeatMap() {
        let t = tip().attr('class', 'd3-tip')
            .offset([-10, 0])
            .html(function(d) {
                return timeParseFormat(d.month) + ' ' + d.year + '<br/>' +
                d3.format('.4r')(baseTemperature + d.variance) + ' &degC<br/>' + d.variance + ' &degC';
            });

        // const url = '..data/global-temperature.json';
        const width = this.state.width;
        const height = this.state.height;
        const graph_width = width - this.margin.left  - this.margin.right;
        const graph_height = height - this.margin.top  - this.margin.bottom;

        const data = this.state.data.monthlyVariance,
            baseTemperature = this.state.data.baseTemperature,
            yearRange = d3.extent(data, d => {return d.year; });

        const legendData = [
            {'interval': 2.7, 'color': '#009688'},
            {'interval': 3.9, 'color': '#4caf50'},
            {'interval': 6.1, 'color': '#8bc34a'},
            {'interval': 7.2, 'color': '#cddc39'},
            {'interval': 8.3, 'color': '#ffeb3b'},
            {'interval': 9.4, 'color': '#ffc107'},
            {'interval': 10.5, 'color': '#ff9800'},
            {'interval': 11.6, 'color': '#ff5722'},
            {'interval': 15,'color': '#f44336'}
        ];

        const yScale = d3.scaleLinear()
            .rangeRound([graph_height,0])
            .domain([12,0]);

        const xScale = d3.scaleLinear()
            .rangeRound([0,graph_width])
            .domain(d3.extent(data, d => {return d.year; }));

        //Setting chart width and adjusting for margins
        const chart = d3.select(this.graph).append("svg")
            // .attr('width', width + this.margin.right + this.margin.left)
            // .attr('height', height + margins.top + margins.bottom)
            .attr('width', width)
            .attr('height', height)
            .call(t)
            .append('g')
            .attr('transform','translate(' + this.margin.left + ',' + this.margin.top + ')');

        // const tooltip = d3.select('.container').append('div')
        //     .attr('class','tooltip')
        //     .html('Tooltip');

        const barWidth = (graph_width) / (yearRange[1] - yearRange[0]),
            barHeight = (graph_height) / 12;

        //Return dynamic color based on intervals in legendData
        const colorScale = d => {
            for (let i = 0; i < legendData.length; i++) {
                if (d.variance + baseTemperature < legendData[i].interval) {
                    return legendData[i].color;
                }
            }
            return 'darkred';
        };

        //Return abbreviate month string from month decimal
        const timeParseFormat = d => {
            if (d === 0) return '';
            return d3.timeFormat('%b')(d3.timeParse('%m')(d));
        };

        //Append heatmap bars, styles, and mouse events
        chart.selectAll('g')
            .data(data).enter().append('g')
            .append('rect')
            .attr('x', d => {return (d.year - yearRange[0]) * barWidth})
            .attr('y', d => {return (d.month - 1) * barHeight})
            .style('fill', colorScale)
            .attr('width', barWidth)
            .attr('height', barHeight)
            //https://github.com/Caged/d3-tip/issues/231
            .on('mouseover', function(d){
                // tooltip.html(timeParseFormat(d.month) + ' ' + d.year + '<br/>' +
                    // d3.format('.4r')(baseTemperature + d.variance) + ' &degC<br/>' + d.variance + ' &degC' )
                    // .style('left', d3.event.pageX - 35 + 'px')
                    // .style('top', d3.event.pageY - 73 + 'px')
                    // .style('opacity', .9);
                t.show(d, this);
            }).on('mouseout', () => {
                t.hide();
            // tooltip.style('opacity', 0)
            //     .style('left', '0px');
        });

        //Append x axis
        let xAxis = chart.append('g')
            .attr('class','axis axis--x')
            .attr('transform','translate(0,' + (this.state.height - this.shape.height) + ')');
        xAxis.call(d3.axisBottom(xScale).tickFormat(d3.format('.4')));

        //Append y axis
        let yAxis = chart.append('g')
            .attr('transform','translate(0,-' + (barHeight / 2-30) + ')');
            yAxis.call(d3.axisLeft(yScale).tickFormat(timeParseFormat))
            .attr('class','axis axis--y');

        //Append y axis label
        yAxis.append('text')
            .attr('transform','translate(-30, ' + (graph_height / 2)  + ') rotate(-90)')
            .style('text-anchor','middle')
            .attr("class", "gridTitle")
            .text('Month');

        //Append x axis label
        xAxis.append('text')
             .attr('transform','translate(' + (graph_width / 2) + ',' + 30 + ')')
            .attr("class", "gridTitle")
            .style('text-anchor','middle')
            .text('Year');

        //Append color legend using legendData
        let legend_w = 20;

        let legend = chart.append('g')
            .attr("transform", "translate(" + (width - (legendData.length * legend_w) - this.margin.left - 20) + ", 0)");

        legend.selectAll('legend_rect').data(legendData).enter()
            .append('rect')
            .attr("class", "legend_rect")
            .attr('width', legend_w)
            .attr('height', 20)
            .attr('x', (d, i) => { return i * legend_w;})
            .attr('y', 0)
            .style('fill', d => {return d.color; });

        //Append text labels for legend from legendData
        legend.append('g')
            .selectAll('legend_text')

            .data(legendData).enter().append('text')
            .attr("class", "legend_text")
            .attr('x', (d,i) => {return i * (legend_w) + legend_w/2})
            .attr('y', 20)
            .attr("dy", 10)
            .text(d => {return d.interval; });

    }

    render() {
        let eClassName = this.props.eClassName ? this.props.eClassName: "";
        let chartClassName = "chart heatmap" + " " + eClassName;
        return (
            <div>
                {/*{this.chartHeaderController.getChartHeader(this.state.headerW + 13)}*/}
                <div
                    className={chartClassName}
                    // ref={el => this.cloudTwo=el}
                    style={{
                        width: (this.state.width),
                        height: (this.state.height),
                        display: this.state.display
                    }}
                >
                    <div ref={graph => this.graph = graph}
                         style={{
                             width: (this.state.width),
                             height: (this.state.height),
                         }}
                    >
                    </div>
                </div>
            </div>
        );
    }
}

export default HeatMap;