import _ from "lodash";
import React from "react";
import {Col, Row} from "react-bootstrap";
import ColorRangeLegend from "../../components/charts/legends/ColorRange_vertical";

const d3 = require("d3");

const renderLegend = (args) => {
  let infoHeight = 105;
  let legendHeight = args.heatMapHeight;
  let sliderHeight = legendHeight - infoHeight - 20 - 14; // additional to adjust padding with ColorRangeLegend
  d3.select(".heatmap-legend-container").select("svg").remove();

  args.setState({
    legendContainer: (
      <div
        id="test-component-exp-legend"
        style={{
          backgroundColor: "white",
          borderRadius: "3px",
          border: "2px solid #1073c9",
          alignItems: "center",
          height: args.heatMapHeight,
          width: args.state.barChartWidth,
          position: "relative",
          paddingLeft: "0.2em",
          // left: 60
          left: args.state.barChartWidth / 2.5
            // args.state.expand
            // ? args.state.barChartWidth / 2.5
            // : (args.state.barChartWidth / 2.5),
        }}
      >
        <Row>
          <div className={"legend-col"}>
            <div
              className={"line-legend-label"}
              style={{ fontWeight: "bold", fontSize: "0.75rem" }}
            >
              Seasonal Mean
              {/* Seasonal Mean Trend */}
            </div>
            <div
              style={{
                position: "relative",
                // left: args.state.barChartWidth / 3,
              }}
            >
              <div
                className={"line-legends"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "3px",
                }}
              >
                  <span
                    className={"legend-color"}
                    style={{
                      // width:"10px",
                      borderBottomColor: args.seasonalMeanPrLineColor,
                    }}
                  > </span>
                {/*<span className={"legend-label"}>PR (kgm<sup>-2</sup>s<sup>-1</sup>)</span>*/}
                <span className={"legend-label"}>PR (mm/day)</span>
              </div>
              <div
                className={"line-legends"}
                style={{ display: "flex", alignItems: "center" }}
              >
                  <span
                    className={"legend-color"}
                    style={{
                      // width:"10px",
                      borderBottomColor: args.seasonalMeanTasmaxLineColor,
                    }}
                  >
                    {" "}
                  </span>
                <span className={"legend-label"}>TASMAX (<sup>o</sup>C)</span>
              </div>
            </div>
          </div>
        </Row>
        <hr style={{ margin: "1px 0 3px 0" }}/>
        <Row style={{ display: "flex", margin: "auto 0 auto 0" }}>
          <div
            className={"line-legend-label checkbox"}
            style={{
              fontSize: "0.7rem",
              fontWeight: "bold",
              marginBottom: "4px",
            }}
          >
            Relative PR
          </div>
          <label
            className="switch"
            style={{
              marginBottom: "4px",
              position: "relative",
              top: "4px",
              // left: args.state.barChartWidth / 3,
            }}
          >
            <input
              type="checkbox"
              onClick={() =>
                args.setState({
                  relativeHeatmapIntensity: !args.state
                    .relativeHeatmapIntensity,
                })
              }
              checked={args.state.relativeHeatmapIntensity}
            />
            <span
              className="slider round"
              style={{ transform: "scale(1/2)" }}
            >
                {" "}
              </span>
          </label>
        </Row>
        <hr style={{ margin: "1px 0 3px 0" }}/>

        <Row style={{ height: "1rem" }}>
          {args.state.relativeHeatmapIntensity ? (
            <div
              className={"line-legend-label checkbox"}
              style={{ fontSize: "0.7rem", fontWeight: "bold" }}
            >
              PR vs. Projected Mean (1985-2006)
            </div>
          ) : (
            <div
              className={"line-legend-label checkbox"}
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Precipitation
            </div>
          )}
        </Row>
        <Row style={{ position: "relative", top: "1em" }}>
          <Row>
            <Col
              lg={5}
              md={5}
              style={{
                display: "flex",
                padding: "0px !important",
                position: "relative",
                left: "-10px",
              }}
            >
              {args.state.relativeHeatmapIntensity ? (
                <div className="heatmap-legend-container">
                  <ColorRangeLegend
                    // label={"PR vs. Historical Mean (2006-2035)"}
                    label={""}
                    scale={[-1, 1.5]}
                    parseData={false}
                    data={{
                      labels: args.RelPrColorLabels,
                      colors: _.map(args.RelPrColorLabels, (rpr) =>
                        args.colorScaleRelative(rpr)
                      ),
                    }}
                    width={55}
                    height={legendHeight - infoHeight - 10}
                    barHeight={6}
                  />
                </div>
              ) : (
                <div className="heatmap-legend-container">
                  <ColorRangeLegend
                    // label={"Precipitation"}
                    label={""}
                    scale={[0, 10]}
                    parseData={false}
                    data={{
                      labels: args.PrColorLabels,
                      colors: _.map(args.PrColorLabels, (pr) =>
                        args.colorScalePr(pr)
                      ),
                    }}
                    width={55}
                    height={legendHeight - infoHeight - 10}
                    barHeight={6}
                  />
                </div>
              )}
            </Col>
            <Col lg={5} md={5}>
              <div className={"slider-radio"}></div>
              <div
                className={"legend-col verticalSlider"}
                style={{
                  width: sliderHeight,
                  transform: "rotate(-90deg)",
                  transformOrigin: (sliderHeight * 0.3 + "px ") + (sliderHeight * 0.7) + "px"
                }}
              >
                {args.renderRangeSlider()}
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    ),
  });
};

export default renderLegend;