import React from "react";
import _ from "lodash";

const d3 = require("d3");
export default class ChartWrapper {
  constructor (chartO, chart_name, props) {
    this.props = props;
    this.margin = {top: 25, right: 25, bottom: 40, left: 50};
  }
  getFixedMargin(margin) {
    return {top: margin, right: margin, bottom: margin, left: margin};
  }

  getSVG(graphRef, width, height, margin) {
    return d3.select(graphRef).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  }

  addLegend = (svg, legends, colorScale, width, args= {}) => {
    let dataIndex = args.x || 10;
    let labelY = args.y || 0;
    let offset = (width / legends.length) - 5;
    let legend = svg.selectAll('.legends'+args.c)
      .data(legends)
      .enter().append('g')
      .attr("class", "legends")
      .attr("transform", (d, i) => {
        if (i === 0) {
          let translate = "translate(" + dataIndex + "," + labelY + ")";
          dataIndex = d.length + offset;
          return translate
        } else {
          let newDataL = dataIndex;
          dataIndex +=  d.length + offset;
          return "translate(" + (newDataL) + "," + labelY + ")"
        }
      });

    legend.append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 10)
      .attr("height", 10)
      .on("mouseover", (d, i) => {
        if (args.onMouseOver) args.onMouseOver(i);
      })
      .on("mouseout", (d, i) => {
        if (args.onMouseOut) args.onMouseOut();
      })
      .on("mousedown", (d, i) => {
        if (args.onClick) args.onClick(d);
      })
      .style("fill", function (d, i) {
        if (args.colorFunc)  return args.colorFunc(d);
        return colorScale[i]
      });

    legend.append('text')
      .attr("x", 12)
      .attr("y", 8)
      .text(function (d, i) {
        return _.startCase(d)
      })
      .attr("class", "textselected")
      .style("text-anchor", "start")
      .style("font-size", 11);
  };

}
