import _ from "lodash";
import React from "react";
import DataTable from "react-data-table-component";
import {Row, Col} from "react-bootstrap";
import OP_URL from "../../../utils/URLParameters";
import ManageDataSet from "../../../requestProcessors/manageDataSets";
import Tabled from "../../charts/confusionMatrix";
import "../../../stylesheets/views/visrec/dataSelection.css";


class DataSelection extends React.Component {
  constructor(props) {
    super(props);
    this.canvasHeight = this.props.canvasHeight;
    this.canvasWidth = this.props.canvasWidth;
    this.default = {
      dataSourceParameterKey: "dataset",
      matrixWidth: this.canvasWidth - this.canvasWidth * 0.25 - 40,
      matrixHeight: this.canvasHeight * 0.3,
      tableHeight: this.canvasHeight * 0.7,
      dataColumnFormatter: (row, k) => row[k] !== undefined? row[k]: ""
    };
    this.state = {
      dataFileName: null,
      dataFileLocation: null,
      dataFileTags: [],
      dataInfo: {
        sDataColumnTypes: {
          categorical: [],
          quantitative: []
        },
        sFeatureCorrelations: {},
        selectedColumns: {
          categorical: null,
          quantitative: null,
        },
        cCorrelations: {},
        columnInfo: [],
        columnInfoLabels: {},
        sDataDimension: [0, 0],
      },

      dataTable: {
        columns: [
          { name: 'Name', selector: 'name'},
          { name: 'Data Type', selector: 'dtype'},
          { name: 'Type', selector: 'type'},
          { name: 'Min', selector: row => this.default.dataColumnFormatter(row, 'min')},
          { name: 'Max', selector: row => this.default.dataColumnFormatter(row, 'max')},
          { name: 'Mean', selector: row => this.default.dataColumnFormatter(row, 'mean')},
          { name: 'Std Deviation', selector: row => this.default.dataColumnFormatter(row, 'standard_deviation')},
          { name: 'Most Occurred', selector: row => _.keys(this.default.dataColumnFormatter(row, 'most_occurred'))[0]},
          { name: 'Unique Values', selector: row => this.default.dataColumnFormatter(row, 'unique_values_count')}
        ]
      }
    };
    this.datasetProcessor = new ManageDataSet();
  }

  componentDidMount() {
    let parameterFileName = OP_URL.GetURLParameter(this.default.dataSourceParameterKey);
    if (parameterFileName !== null) {
      this.datasetProcessor.getDataSetList().then(datasets => {
        let isDataFileExists = false;
        _.forEach(datasets, dataset => {
          if (parameterFileName === dataset.location) {
            isDataFileExists = true;
            this.setState({
              dataFileName: dataset.name,
              dataFileLocation: parameterFileName,
              dataFileTags: dataset.tags.split(",")
            });
            return false;
          }
        });
        // let isDataFileExists = _.indexOf(dataLocationList, parameterFileName);
        if (isDataFileExists !== -1) {
          this.datasetProcessor.getDataSetProperties(parameterFileName).then(data => {
            if (data && data.columns) {
              this.datasetProcessor.getFeatureCorrelations(parameterFileName).then(corr_data => {
                this.setState({
                  dataInfo: {
                    sDataColumnTypes: data.columns,
                    sFeatureCorrelations: corr_data,
                    columnInfo: _.values(data.columnData),
                    columnInfoLabels: data.columnDataLabels,
                    sDataDimension: data.dimension
                  },
                  displayRecommendationPanels: "",
                  displayGeoJSON: false
                });
                OP_URL.UpdateURLParameter(this.default.dataSourceParameterKey, parameterFileName);
              });
            }
          })
        }
      });
    }
  }

  renderConfusionMatrix() {
    //    for correlation among features
    let featureCorrelations = this.state.dataInfo.sFeatureCorrelations;
    let data = [];
    let dataColumns = [];
    _.forEach(featureCorrelations, (d, key) => {
      data.push(d); dataColumns.push(key);
    });
    if (data.length !== 0 && dataColumns.length !== 0)
      return (
        <div id={"feature-correlation-matrix"}>
          <Tabled height={this.default.matrixHeight} width={this.default.matrixWidth} data={data} dataColumns={dataColumns} type={"triangleMatrix"}
                  setChartHeader={false} xLabel={"Feature Correlations"} domainRange={[-1, 0, 1]}
                  colorMapRange={['#ff5722', '#ffc107', '#4caf50']}
          />
        </div>
      );
  }

  render() {
    return(
      <div>
        <Row className={"component-row"}>
          <Col md={3}>
            <div className={"content-wrapper component"}  style={{height: (this.default.matrixHeight+50)/2}}>
              <span className={"section-label"}>Dataset</span>
              <h3>{this.state.dataFileName}</h3>
              <span>{_.map(this.state.dataFileTags, t => <span className={'data-tag'}>{t.toUpperCase()}</span>)}</span>
            </div>
            <div className={"content-wrapper component"}  style={{height: (this.default.matrixHeight+50)/2}}>
              <span className={"section-label"}>Data Dimension</span>
              <div style={{fontSize: "48px"}}>{this.state.dataInfo.sDataDimension[0]} x {this.state.dataInfo.sDataDimension[1]}</div>
            </div>
          </Col>
          <Col md={9}>
            <div className={"content-wrapper component"}>
              <span className={"section-label"}>Feature Correlations</span>
              {this.renderConfusionMatrix()}
            </div>
          </Col>
        </Row>
        <Row className={"component-row"}>
          <Col md={12}>
            <div className={"table-wrapper component"}>
              <DataTable
                title={"Feature Properties"}
                columns={this.state.dataTable.columns}
                data={this.state.dataInfo.columnInfo}
                pagination={true}
                paginationPerPage={8}
                // overflowYOffset={this.default.tableHeight}
                // selectableRows={true}
                // defaultSortAsc={false}
                // defaultSortField={"created"}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

}

export default DataSelection;