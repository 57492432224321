import _ from "lodash";
import React from 'react';
import "../../stylesheets/TimelineNavigator.css";


class TimelineNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.defaultSelected = this.props.selected || 0;
    this.activeClassName = "active";
    this.state = {
      width: this.props.width,
      height: this.props.height,
      count: this.props.count || 60
    }
  }

  onTimeStampClick = (e, index) => {
    _.forEach(e.target.parentNode.childNodes, n => n.classList.remove(this.activeClassName));
    e.target.classList.add(this.activeClassName);
    this.props.onTimeChange(index);
  };

  onTimeStampMouseEnter = (index) => {
    this.tooltipInfo.innerText = this.props.onHoverInfo(index);
  };

  onTimeStampMouseLeave = (e) => {
    this.tooltipInfo.innerText = this.props.info;
  };

  drawNavigator = () => {
    let iWidth = this.state.width / this.state.count - 1;
    return _.map(_.range(0, this.state.count), i => {
      return <span
        className={"timestamp " + (i === this.defaultSelected? this.activeClassName: "")}
        style={{width: iWidth}}
        onClick={(e) => this.onTimeStampClick(e, i)}
        onMouseEnter={(e) => this.onTimeStampMouseEnter(i)}
        onMouseLeave={this.onTimeStampMouseLeave}
      >
      </span>
    });
  };

  render() {
    return(
      <div
        className={"timeline-navigator"}
        ref={ref => this.navigatorContainer = ref}
        style={{height: this.state.height, width: this.state.width}}
      >
        <div style={{margin: "0 auto", width: this.state.width-20}}>
          {this.drawNavigator()}
        </div>
        <div>
        <span
          ref={ref => this.tooltipInfo = ref}
          className={"timeline-tooltip"}
        >{this.props.info}</span>
        </div>
      </div>
    )
  }
}

export default TimelineNavigator;