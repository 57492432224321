import _ from "lodash";
import React from "react";
import ChartWrapper from "../wrapper/ChartWrapper";
import "../../stylesheets/charts/barChart.css";
import sampleData from "../../data/sampleBarData";

const d3 = require("d3");

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartWrapper = new ChartWrapper(this, "bar-chart", this.props);
    this.dimensions = this.props.dimensions || {x: "label", y: "value"};

    this.default = {
      data: sampleData,
      margin: _.cloneDeep(this.chartWrapper.margin),
      width: 500,
      height: 250,
      xDimension: this.dimensions.x,
      yDimension: this.dimensions.y,
    };

    this.state = {
      data: this.props.data || this.default.data,
      height: this.props.height || this.default.height,
      width: this.props.width || this.default.width,
      fillPercentageHeight: this.props.fillPercentageHeight || 0.5

    };

    this.margin = this.default.margin;
  }

  componentDidMount() {
    this.createBarChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("prevProps", prevProps);
    // console.log(this.props);
  }

  reDrawBarChart() {
    this.removeBarChart();
    this.createBarChart();
  }

  updateDataInState(data) {
    this.setState({
      data: data,
    });
    this.reDrawBarChart();
  }

  removeBarChart() {
    d3.select(this.graph).select("svg").remove();
  }

  createBarChart() {
    let margin = this.margin,
      width = this.state.width - margin.left - margin.right,
      height = this.state.height - margin.top - margin.bottom;
    let xDimension = this.default.xDimension;
    let yDimension = this.default.yDimension;
    let data = this.state.data;

    let colorScale = d3.schemeTableau10;

    // set the ranges
    let x = d3.scaleBand()
      .range([0, width])
      .padding(0.1);
    let y = d3.scaleLinear()
      .range([height, 0]);

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    let svg = d3.select(this.graph).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    // get the data
    // format the data
    data.forEach(function(d) {
      d[yDimension] = +d[yDimension];
    });

    // Scale the range of the data in the domains
    x.domain(data.map(function(d) { return d[xDimension]; }));
    y.domain([0, d3.max(data, function(d) { return d[yDimension]; })]);

    // append the rectangles for the bar chart
    svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", function(d) { return x(d[xDimension]); })
      .attr("width", x.bandwidth())
      .attr("y", function(d) { return y(d[yDimension]); })
      .attr("height", function(d) { return height - y(d[yDimension]); })
      .style("fill", (d, i) => colorScale[i%10]);

    // add the x Axis
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // add the y Axis
    svg.append("g")
      .call(d3.axisLeft(y));

  }

  render() {
    return <div id={"bar-chart"} ref={(ref) => (this.graph = ref)}></div>;
  }
}

export default BarChart;
