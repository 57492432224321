import axios from "axios";
import React from "react";
import DataServerConfig from "../manage/requestProcessor/dataServer";


class ManageDataSet {
  constructor(props) {
    this.dataServer = DataServerConfig.Server;
    this.dataServerAPI = DataServerConfig.ServerAPI;
  }

  getHeaders() {
    return new Headers({
      credentials : 'same-origin',
      'Access-Control-Allow-Origin': '*',
    });
  }

  async getDataSetList(dataFileType = []) {
    let response = await fetch(
      this.dataServer +
      this.dataServerAPI.DataSetList.path,
      {
        credentials: "same-origin",
        "Access-Control-Allow-Origin": "*"
      }
    ).catch(error => console.log(error));
    try {
      return await response
        .json()
        .catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  async getDataSetProperties(file_name) {
    let response = await fetch(
      this.dataServer + this.dataServerAPI.DataProperties.path + "?data_file="+file_name,
      {
        headers: this.getHeaders()
      }
    ).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  async getFeatureCorrelations(file_name) {
    let response = await fetch(
      this.dataServer + this.dataServerAPI.FeatureCorrelations.path + "?data_file="+file_name,
      {
        headers: this.getHeaders(),
      }
    ).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  async saveDataFile(dataFile) {
    return await axios.post(
      this.dataServer + this.dataServerAPI.UploadDataFile.path,
      dataFile, {
        headers: {
          credentials : 'same-origin',
          'Access-Control-Allow-Origin': '*'
        },
      }
    ).catch(error => {
      console.log(error);
    });
  }

  async fetchNEXDCP30(file_name) {
    let response = await fetch(
      this.dataServer + this.dataServerAPI.FetchNEXDCP30.path + "?file_name="+file_name,
      {
        headers: this.getHeaders(),
      }
    ).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  async getSeasonalRegionalMean(season = "", region = "", model="GISS-E2-R") {
    let response = await fetch(
      this.dataServer + this.dataServerAPI.GetSeasonalRegionalMean.path +
        "?season="+season+ "&region="+region + "&model="+model,
      {
        headers: this.getHeaders(),
      }
    ).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  async getTimeStampIndex(fromYear=2015, toYear=2018, season=null) {
    let response = await fetch(
      this.dataServer + this.dataServerAPI.GetTimeStampIndex.path +
        "?from_year="+fromYear+ "&to_year="+toYear + "&season="+season,
      {
        headers: this.getHeaders(),
      }
    ).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL!"));
    } catch (e) {
      return [];
    }
  }

  // Third party requests
  async getGeoLocation(lat, lon) {
    let key = "AIzaSyCCI7-QA3ZNNe1h6bUi-EZntVKogRnIOlw";
    let url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lon + "&key=" + key;
    let response = await fetch(url).catch(error => console.log(error));
    try {
      return await response.json().catch(error => alert("ERROR IN DATA RETRIEVAL FROM GCP!"));
    } catch (e) {
      return [];
    }

  }

}

export default ManageDataSet;