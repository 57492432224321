import _ from "lodash";
import React from "react";
import ParallelCoordinates from "../../charts/parallelcoordinates";
import PrecipitationMean from "../../../data/GISS_E2_R/precipitation_mean";
import NetCDFTimeStamps from "../../../data/GISS_E2_R";
import LineChart from "../../charts/LineChart";
import { Row, Col, Form } from "react-bootstrap";
import ManageDataSet from "../../../requestProcessors/manageDataSets";
import HorizontalStackedBarChart from "../../charts/HorizontalStackedBarChart";
import TreeMap from "../../charts/TreeMap";
import ChartWrapper from "../../wrapper/ChartWrapper";

import "../../../stylesheets/views/scrollbar_thin.css";
import SeasonalRegionalSmallMultiples from "./SeasonalRegionalSmallMultiples";

class SummaryCharts extends React.Component {
  constructor(props) {
    super(props);
    this.dataProcessor = new ManageDataSet();
    this.state = {
      seasonalRegionalParameters: { seasons: "", regions: "" },
      seasonalRegionalResponseData: {},
      seasonalRegionalData: [],
      regionalSeasonalData: null,
      minMeanPrecipitation: 0,
      maxMeanPrecipitation: 5,
      seasonColorScale: ["#51a32d", "#fb1a4f", "#ff9252", "#01b6cb"],
      lineChartSetting: { hover: "seasons", click: "regions" },
      seasons: ["spring", "summer", "fall", "winter"],
      regions: [
        "southeast",
        "northeast",
        "m-atlantic",
        "west",
        "northwest",
        "southwest",
        "midwest",
      ],
      regionAcronyms: {
        southeast: "SE",
        northeast: "NE",
        "m-atlantic": "MA",
        west: "W",
        northwest: "NW",
        southwest: "SW",
        midwest: "MW",
      },
      //[DY] 2021.02.02
      selectedRegion: this.props.selectedRegion || null,
      selectedSeason: this.props.selectedSeason || null,
      doReordering: this.props.doReordering || false,
    };

    this.alertController = this.props.alertController;
    this.seasonalBarChartHeight = this.props.sFrameHeight * 0.25;
    this.chartLegendHeight = this.props.sFrameHeight * 0.02;
    this.seasonalRegionalTrendHeight = this.props.sFrameHeight * 0.45;
    this.lineChartSmallMultiplesHeight =
      (this.seasonalRegionalTrendHeight * 0.92) / this.state.regions.length;
    this.lineChartSmallMultipleGridWidth = this.props.sChartWidth - 16 - 5;
    this.lineChartSmallMultiplesWidth =
      this.lineChartSmallMultipleGridWidth / this.state.seasons.length;
    this.treeMapHeight = this.props.sFrameHeight * 0.28;

    this.chartWrapper = new ChartWrapper(this, "summary", this.props);
  }

  componentDidMount() {
    this.getSeasonalRegionalMeanData();
    this.createLegend();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps', nextProps);
    this.setState(nextProps);
}

  componentDidUpdate(prevProps, prevState, snapshot) {
    // let clickOption = this.state.lineChartSetting.click;
    // if (this.state.seasonalRegionalParameters[clickOption] !== prevState.seasonalRegionalParameters[clickOption])
    if (
      this.state.seasonalRegionalParameters.seasons !==
        prevState.seasonalRegionalParameters.seasons ||
      this.state.seasonalRegionalParameters.regions !==
        prevState.seasonalRegionalParameters.regions
    )
      this.getSeasonalRegionalMeanData();
  }

  createLegend = () => {
    // let chartMargin = _.clone(this.chartWrapper.margin);
    let chartMargin = { top: 10, right: 0, bottom: 3, left: 15 };
    let svg = this.chartWrapper.getSVG(
      this.chartLabelRef,
      this.props.sChartWidth,
      this.chartLegendHeight,
      chartMargin
    );
    let seasonLabels = ["Spring", "Summer", "Fall", "Winter"];
    this.chartWrapper.addLegend(
      svg,
      seasonLabels,
      this.state.seasonColorScale,
      this.props.sChartWidth
    );
  };

  getSeasonalRegionalMeanData = () => {
    this.dataProcessor
      .getSeasonalRegionalMean(
        this.state.seasonalRegionalParameters.seasons,
        this.state.seasonalRegionalParameters.regions
      )
      .then((res) => {
        let chartData = [];

        if (res.data) {
          _.forEach(res.data, (season_data, i) => {
            _.forEach(season_data, (region_data, c) => {
              chartData.push(region_data);
            });
          });

          this.setState({
            seasonalRegionalData: chartData,
            minMeanPrecipitation: res.min_pr,
            maxMeanPrecipitation: res.max_pr,
            seasonalRegionalResponseData: res.data,
            regionalSeasonalData: res.regional_seasonal_data,
          });
        }
      });
  };

  updateLineChartData = (t) => {
    let seasonalRegionalParameters = _.clone(
      this.state.seasonalRegionalParameters
    );
    seasonalRegionalParameters[this.state.lineChartSetting.click] = t;
    this.setState({ seasonalRegionalParameters: seasonalRegionalParameters });
  };

  updateSeasonalRegionalParameter = (key, value) => {
    let seasonalRegionalParameters = _.clone(
      this.state.seasonalRegionalParameters
    );
    seasonalRegionalParameters[key] = value;
    this.setState({ seasonalRegionalParameters: seasonalRegionalParameters });
  };

  render() {
    return (
      <div>
        {/*<div className={"content-wrapper component"} style={{width: this.props.sChartWidth}}>*/}
        {/*  <ParallelCoordinates*/}
        {/*    height={this.props.sChartHeight}*/}
        {/*    width={this.props.sChartWidth}*/}
        {/*    data={PrecipitationMean}*/}
        {/*    dataPointCircle={true}*/}
        {/*    colorAttribute={"year"}*/}
        {/*    dataFilePrefix={this.props.filePrefix}*/}
        {/*    timestamps={NetCDFTimeStamps}*/}
        {/*    onCircleClick={this.onDataSetChange}*/}
        {/*  />*/}
        {/*</div>*/}
        <div
          className={"content-wrapper component"}
          style={{ width: this.props.sChartWidth }}
        >
          {/*<GroupedBarChart height={this.props.sChartHeight} width={this.props.sChartWidth}/>*/}
          <HorizontalStackedBarChart
            height={this.seasonalBarChartHeight}
            width={this.props.sChartWidth}
            colorScale={this.state.seasonColorScale}
          />
        </div>
        {/* <div className={"content-wrapper component"} style={{width: this.props.sChartWidth}}>
        </div> */}
        {/* below multiple line charts component is commented out because it has an error */}
        <div
          className={"content-wrapper component thin"}
          style={{
            width: this.props.sChartWidth,
            height: this.seasonalRegionalTrendHeight,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <SeasonalRegionalSmallMultiples
            sChartWidth={this.props.sChartWidth}
            regions={this.state.regions}
            seasons={this.state.seasons}
            seasonalRegionalResponseData={
              this.state.seasonalRegionalResponseData
            }
            lineChartSmallMultiplesHeight={this.lineChartSmallMultiplesHeight}
            lineChartSmallMultiplesWidth={this.lineChartSmallMultiplesWidth}
            minMeanPrecipitation={this.state.minMeanPrecipitation}
            maxMeanPrecipitation={this.state.maxMeanPrecipitation}
            seasonalRegionalParameters={this.state.seasonalRegionalParameters}
            lineChartSetting={this.state.lineChartSetting}
            seasonColorScale={this.state.seasonColorScale}
            alertController={this.alertController}
            regionAcronyms={this.state.regionAcronyms}
          />
        </div>
        <div
          className={"content-wrapper component"}
          style={{ width: this.props.sChartWidth }}
        >
          {this.state.regionalSeasonalData != null ? (
            <TreeMap
              height={this.treeMapHeight}
              width={this.props.sChartWidth}
              colorScale={this.state.seasonColorScale}
              // data={this.state.regionalSeasonalData}
              data={this.state.seasonalRegionalResponseData}
              regionalSeasonalData = {this.state.regionalSeasonalData}
              //seasonalRegionalData = {this.state.seasonalRegionalResponseData}
              doDrawRegionFirst = {true}
              // [DY] 2020.02.02
              doReordering={this.state.doReordering}
              selectedRegion={this.state.selectedRegion}
              selectedSeason={this.state.selectedSeason}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default SummaryCharts;
