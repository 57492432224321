import _ from "lodash";
import React, {useState} from "react";
import {Nav, Navbar, Container} from "react-bootstrap";
import CookieManager from "../../utils/CookieManager";
import ManageUserData from "../../requestProcessors/manageUserData";
import NAVICON from "../../images/esva-blue.png";
import {Link} from "react-router-dom";
import {UserIcon} from "../../utils/IconContainer";

const d3 = require("d3");

export default function HorizontalNavigation({
   height,
   width,
   regionAcronyms,
   regionIndex,
   regionalColorScale,
   onClickRegionAcronyms,
   currentView,

   //Expand toggle
   isExpand,
   onClickExpand,
   //RelativeHeatmapIntensity
   isRelativeHeatmapIntensity,
   onClickRelativeHeatmapIntensity,
   currentModel,
}) {
   let userCookieName = "username";
   const cookieManager = new CookieManager();
   const userRequestProcessor = new ManageUserData();

   const [loggedInUsername, setLoggedInUsername] = useState(
     cookieManager.readCookie(userCookieName)
   );
   const [userInfoPanelActive, setUserInfoPanelActive] = useState(0);
   const [userPasswordInput, setUserPasswordInput] = useState(null);
   const [usernameInput, setUsernameInput] = useState(null);

   let onClickLogin = () => {
      if (usernameInput) {
         userRequestProcessor
           .userAuthentication(usernameInput, userPasswordInput)
           .then((res) => {
              if (res.data.username === usernameInput) {
                 cookieManager.eraseCookie(userCookieName);
                 cookieManager.createCookie(userCookieName, usernameInput, 365);

                 setUsernameInput(null);
                 setUserPasswordInput(null);
                 setLoggedInUsername(usernameInput);
                 setUserInfoPanelActive(0);
              }
           });
      }
   };

   let onClickLogout = () => {
      cookieManager.eraseCookie(userCookieName);
      setUserInfoPanelActive(1);
      setLoggedInUsername(null);
   };

   let renderUserInfo = (username) => {
      if (username) {
         return (
           <div>
          <span className={"user-info-username"}>
            <b>{username}</b>
          </span>
              <button className={"btn btn-sm btn-danger"} onClick={onClickLogout}>
                 Logout
              </button>
           </div>
         );
      } else {
         return (
           <div>
              <div>
                 <input
                   className={"form-control input-sm"}
                   type={"text"}
                   placeholder={"Username"}
                   onChange={(e) => setUsernameInput(e.target.value)}
                 />
              </div>
              <div>
                 <input
                   className={"form-control input-sm"}
                   type={"password"}
                   placeholder={"Password"}
                   onChange={(e) => setUserPasswordInput(e.target.value)}
                 />
              </div>
              <button className={"btn btn-sm btn-success"} onClick={onClickLogin}>
                 Login
              </button>
           </div>
         );
      }
   };


   let userInfoNavClick = () => {
      setUserInfoPanelActive(!userInfoPanelActive);
   };

   return (
     <div style={{width: width}}>
        {/*<div className={"nav-brand-icon"}>*/}
        {/*   <img src={NAVICON} height={36} width={36} />*/}
        {/*</div>*/}
        <Navbar bg="primary" className={"nav-brand-icon"}>
           {/*<Container>*/}
              <Navbar.Brand href="#home">
                 DCPViz
                 {/*<img src={NAVICON} height={36} width={36} />*/}
              </Navbar.Brand>
              <Nav className="me-auto" style={{height: height}}>
                 <Nav.Link className={"model-label region"}>Model</Nav.Link>
                 <Nav.Link href="#home" style={{padding: 0, width: 200}}>
                    <div className={"cDropdown cNavItem"}>
                       <div className={"cDropdown-title"}>{currentModel}</div>
                       <ul className={"cDropdown-options"}>
                          <li className={"cDropdown-option"} id={'CESM1'}>
                             <div>CESM1-CAM5</div>
                             <ul className={'cDropdown-scenarios'} id={'CESM1-options'}>
                                <li className={"cDropdown-scenario"}><Link to={'/cesm1-cam5/'}>CESM1-CAM5 RCP8.5</Link></li>
                                <li className={"cDropdown-scenario"}><Link to={'/cesm1-cam5-rcp45/'}>CESM1-CAM5 RCP4.5</Link></li>
                                <li className={"cDropdown-scenario"}><Link to={'/cesm1-cam5-rcp26/'}>CESM1-CAM5 RCP2.6</Link></li>
                             </ul>
                          </li>
                          <li className={"cDropdown-option"} id={'GISS'}>
                             <div>GISS-E2-R</div>
                             <ul className={'cDropdown-scenarios'} id={'GISS-options'}>
                                <li className={"cDropdown-scenario"}><Link to={"/giss-e2-r/"}>GISS-E2-R RCP 8.5</Link></li>
                             </ul>

                          </li>
                          {/*<li className={"cDropdown-option"}><Link to={'/cesm1-cam5/'}>CESM1-CAM5-RCP8.5</Link></li>*/}
                          {/*<li className={"cDropdown-option"}><Link to={'/cesm1-cam5-rcp45/'}>CESM1-CAM5-RCP4.5</Link></li>*/}
                          {/*<li className={"cDropdown-option"}><Link to={'/cesm1-cam5-rcp26/'}>CESM1-CAM5-RCP2.6</Link></li>*/}
                          {/*<li className={"cDropdown-option"}><Link to={"/giss-e2-r/"}>GISS-E2-R</Link></li>*/}
                       </ul>
                    </div>
                 </Nav.Link>
                 <Nav.Link className={"region-label region"}>Regions</Nav.Link>
                 {_.reverse(_.map(_.keys(regionAcronyms), (region, index) => {
                    return (
                      <Nav.Link
                        id={"region" + index}
                        style={{
                           backgroundColor: regionalColorScale[index],
                           // borderLeftColor: regionalColorScale[index],
                           cursor: "pointer",
                        }}
                        className={"region " + (index === currentView ? "selected" : "")}
                        onClick={(e) => {
                           if (region === "US") return;
                           document
                             .getElementById("region" + currentView)
                             .classList.toggle("selected");

                           document
                             .getElementById("region" + index)
                             .classList.toggle("selected");

                           d3.selectAll(".slide-background").classed("selected", false);

                           let currentIndex = regionIndex[regionAcronyms[region]];

                           onClickRegionAcronyms(currentIndex);
                        }}
                      >
                         {region}
                      </Nav.Link>
                    );
                 }))}
                 <Nav.Link className={"region-label region"}>Expand</Nav.Link>
                 {/*<Nav.Link>*/}
                    <div className={"slider-radio"} style={{marginTop: "0.8em"}}>
                       <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() => onClickExpand()}
                            checked={isExpand}
                          />
                          <span className="slider round"> </span>
                       </label>
                    </div>
                 {/*</Nav.Link>*/}

                 <Nav.Link className={"username-label"}>
                    <div className={"navbar-nav sidebar nav-footer"}>
                       <div
                         className={
                            "user-nav-item " +
                            (userInfoPanelActive ? "active " : "") +
                            (loggedInUsername ? "loggedIn" : "")
                         }
                       >
                          <a href={"#"} onClick={userInfoNavClick}>
                             <UserIcon /> <span>{loggedInUsername? loggedInUsername: ""}</span>
                          </a>
                          <div className={"floating-label"}>
                             {renderUserInfo(loggedInUsername)}
                          </div>
                       </div>
                    </div>
                 </Nav.Link>

                 {/*<Nav.Link href="#features">Features</Nav.Link>*/}
                 {/*<Nav.Link href="#pricing">Pricing</Nav.Link>*/}
              </Nav>
           {/*</Container>*/}
        </Navbar>
     </div>
  )
}
