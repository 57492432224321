import React from "react";
import _ from "lodash";
import { Row } from "react-bootstrap";
import LineChart from "../charts/TestComponentExp.LineChart";
import SummaryChartNavigation from "../navigation/SummaryChartNavigation";

class SeasonalRegionalSmallMultiples extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover:false,
      showAllSmallMultiples: false,
      allMultiplesTopMargin: 0
    };

    // console.log(props);

    // let currentRegion = props.currentRegion;
    // console.log(
    //   this.props.seasonalRegionalResponseData["spring"][currentRegion]
    // );

    this.floatingLineChartRef = React.createRef();
    this.floatingChartContainer = undefined;
  }

  componentDidMount() {
    this.floatingChartContainer = document.getElementById(
      "floating-seasonal-regional-line-chart"
    );
  }

  onFloatingLineChartView = (left, top, ref, args) => {
    let chartDiv = this.floatingChartContainer;
    chartDiv.style.display = "";
    chartDiv.style.left = left + "px";
    chartDiv.style.top = top + "px";
    let chartData = this.props.seasonalRegionalResponseData[args.season][
      args.region
    ];

    ref.current.setState(
      {
        data: [chartData],
        colorScale: [this.props.seasonColorScale[args.colorIndex]],
        trendline: true,
        xLabel:
          _.capitalize(args.region) +
          " " +
          _.capitalize(args.season) +
          " Season Trend",
      },
      () => {
        ref.current.removeLineChart();
        ref.current.createLineChart();
      }
    );
  };

  onFloatingLineChartHide = (ref) => {
    this.floatingChartContainer.style.display = "none";
  };

  render() {
    return (
      <div>
        {/* tooltip */}
        {/*<div*/}
        {/*  className={"content-wrapper component thin"}*/}
        {/*  style={{*/}
        {/*    width: 550,*/}
        {/*    height: 400,*/}
        {/*    overflowX: "hidden",*/}
        {/*    overflowY: "scroll",*/}
        {/*    display: this.state.showAllSmallMultiples? "block": "none",*/}
        {/*    position: "absolute",*/}
        {/*    zIndex: 2000,*/}
        {/*    top: this.state.allMultiplesTopMargin*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <SeasonalRegionalAllSmallMultiples*/}
        {/*    sChartWidth={540}*/}
        {/*    regions={this.props.regions}*/}
        {/*    seasons={this.props.seasons}*/}
        {/*    seasonalRegionalResponseData={this.props.seasonalRegionalResponseData}*/}
        {/*    lineChartSmallMultiplesHeight={52}*/}
        {/*    lineChartSmallMultiplesWidth={131}*/}
        {/*    minMeanPrecipitation={this.props.minMeanPrecipitation}*/}
        {/*    maxMeanPrecipitation={this.props.maxMeanPrecipitation}*/}
        {/*    seasonalRegionalParameters={this.props.seasonalRegionalParameters}*/}
        {/*    lineChartSetting={this.props.lineChartSetting}*/}
        {/*    seasonColorScale={this.props.seasonColorScale}*/}
        {/*    alertController={this.alertController}*/}
        {/*    regionAcronyms={this.props.regionAcronyms}*/}
        {/*  />*/}
        {/*</div>*/}
        {/* end tooltip */}

        <Row
          style={{
            cursor: "pointer",
            marginLeft: "0.5rem",
            fontWeight: "bold",
            fontSize: "0.7rem"
          }}>
          <SummaryChartNavigation
            current={"line"}
            header={"Seaonal Regional PR & TASMAX Trends"}
            body={"A seasonal Time series, displays the regional mean trends of PR and TASMAX"}
            onChartChange={this.props.onChartChange}
          />

          {/*Seaonal Regional PR & TASMAX Trends*/}
          {/*<span*/}
          {/*  style={{paddingLeft: 2}}*/}
          {/*  onMouseEnter={(e) => {*/}
          {/*    // console.log(e.screenX, e.screenY);*/}
          {/*    this.setState({*/}
          {/*      showAllSmallMultiples: true,*/}
          {/*      allMultiplesTopMargin: e.screenY - 550 - 10*/}
          {/*    })*/}
          {/*  }}*/}
          {/*  onMouseOut={() => this.setState({showAllSmallMultiples: false})}*/}
          {/*><ThIcon/></span>*/}

          {/*<CaretRightFill id="popover-description" style={{margin: "auto 0"}}/>*/}
          {/*<Popover*/}
          {/*  placement="right"*/}
          {/*  isOpen={this.state.showPopover}*/}
          {/*  target={"popover-description"}*/}
          {/*>*/}
          {/*  <PopoverHeader> Objective </PopoverHeader>*/}
          {/*  <PopoverBody>A seasonal Time series, displays the regional mean trends of PR and TASMAX</PopoverBody>*/}
          {/*</Popover>*/}
        </Row>
        <Row
          className={"custom-layout"}
          style={{ width: this.props.sChartWidth }}
        >
          {_.map(this.props.regions, (region, ri) => {
            return _.map(this.props.seasons, (season, i) => {
              if (
                region == this.props.currentRegion &&
                this.props.seasonalRegionalResponseData[season] &&
                this.props.seasonalRegionalResponseData[season][region]
              )
                return (
                  <Row
                    className={"seasonal-regional-small-multiple"}
                    // sm={12 / this.props.seasons.length}
                    style={{
                      // width: this.props.lineChartSmallMultiplesWidth,
                      width: this.props.lineChartSmallMultiplesWidth,
                      maxWidth: "100%",
                    }}
                  >
                    <LineChart
                      data={[
                        this.props.seasonalRegionalResponseData[season][region],
                      ]}
                      updateLineChartData={this.updateLineChartData}
                      xDomain={_.range(1985, 2100, 1)}
                      yRange={[
                        this.props.minMeanPrecipitation,
                        this.props.maxMeanPrecipitation + 1,
                      ]}
                      // yLabel={season}
                      legends={[season]}
                      tickSize={[10, 5]}
                      trendline={true}
                      margin={{ top: 5, right: 40, bottom: 20, left: 30 }}
                      lineStrokeWidth={2}
                      height={this.props.lineChartSmallMultiplesHeight}
                      width={this.props.lineChartSmallMultiplesWidth}
                      selectedState={
                        this.props.seasonalRegionalParameters[
                          this.props.lineChartSetting.click
                        ]
                      }
                      colorScale={[this.props.seasonColorScale[i]]}
                      // legends={[region + " - " + season] }
                      // removeAxisBottom={true}
                      // removeAxisLeft={true}
                    />
                  </Row>
                );
              return "";
            });
          })}
        </Row>
      </div>
    );
  }
}

export default SeasonalRegionalSmallMultiples;
