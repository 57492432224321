import _ from "lodash";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {UserIcon} from "../../utils/IconContainer";
import {sideNavigationItems, settingsNavItems, documentationNavItems} from "../../manage/navigation/SideNavigation";
import "../../stylesheets/NavBrand.css";
import NAVICON from "../../images/esva-blue.png";
import CookieManager from "../../utils/CookieManager";
import ManageUserData from "../../requestProcessors/manageUserData";

export default function SideNavigationIcon() {
  let userCookieName = "username";
  const cookieManager = new CookieManager();
  const userRequestProcessor = new ManageUserData();

  const [loggedInUsername, setLoggedInUsername] = useState(cookieManager.readCookie(userCookieName));
  const [userInfoPanelActive, setUserInfoPanelActive] = useState(0);
  const [userPasswordInput, setUserPasswordInput] = useState(null);
  const [usernameInput, setUsernameInput] = useState(null);

  let onClickLogin = () => {
    if (usernameInput) {
      userRequestProcessor.userAuthentication(usernameInput, userPasswordInput).then(res => {
        if (res.data.username === usernameInput) {
          cookieManager.eraseCookie(userCookieName);
          cookieManager.createCookie(userCookieName, usernameInput, 365);

          setUsernameInput(null);
          setUserPasswordInput(null);
          setLoggedInUsername(usernameInput);
          setUserInfoPanelActive(0);
        }
      });
    }
  };

  let onClickLogout = () => {
    cookieManager.eraseCookie(userCookieName);
    setUserInfoPanelActive(1);
    setLoggedInUsername(null);
  };

  let userInfoNavClick = () => {
    setUserInfoPanelActive(!userInfoPanelActive);
  };

  let renderSideNavItems = (navItems) => {
    return _.map(navItems, (item) => {
      return (
        <li className={"nav-item"}>
          {/*<item.icon/>*/}
          <Link to={item.pathname}>
            <item.icon/>
          </Link>
          <div className={"floating-label"}>{item.label}</div>
        </li>
      );
    });
  };

  let renderUserInfo = (username) => {
    if (username) {
      return <div>
        <span className={"user-info-username"}><b>{username}</b></span>
        <button className={"btn btn-sm btn-danger"} onClick={onClickLogout}>Logout</button>
      </div>
    }
    else {
      return <div>
        <div><input className={"form-control input-sm"} type={"text"} placeholder={"Username"} onChange={(e) => setUsernameInput(e.target.value)}/></div>
        <div><input className={"form-control input-sm"} type={"password"} placeholder={"Password"} onChange={(e) => setUserPasswordInput(e.target.value)}/></div>
        <button className={"btn btn-sm btn-success"} onClick={onClickLogin}>Login</button>
      </div>
    }
  };

  return (
    <div className={"nav-brand"}>
      <div className={"nav-brand-icon"}>
        <img src={NAVICON} height={42} width={42}/>
      </div>
      {/*<NavbarBrand>iVISREC</NavbarBrand>*/}
      <hr/>
      <div className={"sidenav"}>
        <ul className={"navbar-nav sidebar"}>
          {renderSideNavItems(sideNavigationItems)}
        </ul>
        <hr/>
        <ul className={"navbar-nav sidebar"}>
          {renderSideNavItems(documentationNavItems)}
        </ul>
        <hr/>
        <ul className={"navbar-nav sidebar"}>
          {renderSideNavItems(settingsNavItems)}
        </ul>
        <ul className={"navbar-nav sidebar nav-footer"}>
          <li className={"user-nav-item " + (userInfoPanelActive? "active ": "") + (loggedInUsername? "loggedIn": "")}>
            <a href={"#"} onClick={userInfoNavClick}><UserIcon/></a>
            <div className={"floating-label"}>
              {renderUserInfo(loggedInUsername)}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
