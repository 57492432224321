import _ from "lodash";
import React from "react";
import ChartWrapper from "../wrapper/ChartWrapper";
import "../../stylesheets/charts/barChart.css";
import sampleData from "../../data/sampleBarData";

const d3 = require("d3");

class HorizontalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartWrapper = new ChartWrapper(this, "bar-chart", this.props);
    this.dimensions = this.props.dimensions || {y: "label", x: "value"};

    this.default = {
      data: sampleData,
      margin: _.cloneDeep(this.chartWrapper.margin),
      width: 500,
      height: 250,
      xDimension: this.dimensions.x,
      yDimension: this.dimensions.y
    };

    this.state = {
      data: this.props.data || this.default.data,
      height: this.props.height || this.default.height,
      width: this.props.width || this.default.width,
      fillPercentageHeight: this.props.fillPercentageHeight || 0.5,
      removeXAxis: this.props.removeXAxis || false,
      removeYAxis: this.props.removeYAxis || false,
      padding: this.props.padding || 0.1,
      xDomain: this.props.xDomain
    };

    this.info = this.props.info;
    this.margin = this.props.margin || this.default.margin;

    this.tooltip = d3.select("body").append("div")
      .attr("class", "image-tooltip")
      .style("background", 'white')
      .style("left","100px")
      .style("top", "-100px")
      .style("height", "110px")
      .style("width", "170px");
  }

  componentDidMount() {
    this.createBarChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("prevProps", prevProps);
    // console.log(this.props);
    if (this.props.focusBar && this.props.focusBar !== prevProps.focusBar)
      this.reDrawBarChart();
  }

  reDrawBarChart() {
    this.removeBarChart();
    this.createBarChart();
  }

  updateDataInState(data) {
    this.setState({
      data: data,
    });
    this.reDrawBarChart();
  }

  removeBarChart() {
    d3.select(this.graph).select("svg").remove();
  }

  createBarChart() {
    let margin = this.margin,
      width = this.state.width - margin.left - margin.right,
      height = this.state.height - margin.top - margin.bottom;
    let xDimension = this.default.xDimension;
    let yDimension = this.default.yDimension;
    let tooltip = this.tooltip;
    let data = this.state.data;
    let labelInfo = this.info || {};

    let colorScale = this.props.colorScale || d3.schemeTableau10;

    // set the ranges
    let x = d3.scaleLinear()
      .range([0, width]);

    let y = d3.scaleBand()
      .range([height, 0]).padding(this.state.padding);

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    let svg = d3.select(this.graph).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    // get the data
    // format the data
    // data.forEach(function(d) {
    //   d[yDimension] = +d[yDimension];
    // });

    // Scale the range of the data in the domains
    y.domain(data.map(function(d) { return d[yDimension].toString(); }));
    x.domain(this.state.xDomain || [0, d3.max(data, function(d) { return d[xDimension]; })]);

    // append the rectangles for the bar chart
    svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      // .attr("x", function(d) { return x(d[xDimension]); })
      .attr("width", d => x(d.value))
      .attr("y", function(d) {return y(d[yDimension].toString()); })
      .attr("height", y.bandwidth())
      .on("mouseover", function (d, index) {
        tooltip.transition()
          .duration(200)
          .style("opacity", .9);

        tooltip.html(
          "<span><b>Regional Mean PRCP</b></span><br/>" +
          "<span> Year: " + labelInfo.year + "</span><br/>" +
          (labelInfo.season? "<span> Season: " + _.capitalize(labelInfo.season) + "</span><br/>": "") +
          "<span> Region: " + _.capitalize(labelInfo.regionList[index]) + "</span><br/>" +
          "<span> Mean PRCP: " + d.value.toFixed(2) + " mm/day </span><br/>"
        )
        .style("left", (d3.event.pageX) + "px")
        .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", (d) => {
        this.tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      })
      .style("fill", (d, i) => colorScale[i%10])
      .style("opacity", (d) => {
        if (this.props.focusBar && d.label.toString() !== this.props.focusBar.toString())
          return 0.2;
        return 1;
      });

    if (!this.state.removeXAxis)
      // add the x Axis
      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));

    if (!this.state.removeYAxis)
      // add the y Axis
      svg.append("g")
        .call(d3.axisLeft(y));

  }

  render() {
    return <div id={"bar-chart"} ref={(ref) => (this.graph = ref)}> </div>;
  }
}

export default HorizontalBarChart;
