const React = require("react");


function PageNotFound(props) {
  return (
    <div className={"page-not-found"}>
      <div className={"error"}>
        <span>404</span>
      </div>
      <div className={"error-message"}>
        <span>The page you are looking for does not exist</span>
      </div>
    </div>
  );
}

export default PageNotFound;
