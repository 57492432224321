import React from "react";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import LineChart from "../../charts/LineChart";

class SeasonalRegionalSmallMultiples extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.floatingLineChartRef = React.createRef();
    this.floatingChartContainer = undefined;
  }

  componentDidMount() {
    this.floatingChartContainer = document.getElementById(
      "floating-seasonal-regional-line-chart"
    );
  }

  onFloatingLineChartView = (left, top, ref, args) => {
    let chartDiv = this.floatingChartContainer;
    chartDiv.style.display = "";
    chartDiv.style.left = left + "px";
    chartDiv.style.top = top + "px";
    let chartData = this.props.seasonalRegionalResponseData[args.season][
      args.region
    ];

    ref.current.setState(
      {
        data: [chartData],
        colorScale: [this.props.seasonColorScale[args.colorIndex]],
        trendline: true,
        xLabel:
          _.capitalize(args.region) +
          " " +
          _.capitalize(args.season) +
          " Season Trend",
      },
      () => {
        ref.current.removeLineChart();
        ref.current.createLineChart();
      }
    );
  };

  onFloatingLineChartHide = (ref) => {
    this.floatingChartContainer.style.display = "none";
  };

  render() {
    return (
      <>
        <div
          className={"floating-line-chart seasonal-regional-small-multiple"}
          id={"floating-seasonal-regional-line-chart"}
          style={{ display: "none" }}
        >
          <LineChart
            ref={this.floatingLineChartRef}
            data={[[]]}
            updateLineChartData={this.updateLineChartData}
            xDomain={_.range(1985, 2100, 1)}
            yRange={[
              this.props.minMeanPrecipitation,
              this.props.maxMeanPrecipitation + 1,
            ]}
            yLabel={["Precipitation (mm/day)", "Max Temperature (K)"]}
            xLabel={"Regional Seasonal Trend"}
            tickSize={[5, 5]}
            trendline={false}
            margin={{ top: 25, right: 50, bottom: 40, left: 30 }}
            lineStrokeWidth={2}
            height={180}
            width={320}
            // selectedState={this.props.seasonalRegionalParameters[this.props.lineChartSetting.click]}
            colorScale={[this.props.seasonColorScale[1]]}
            // legends={[region + " - " + season] }
          />
        </div>
        <Row
          className={"custom-layout"}
          style={{ width: this.props.sChartWidth }}
        >
          {_.map(this.props.regions, (region, ri) => {
            return _.map(this.props.seasons, (season, i) => {
              if (
                this.props.seasonalRegionalResponseData[season] &&
                this.props.seasonalRegionalResponseData[season][region]
              )
                return (
                  <Col
                    className={"seasonal-regional-small-multiple"}
                    sm={12 / this.props.seasons.length}
                    style={{
                      width: this.props.lineChartSmallMultiplesWidth,
                      maxWidth: "100%",
                    }}
                    onMouseEnter={(e) =>
                      this.onFloatingLineChartView(
                        this.props.lineChartSmallMultiplesWidth * (i + 1) + 3,
                        this.props.lineChartSmallMultiplesHeight * (ri + 1),
                        this.floatingLineChartRef,
                        { season: season, region: region, colorIndex: i }
                      )
                    }
                    onMouseLeave={(e) =>
                      this.onFloatingLineChartHide(this.floatingLineChartRef)
                    }
                  >
                    <LineChart
                      data={[
                        this.props.seasonalRegionalResponseData[season][region],
                      ]}
                      // updateLineChartData={this.updateLineChartData}
                      xDomain={_.range(1985, 2100, 1)}
                      yRange={[
                        this.props.minMeanPrecipitation,
                        this.props.maxMeanPrecipitation + 1,
                      ]}
                      yLabel={
                        season === "spring"
                          ? _.invert(this.props.regionAcronyms)[region]
                          : undefined
                      }
                      trendline={true}
                      margin={{
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: season === "spring" ? 15 : 5,
                      }}
                      height={this.props.lineChartSmallMultiplesHeight}
                      width={this.props.lineChartSmallMultiplesWidth}
                      selectedState={
                        this.props.seasonalRegionalParameters[
                          this.props.lineChartSetting.click
                        ]
                      }
                      colorScale={[this.props.seasonColorScale[i]]}
                      // legends={[region + " - " + season] }
                      removeAxisBottom={true}
                      removeAxisLeft={true}
                    />
                  </Col>
                );
              return "";
            });
          })}
        </Row>
      </>
    );
  }
}

export default SeasonalRegionalSmallMultiples;
