import React from "react";
import sampleData from "../../data/sample-horizontal-stacked-bar-chart";
import ChartWrapper from "../wrapper/ChartWrapper";
import _ from "lodash";
import {Row} from "react-bootstrap";
import ManageDataSet from "../../requestProcessors/manageDataSets";
import SummaryChartNavigation from "../navigation/SummaryChartNavigation";

const d3 = require("d3");
class HorizontalStackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartWrapper = new ChartWrapper(this, "bar-chart", this.props);
    this.default = {
      data: sampleData,
      margin: _.cloneDeep(this.chartWrapper.margin),
      variables: {
        tasmax: {key: "tasmax", label: "Max. Temperature (C)"},
        pr: {key: "pr", label: "Precipitation (mm/day)"}
      }
    };
    this.state = {
      data: this.props.data || this.default.data,
      width: this.props.width || 920,
      height: (this.props.height || 540) - 15,
      yDomain: this.props.yDomain || [0, 7],
      xDomain: this.props.xDomain || _.range(2006, 2099, 1),
      colorScale: this.props.colorScale || [
        "steelblue",
        "darkorange",
        "lightblue",
        "#01b6cb",
      ],
      showPopover: false,
      variable: this.default.variables.pr.key,
    };

    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "image-tooltip")
      .style("background", "white")
      .style("left", "100px")
      .style("top", "-100px")
      .style("height", "115px")
      .style("width", "185px");

    this.margin = this.default.margin;
    this.margin.top = 20;
    this.margin.right = 30;
    this.margin.left = 35;
    this.margin.bottom = 20;

    this.dataProcessor = new ManageDataSet();
    this.seasons = ["s0", "s1", "s2", "s3"];
    this.seasonLabels = ["Spring", "Summer", "Fall", "Winter"];
  }

  componentDidMount() {
    // this.createBarChart();
   this.prepareChartData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.variable !== this.state.variable){
      this.removeChart();
      this.prepareChartData();
    }
  }

  prepareChartData = () => {
    let variable = this.state.variable;
    let pData = [];
    let minVal = Number.POSITIVE_INFINITY, maxVal = Number.NEGATIVE_INFINITY;
    let resultAddedInBucket = [];
    _.forEach(this.props.predictionScenarios, (ps, i) => {
      this.dataProcessor
        .getSeasonalRegionalMean(
          "",
          this.props.currentRegion,
          // this.PredictionModel
          ps
        )
        .then((res) => {
          let thisRegion = this.props.currentRegion;
          if (res.data) {
            let data = res.data;
            let dataLength = data[this.seasonLabels[0].toLowerCase()][thisRegion].length;
            _.forEach(_.range(0, dataLength), (d) => {
              let datum = {};
              _.forEach(this.seasonLabels, (s, ind) => {
                if (ind===0) {
                  datum.year = data[s.toLowerCase()][thisRegion][d].year;
                  datum.scenario = ps;
                }
                datum["s"+ind] = data[s.toLowerCase()][thisRegion][d][variable];
              });
              pData.push(datum)
            });

            let vv = variable.replace("max", "");
            if (res['min_'+vv] < minVal) minVal = Math.floor(res['min_'+vv]);
            if (res['max_'+vv] > maxVal) maxVal = Math.ceil(res['max_'+vv]);
          }
          // if (i === this.props.predictionScenarios.length - 1) {
          resultAddedInBucket.push(ps);
          if (resultAddedInBucket.length === this.props.predictionScenarios.length) {
            this.setState({
              yDomain: [minVal, maxVal],
              // yDomain: [25, 40],
              data: _.filter(pData, d => d.year >=2006)
            }, () => {
              this.removeChart();
              this.createBarChart();
            });
          }
        });
    });
  };

  removeChart = () => {
    d3.select(this.graph).select("svg").remove();
    d3.select(this.graphXAxis).select("svg").remove();
  };

  createBarChart = () => {
    // let speed = 0;
    // let barHeight = 10;
    let tooltip = this.tooltip;
    let groupData = this.state.data;
    let axisLabel = this.default.variables[this.state.variable].label;
    let movingAverages = [];
    let seasons = this.seasons;
    let seasonLabels = this.seasonLabels;
    let quartileOpacityMap = { Lower: 0.2, Second: 0.5, Third: 0.8, Upper: 1 };
    let inverseQuartileMap = _.invert(quartileOpacityMap);
    // let seasonColors = this.state.colorScale;

    let margin = this.margin,
      width = this.state.width - margin.left - margin.right,
      height = this.state.height - margin.top - margin.bottom;

    let seasonQuantiles = {};
    _.forEach(_.range(0, seasons.length, 1), (s) => {
      let seasonData = _.map(groupData, (d) => d["s" + s]);
      seasonQuantiles["s" + s] = d3
        .scaleQuantile()
        .range(_.values(quartileOpacityMap))
        .domain(seasonData);
    });

    // let chartHeight = barHeight*114; // barHeight * numberOfYears
    let chartHeight = height;
    let x = d3
      .scaleBand()
      .range([0, width])
      .padding(0.1)
      .domain(this.state.xDomain); // reversed why?
    // let z = d3.scaleOrdinal().range(seasonColors).domain(seasons);

    let chartMargin = _.clone(margin);
    // chartMargin.top = 5;
    let svg = this.chartWrapper.getSVG(
      this.graph,
      width,
      chartHeight,
      chartMargin
    );

    // this.chartWrapper.addLegend(svg, seasonLabels, seasonColors, width/2);

    svg
      .append("g")
      .attr("transform", "translate(2," + height + ")")
      .attr("class", "x axis")
      .call(
        d3.axisBottom(x)
          // .tickValues(_.concat(_.range(this.state.xDomain[1], this.state.xDomain[0], -15), [1985]))
          .tickValues(_.concat(_.range(this.state.xDomain[this.state.xDomain.length - 1], this.state.xDomain[0], -16), [2006]))
      )
      .append("text")
      .attr("y", x.bandwidth())
      .style("text-anchor", "end")
      .style("font-weight", "bold")
      .text("Value");

    svg.append("text")
      .attr("class", "gridTitle")
      .attr("transform", "translate(-25, " + (chartHeight/2) + ") rotate(-90)")
      .text(axisLabel);


    let axisHeight = chartHeight / seasons.length;
    _.forEach(_.range(0, seasons.length, 1), (i) => {
      let yScaleMin = axisHeight * i;
      let yScaleMax = axisHeight * (i + 1);

      let y = d3.scaleLinear()
        .range([yScaleMax, yScaleMin])
        .domain(this.state.yDomain);

      svg
        .append("g")
        .attr("width", 25)
        .attr("height", axisHeight)
        .append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(y).tickValues(_.range(
          this.state.yDomain[0], this.state.yDomain[1],
          // 1
          this.state.yDomain[1] <= 7? 1: Math.ceil((this.state.yDomain[1] - this.state.yDomain[0])/7)
        )));
      // draw bars
      svg.append("g").style("fill", 'white')
        .selectAll(".bar bar" + i)
        .data(groupData)
        .enter()
        .append("rect")
        .attr("class", d => {
          let cls = "";
          if (_.includes(d.scenario, 'RCP26')) cls = "RCP26";
          else if (_.includes(d.scenario, 'RCP45')) cls = "RCP45";
          else cls = "RCP85";

          return "bar bar-"+cls + " bar-"+d.year;
        })
        .attr("x", function (d) {
          return x(d.year)+2;
        })
        .attr("width", x.bandwidth())
        .attr("y", function (d) {
          return y(d["s" + i]);
        })
        .attr("height", function (d) {
          return yScaleMax - y(d["s" + i]);
        })
        // .style("fill", z('s'+i))
        .on("mouseover", function (d, index) {
          let season = "s" + i;
          tooltip.transition().duration(200).style("opacity", 0.9);

          let scenarioData = _.filter(groupData, di => di.year === d.year);
          let tooltipContent = "<span> <b>Year: </b>" + d.year +
            "</span><br/>" +
            "<span><b>" + axisLabel + ": </b>" +
            // d[season] +
            "</span><br/>";

          _.forEach(scenarioData, sd => tooltipContent += "<u>" + (_.includes(sd.scenario, "RCP")? sd.scenario: (sd.scenario+"-RCP8.5")) + " </u>: " + sd[season] + "</span><br/>");

            tooltip
            .html(tooltipContent)
            .style("left", d3.event.pageX + "px")
            .style("top", d3.event.pageY - 28 + "px");
        })
        .on("mouseout", (d) => {
          tooltip.transition().duration(500).style("opacity", 0);
        })
        .style("stroke", "#f5fffe")
        .style("fill", d => {
          if (_.includes(d.scenario, 'RCP26'))
            return "#f5fffe";
            // return '#ddf9fc';
          else if (_.includes(d.scenario, 'RCP45')) return '#1565C0';
          return '#bd370d';
        })
        .style("opacity", function (d) {
          if (_.includes(d.scenario, 'RCP26')) return 1;
          else if (_.includes(d.scenario, 'RCP45')) return 0.7;
          return 0.9;
          // let season = "s" + i;
          // let value = d[season];
          // return seasonQuantiles[season](value);
        });

      svg
        .append("g")
        .attr("transform", "translate(2," + yScaleMax + ")")
        .attr("class", "x axis")
        .attr("opacity", 0.2)
        .call(
          d3.axisBottom(x)
            .tickSize(1)
            // .tickValues(_.concat(_.range(this.state.xDomain[this.state.xDomain.length - 1], this.state.xDomain[0], -16), [2006]))
            .tickValues([])
        );

      // draw lines
      let starting_from = 5;
      let line = d3
        .line()
        .x(function (d) {
          return x(d.year);
        }) // set the x values for the line generator
        .y(function (d, index) {
          let base = i * seasons.length;
          let from_index = starting_from + index - 4;
          let to_index = starting_from + index;
          if (from_index >= 0 && to_index <= groupData.length) {
            let subset = _.map(
              _.slice(groupData, from_index, to_index),
              (d) => d["s" + i]
            );
            let ma = _.mean(subset);
            movingAverages.push(ma);
            return y(ma);
          }
          movingAverages.push(base);
          return y(base);
        }); // set the y values for the line generator

      // svg
      //   .append("path")
      //   .datum(_.slice(groupData, starting_from))
      //   .attr("class", "line line" + i)
      //   .attr("transform", "translate(" + x.bandwidth() / 2 + ", " + 0 + ")")
      //   .style("stroke", "black")
      //   .style("stroke-width", 2)
      //   .style("opacity", 0.5)
      //   .attr("d", line);

      // draw legend
      // ex> sping/ summer / fall/ winter
      // svg.append("rect")
      // .attr("x", x(groupData[0].year)+5)
      // .attr("y", y(3) - 7.5)
      // .attr("width", 10)
      // .attr("height", 10)
      // .attr("fill", "gray");

      // svg
      //   .append("text")
      //   .attr("x", x(groupData[0].year) + 17)
      //   .attr("y", y(3))
      //   .text(seasonLabels[i])
      //   .style("font-size", "10px")
      svg
        .append("text")
        .attr("transform", "translate(" + (width+margin.left-15) + ", " + (yScaleMax-axisHeight/2) + ") rotate(90)")
        // .attr("x", width-margin.right)
        // .attr("y", y(this.state.yDomain[0] + _.sum(this.state.yDomain)/2))
        .text(seasonLabels[i])
        .style("font-size", "10px")
    });
    // svg
    //   .append("line")
    //   .style("stroke", "black")
    //   .style("stroke-width", 2)
    //   .style("opacity", 0.5)
    //   .attr("x1", width - 90)
    //   .attr("y1", 0)
    //   .attr("x2", width - 75)
    //   .attr("y2", 0);
    //
    // svg
    //   .append("text")
    //   .attr("x", width - 70)
    //   .attr("y", 3)
    //   .text("Moving Avg")
    //   .attr("class", "textselected")
    //   .style("text-anchor", "start")
    //   .style("font-size", 11);
    svg
      .append("line")
      .style("stroke", "#1565C0")
      .style("stroke-width", 4)
      .style("opacity", 0.8)
      .attr("x1", width - 80)
      .attr("y1", 0)
      .attr("x2", width - 70)
      .attr("y2", 0);
    svg
      .append("text")
      .attr("x", width - 65)
      .attr("y", 3)
      .text("RCP 4.5")
      .attr("class", "textselected")
      .style("text-anchor", "start")
      .style("font-size", 11);

    svg
      .append("line")
      .style("stroke", "#bd370d")
      .style("stroke-width", 4)
      .style("opacity", 0.8)
      .attr("x1", width - 150)
      .attr("y1", 0)
      .attr("x2", width - 140)
      .attr("y2", 0);
    svg
      .append("text")
      .attr("x", width - 135)
      .attr("y", 3)
      .text("RCP 8.5")
      .attr("class", "textselected")
      .style("text-anchor", "start")
      .style("font-size", 11);

    svg
      .append("line")
      .style("stroke", this.state.variable === this.default.variables.pr.key? "#e04e1f": "#dadada")
      .style("stroke-width", 12)
      .style("opacity", 0.8)
      .attr("x1", width - 230)
      .attr("y1", 0)
      .attr("x2", width - 200)
      .attr("y2", 0)
      .on("click", d => this.setState({variable: this.default.variables.pr.key}));

    svg.append("text")
      .attr("x", width - 220)
      .attr("y", 3)
      .text("PR")
      .attr("class", "textselected")
      .style("fill", this.state.variable === this.default.variables.pr.key? "white": "black")
      .style("text-anchor", "start")
      .style("font-size", 11);
    svg
      .append("line")
      .style("stroke", this.state.variable === this.default.variables.tasmax.key? "#e04e1f": "#dadada")
      .style("stroke-width", 12)
      .style("opacity", 0.8)
      .attr("x1", width - 300)
      .attr("y1", 0)
      .attr("x2", width - 240)
      .attr("y2", 0)
      .on("click", d => this.setState({variable: this.default.variables.tasmax.key}));
    svg.append("text")
      .attr("x", width - 290)
      .attr("y", 3)
      .text("TASMAX")
      .attr("class", "textselected")
      .style("fill", this.state.variable === this.default.variables.tasmax.key? "white": "black")
      .style("text-anchor", "start")
      .style("font-size", 11);

    // setTimeout(() => {
      d3.selectAll(".bar-RCP85").raise();
      setTimeout(() => {d3.selectAll(".bar-RCP45").raise();}, 250);
      setTimeout(() => {d3.selectAll(".bar-RCP26").raise();}, 550);
      // d3.selectAll(".bar-RCP45").raise();
      // d3.selectAll(".bar-RCP26").raise();
    // }, 500);
  };

  render() {
    return (
      <div>
        <Row
          style={{
            cursor: "pointer",
            position: "absolute",
            height: this.heatMapHeight * 0.1,
            marginLeft: "0.5rem",
            fontWeight: "bold",
            fontSize: "0.7rem"
          }}>
          <SummaryChartNavigation
            current={"bar"}
            header={"Compare Emission Scenarios"}
            body={"Stacked Histogram to present seasonal mean precipitation.\n" +
            "                The bar color strength shows the corresponding quantile the value lies in. Bolder color means the upper\n" +
            "                quantile.\n" +
            "                The black lines represents the moving average of mean precipitation of past 5 years."}
            onChartChange={this.props.onChartChange}
          />
        </Row>
        <Row>
          <div>
            <div
              id={"horizontal-grouped-bar-chart-xaxis"}
              ref={(ref) => (this.graphXAxis = ref)}
            >
              {" "}
            </div>
            <div
              id={"horizontal-grouped-bar-chart"}
              ref={(ref) => (this.graph = ref)}
              style={{height: this.state.height, overflowY: "auto", marginTop: "0.8rem"}}
            >
              {" "}
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default HorizontalStackedBarChart;
