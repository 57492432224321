import _ from "lodash";
import React from "react";
import {Row, Col} from "react-bootstrap";
import {SimpleSelect} from "react-selectize";
import {CirclePicker} from "react-color";
import {circleColorPickerOptions} from "../../../utils/ColorPickerStyles";
import "../../../stylesheets/charts/controllers.css";

class LineChartController extends React.Component {
  constructor(props) {
    super(props);
    this.default = {
      firstPositionID: 1,
      positionIncrements: 1,
      maxXAttributeSelection: 3,
      dimensionItem: {attribute: undefined, color: circleColorPickerOptions[6]}
    };
    this.colorPickerRefs = {};
    this.state = {
      datasetName: this.props.datasetName,
      datasetLocation: this.props.datasetLocation,
      validDimensions: this.filterDimensions(this.props.dimensions),
      xDimensions: {1: _.clone(this.default.dimensionItem)},
      yDimension: {}

    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dimensions !== prevProps.dimensions) {
      this.setState({
        datasetName: this.props.datasetName,
        datasetLocation: this.props.datasetLocation,
        validDimensions: this.filterDimensions(this.props.dimensions)
      });
    }
  }

  filterDimensions = (dimensions) => {
    return dimensions.quantitative;
  };

  onXDimensionChange = (selection, position) => {
    let xDimensions = this.state.xDimensions;
    if (selection === undefined) {
      delete xDimensions[position];
      delete this.colorPickerRefs[position];
    }
    else {
      xDimensions[position]["attribute"]= selection.value; // = {attribute: selection.value, color: circleColorPickerOptions[position]};
      if (_.size(this.state.xDimensions) < this.default.maxXAttributeSelection)
        xDimensions[position+this.default.positionIncrements] = _.clone(this.default.dimensionItem);
    }

    this.setState({xDimensions: xDimensions});
  };

  renderOptions = (options) => {
    return _.map(options, dimension => {
      return <option value={dimension}>{dimension}</option>
    });
  };

  renderSimpleSelect = (dimension, position) => {
    return (
      <Col md={3}>
        <div className={"attribute-selection"}>
          <SimpleSelect
            placeholder="Select x-attribute"
            onValueChange={value => this.onXDimensionChange(value, position)}
          >
            {this.renderOptions(this.state.validDimensions)}
          </SimpleSelect>
        </div>
        <div>
          <div className={"swatch"} onClick={ () => this.colorPickerRefs[position].style.display = "" }>
            <div style={{background: dimension.color }}> </div>
          </div>
          <div
            ref={ref=>this.colorPickerRefs[position]=ref}
            style={{display: "none"}}
          >
            <CirclePicker
              className={"color-picker"}
              colors={circleColorPickerOptions}
              circleSize={20}
              circleSpacing={12}
              // onSwatchHover={(color,e) => console.log(color)}
              onChange={(color,e) => {
                let xDimensions = this.state.xDimensions;
                xDimensions[position].color = color.hex;
                this.setState({xDimensions: xDimensions});
                this.colorPickerRefs[position].style.display = "none"
              }}
            />
          </div>
        </div>
      </Col>
    )
  };

  renderXAttributeSelection = () => {
    return  _.map(this.state.xDimensions, (dimension, id) => {
      return this.renderSimpleSelect(dimension, id);
    });
  };

  render() {
    return (
      <div>
        <Row>{this.renderXAttributeSelection()}</Row>
        {/*<Row>*/}
        {/*  <Col md={4}>*/}
        {/*    <SimpleSelect*/}
        {/*      placeholder="Select y-attribute" onValueChange={value => console.log(value)}*/}
        {/*    >*/}
        {/*      {this.renderOptions(this.state.validDimensions)}*/}
        {/*    </SimpleSelect>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </div>
    )
  }
}

export default LineChartController;
