import _ from "lodash";
import React from "react";
import URLParameters from "../../utils/URLParameters";
import charts from "../../manage/charts";
import {SimpleSelect} from "react-selectize";
import {Row, Col} from "react-bootstrap";
import ManageDataSet from "../../requestProcessors/manageDataSets";


class ExploreChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartKey = URLParameters.GetURLParameter('chart');
    this.chart = charts[this.chartKey];

    this.canvasWidth = this.props.canvasWidth;
    this.canvasHeight = this.props.canvasHeight;

    let contentGap = 50;
    this.chartWidth = this.canvasWidth-contentGap;
    this.chartHeight = this.canvasHeight*0.7;
    this.controlWidth = this.canvasWidth;
    this.controlHeight = this.canvasHeight*0.3;

    this.dataProcessor = new ManageDataSet();
    this.state = {
      datasetList: {},
      selectedDataset: {},
      dataColumns: [],
      // dataDimensions: []
    }
  }

  componentDidMount() {
    // this.dataProcessor.getDataSetList().then(data => {
    //   this.setState({datasetList: data})
    // });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedDataset.location && this.state.selectedDataset.location !== prevState.selectedDataset.location) {
      // TODO :: triggering twice for one data file change
      this.onDataSetChange();
    }
  }

  onDataSetChange = () => {
    this.dataProcessor.getDataSetProperties(this.state.selectedDataset.location).then(data => {
      this.setState({dataColumns: data.columns});
    });
  };

  triggerDataSetChange = (selectedOption) => {
    if (selectedOption === undefined) {
      this.setState({
        selectedDataset: {},
        dataColumns: []
      });
      return;
    }
    let dataSetLocation = selectedOption.value;
    _.forEach(this.state.datasetList, dataset => {
      if (dataset.location === dataSetLocation) {
        this.setState({
          selectedDataset: dataset
        });
        return false;
      }
    });
  };

  renderDatasetOption() {
    return _.map(this.state.datasetList, dataset => {
      return <option value={dataset.location}>{dataset.name}</option>
    });
  }

  render() {
    return(
      <div>
        <div className={"content-wrapper component"} style={{width: this.controlWidth, height: this.controlHeight}}>
          <Row>
            <Col md={6}>
              <SimpleSelect placeholder="Select Dataset" onValueChange={value => this.triggerDataSetChange(value)}>
                {this.renderDatasetOption()}
              </SimpleSelect>
            </Col>
            <Col md={6}>

            </Col>
          </Row>
          <div>
            {(this.chart.controller && this.state.selectedDataset.name) &&
            <this.chart.controller
              datasetName={this.state.selectedDataset.name}
              datasetLocation={this.state.selectedDataset.location}
              dimensions={this.state.dataColumns}
            />
            }
          </div>
        </div>
        <div className={"content-wrapper component"} style={{width: this.canvasWidth}}>
          <this.chart.component height={this.chartHeight} width={this.chartWidth}/>
        </div>
      </div>
    )
  }
}

export default ExploreChart;
