import React, {useState} from "react";
import {InfoIcon} from "../../utils/IconContainer";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";

export default function SummaryChartNavigation (props) {
  const [showPopover, setShowPopover] = useState(false);
  const onChartChange = props.onChartChange;
  return (
    <div className="summary-chart-options">
      <button className={"sm-chart btn btn-sm btn-"+ (props.current === "line"? "primary": "secondary")} onClick={onChartChange[0]}>Time Series</button>
      <button className={"sm-chart btn btn-sm btn-"+ (props.current === "bar"? "primary": "secondary")} onClick={onChartChange[1]}>Compare Emission Scenarios</button>
      <button className={"sm-chart btn btn-sm btn-"+ (props.current === "treemap"? "primary": "secondary")} onClick={onChartChange[2]}>Hierarchical Treemap</button>
      <span
        id={"popover-description"}
        onMouseLeave={() => setShowPopover(false)}
        onMouseOver={() => setShowPopover(true)}
      ><InfoIcon/></span>
      <Popover
        placement="right"
        isOpen={showPopover}
        target={"popover-description"}
      >
        <PopoverHeader>{props.header}</PopoverHeader>
        <PopoverBody>{props.body}</PopoverBody>
      </Popover>
    </div>
  )
}