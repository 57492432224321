import React from "react";
import {Row, Col} from "react-bootstrap";
import GeoSpatial from "../charts/GeoSpatial";
import TimelineNavigator from "../navigation/TimelineNavigator";
import NetCDFTimeStamps from "../../data/GISS_E2_R/index";


class CompareGeoJSON extends React.Component {
  constructor(props) {
    super(props);
    this.canvasWidth = this.props.canvasWidth;
    this.canvasHeight = this.props.canvasHeight;

    let contentGap = 10;
    this.chartWidth = (this.canvasWidth-contentGap)/3-20;
    this.chartHeight = this.canvasHeight*0.8;
    this.controlWidth = this.canvasWidth;
    this.controlHeight = this.canvasHeight*0.2;

    this.default = {
      leftIndex: 0,
      rightIndex: 1
    };


    this.monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.filePrefix = "user_data/pr_amon_BCSD_rcp85_r1i1p1_CONUS_ACCESS1-0_208601-209012/";
    this.state = {
      leftIndexData: NetCDFTimeStamps[this.default.leftIndex],
      rightIndexData: NetCDFTimeStamps[this.default.rightIndex]
    }
  }

  getTimeLineInfo = (indexData) => {
    let datetime = new Date(indexData.time);
    return indexData.index + ". " + indexData.filename + " - " + this.monthNames[datetime.getMonth()] + ", " + datetime.getFullYear();
  };

  getInfoByIndex = (index) => {
    return this.getTimeLineInfo(NetCDFTimeStamps[index]);
  };

  onDataSetChange = (index, side) => {
    // console.log(index);
    // console.log(NetCDFTimeStamps[index]);
    // let indexData = NetCDFTimeStamps[index];
    this.setState({
      [side+"IndexData"]: NetCDFTimeStamps[index]
    });
  };


  render() {
    return (
      <div>
        <div className={"content-wrapper component"} style={{padding: 0, width: this.canvasWidth}}>
          <Row>
            <Col md={4}>
              <GeoSpatial
                height={this.chartHeight}
                width={this.chartWidth}
                scale={[-1, 15]}
                legendSizeToWidth={1/2}
                controlTopLeft={false}
                controlBottomRight={false}
                zoom={3}
                dataSourceOption={'external'}
                dataFileName={this.filePrefix + this.state.leftIndexData.filename}
              />
            </Col>
            <Col md={4}>
              <GeoSpatial
                height={this.chartHeight}
                width={this.chartWidth}
                legendSizeToWidth={1/2}
                scale={[-14, 14]}
                controlTopLeft={false}
                zoom={3}
                controlBottomRight={false}
                dataSourceOption={'external'}
                dataFileName={"test_ri2.geojson"}
                // dataFileName={"nca_mask.geojson"}
              />
            </Col>
            <Col md={4}>
              <GeoSpatial
                height={this.chartHeight}
                width={this.chartWidth}
                scale={[-1, 15]}
                legendSizeToWidth={1/2}
                controlTopLeft={false}
                controlBottomRight={false}
                zoom={3}
                dataSourceOption={'external'}
                dataFileName={this.filePrefix + this.state.rightIndexData.filename}
              />
            </Col>
          </Row>
        </div>
        <div className={"content-wrapper component"} style={{width: this.controlWidth, height: this.controlHeight}}>
          <Row>
            <Col md={4}>
              <TimelineNavigator
                height={this.controlHeight - 20}
                width={(this.controlWidth-20)/3}
                selected={this.startIndex}
                info={this.getTimeLineInfo(this.state.leftIndexData)}
                onHoverInfo={this.getInfoByIndex}
                onTimeChange={(index) => this.onDataSetChange(index, "left")}
              />
            </Col>
            <Col md={4}></Col>
            <Col md={4}>
              <TimelineNavigator
                height={this.controlHeight - 20}
                width={(this.controlWidth-20)/3}
                selected={this.startIndex}
                info={this.getTimeLineInfo(this.state.rightIndexData)}
                onHoverInfo={this.getInfoByIndex}
                onTimeChange={(index) => this.onDataSetChange(index, "right")}
              />
            </Col>
          </Row>
        </div>

      </div>
    )
  }
}

export default CompareGeoJSON;
