import _ from "lodash";
import React from "react";
import {Link} from "react-router-dom";
import {NavIcon, UserIcon} from "../../utils/IconContainer";
import {NavbarBrand} from "react-bootstrap";
import {sideNavigationItems, settingsNavItems, documentationNavItems} from "../../manage/navigation/SideNavigation";
import "../../stylesheets/NavBrand.css";

export default function SideNavigation() {
  let renderSideNavItems = (navItems) => {
    return _.map(navItems, (item) => {
      return (
        <li className={"nav-item"}>
          <item.icon/>
          <Link to={item.pathname}>
            {/*{item.label}*/}
            <item.icon/>
          </Link>
          {/*<a href={item.pathname}>{item.label}</a>*/}
        </li>
      );
    });
  };
  return (
    <div className={"nav-brand"}>
      <NavIcon/>
      {/*<NavbarBrand>iVISREC</NavbarBrand>*/}
      <hr/>
      <div className={"sidenav"}>
        <ul className={"navbar-nav sidebar"}>
          {renderSideNavItems(sideNavigationItems)}
        </ul>
        <hr/>
        <ul className={"navbar-nav sidebar"}>
          {renderSideNavItems(documentationNavItems)}
        </ul>
        <hr/>
        <ul className={"navbar-nav sidebar"}>
          {renderSideNavItems(settingsNavItems)}
        </ul>
        <ul className={"navbar-nav sidebar nav-footer"}>
          <li className={"user-nav-item"}>
            <UserIcon/>
            {/*<a className={"sidenav-username"}>LoggedInUser</a>*/}
          </li>
        </ul>
      </div>
    </div>
  );
};
