import _ from "lodash";
import React from "react";
import {Col, DropdownButton, Form, Row} from "react-bootstrap";
import {GridIcon} from "../../utils/IconContainer";
import DataServerConfig from "../../manage/requestProcessor/dataServer";
import NEXDCP30Parameters from "../../utils/NEXDCP30Parameters";


class MapViewController extends React.Component {
  constructor(props) {
    super(props);
    this.alertController = this.props.alertController;
    this.state = {
      fromYear: 2012,
      toYear: 2015,
      season: null
    };
  }

  onGridControllerClick = () => {
    let image_url = DataServerConfig.Server + DataServerConfig.ServerAPI.FetchNEXDCP30Thumbnail.path;
    let alertProps = {title: "Map View Selection"};
    let alertContent = <div className={"data-upload-popup"}>
      <Row>
        <Col xs={4}>
          <Form.Control
            as="select" size={"sm"} name={"season"}
            onChange={(e) => console.log(e.target.value)}
          >
            {_.map(["Winter", "Spring", "Summer", "Fall"], (dimension, index) => {
              return <option value={index+1}>{dimension.toString()}</option>
            })}
          </Form.Control>
        </Col>
        <Col xs={3}>
          <Form.Control
            name={"fromYear"} id={"fromYear"} type={"number"}
            max={2099} min={1985} value={this.state.fromYear} step={1}
            placeholder={"From"} size={"sm"}
            // onChange={(e) => this.setState({fromYear: e.target.value})}
          />
        </Col>
        <Col xs={3}>
          <Form.Control
            name={"toYear"} id={"toYear"} type={"number"}
            max={2099} min={1985} value={2015} step={1}
            placeholder={"To"} size={"sm"}
            // onChange={(e) => this.setState({toYear: e.target.value})}
          />
        </Col>
        <Col xs={2}>
          <button className={"btn btn-primary"}>Filter</button>
        </Col>
      </Row>
      <div className={"slider-navigator mt-1"} style={{height: 500, overflowX: "scroll"}}>
        <div className={"slideshow"}>
          {_.map(this.props.timestamps, (item, index) => {
            let abs_url = image_url+"?file_name="+item.filePrefix+"&index_name="+item.filename;
            return <div className={"slide"}
              // onClick={(e) => this.onSlideClick(e, index)}
              // onMouseEnter={() => this.onMouseEnterSlide(index)}
              // onMouseLeave={() => this.onMouseLeaveSlide(index)}
            >
              <div
                className={"slide-background"}
                style={{backgroundImage: "url("+abs_url+")"}}
              >
              </div>
              <div className={"label"}>{item.date}</div>
            </div>
          })}
        </div>
      </div>
      <button className={"btn btn-danger mt-1"} onClick={() => this.alertController.onAlertCancel()}>Cancel</button>
    </div>;
    this.alertController.setAlert(alertProps, alertContent, "w800");

  };

  render() {
    return(
      <Row className={"action-pane"}>
        <Col sm={4} className="btn-group btn-group-sm" role="group" aria-label="Small button group">
          <button type="button" className="btn btn-primary"
                  onClick={(e) => this.props.changeGeoSpatialView(e, this.props.geoSpatialViews.single)}
          >
            Single View
          </button>
          <button type="button" className="btn btn-secondary"
                  onClick={(e) => this.props.changeGeoSpatialView(e, this.props.geoSpatialViews.comparison)}
          >
            Comparison View
          </button>
          <button type="button" className="btn btn-secondary"
                  onClick={(e) => this.props.changeGeoSpatialView(e, this.props.geoSpatialViews.multi)}
          >
            Multi View
          </button>
        </Col>
        <Col sm={1}>
          <DropdownButton
            key={"up"}
            id={`dropdown-button-drop-up`}
            drop={'up'}
            size={'sm'}
            variant="info"
            title={`Metadata`}
          >
            <div className={"legendControl metadata"} style={{height: 105}}>
              <div className={"md"}><span className={"md-title"}>Experiment</span> <span className={"md-value"}>rcp85</span></div>
              <div className={"md"}><span className={"md-title"}>Model</span> <span className={"md-value"}>access</span></div>
              <div className={"md"}><span className={"md-title"}>Variable</span> <span className={"md-value"}>pr</span></div>
              <div className={"md"}><span className={"md-title"}>Year</span> <span className={"md-value"}>07/2090</span></div>
            </div>
          </DropdownButton>
        </Col>
        <Col sm={1}>
          <DropdownButton
            key={"up"}
            id={`dropdown-button-drop-up`}
            drop={'up'}
            size={'sm'}
            variant="secondary"
            title={`Map Layers`}
          >
            <div className={"legendControl metadata"} style={{height: 120}}>
              {_.map(this.props.layerOptions, (url, label) => {
                return <Form.Check
                  inline
                  label={label}
                  type={"radio"}
                  name={"select-layer"}
                  onClick={(e) => this.props.changeMapURL(label, url)}
                />
              })}
            </div>
          </DropdownButton>
        </Col>
        <Col sm={5}> </Col>
        <Col sm={1} onClick={this.onGridControllerClick}><GridIcon/></Col>

      </Row>
    );
  }
}

export default MapViewController;