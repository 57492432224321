import React from "react";
import _ from "lodash";

const d3 = require("d3");

const drawHeatMap = (args) => {
  let currentView = args.state.currentView;
  let startYear = args.startYear; // axis is reversed
  let tooltip = args.tooltip;
  let monthDivisor = 12;
  let margin = { top: 15, right: 0, bottom: 10, left: 50 };

  let statePrHeatMapRange = args.state.heatmapRanges[1]; // only checking this for PR vs Historical
  let minPrHeatMap = statePrHeatMapRange.min;
  let maxPrHeatMap = statePrHeatMapRange.max;

  // let width = args.gridWidth;
  // let height = 200;
  let seasonalGap = args.state.barChartWidth;
  let lineChartWidth = seasonalGap - 2;
  // 5 = (the number oflinechart + legend )
  // let width = args.heatmapRef.clientWidth - seasonalGap * 4.5;
  let width = args.gridWidth;
  let height = args.heatmapRef.clientHeight;
  let marginalHeight = height - margin.top - margin.bottom;

  let recW = width / monthDivisor;

  if (args.state.heatmapCellWidth == null) {
    args.setState({ heatmapCellWidth: recW });
  } else {
    recW = args.state.heatmapCellWidth;
  }

  let recH = marginalHeight / 50;
  let svg = args.chartWrapper.getSVG(
    args.heatmapRef,
    args.heatmapRef.clientWidth,
    args.heatmapRef.clientHeight,
    margin
  );

  let pivot = 0;
  let currentSeasonInRelativePr = 0;
  let trackSeasonInMouseOver = 0;
  let colorScale = args.colorScalePr;
  let colorScaleRelative = args.colorScaleRelative;
  svg.append("g");

  svg
    .selectAll("rect")
    .data(args.heatData)
    .enter()
    .append("rect")
    .attr("class", "hrect")
    .attr("id", (d, i) => "hrect" + i)
    .attr("x", (d, i) => {
      let divider = 3; // month in a season
      let marginRight = i % divider !== 0 ? 2 : 0;
      let expandMargin = 0;

      if (args.state.expand) {
        expandMargin = Math.floor((i % monthDivisor) / 3) * -6;

        pivot = pivot + (i !== 0 && i % 3 === 0 ? seasonalGap : 0);
        if (i % monthDivisor === 0) pivot = 0;
      }

      // // [DY]
      // if(args.state.expand){
      //   return (i % 12) * recW + pivot - marginRight * (i % divider)
      // }
      // else
      return (
        (i % monthDivisor) * recW + pivot - marginRight * (i % divider) + expandMargin
      );
    })
    .attr("y", (d, i) => marginalHeight - recH - Math.floor(i / monthDivisor) * recH)
    .attr("height", recH)
    .attr("width", (d, i) => {
      return recW - 2;
    })
    .on("click", (d, i) => {
      let targetIndex = (i % monthDivisor) + (Math.floor((args.indexLength-1)/monthDivisor) - Math.floor(i/monthDivisor))*monthDivisor;
      document.getElementById("th-grid-vertical").scrollTop =
        Math.floor(targetIndex / monthDivisor) * 35;
      d3.selectAll("#th-grid-" + args.state.inspectedIndex).style('border', 'none');
      d3.select("#th-grid-" + targetIndex).style('border', "2px solid #1073c9");
        // .classed("selected", true);
      args.setState({
        inspectedIndex: targetIndex
      })
    })
    .style("fill", (d, i) => {
      let invalidRangeFill = "#EFEFEF";
      if (!args.state.relativeHeatmapIntensity) {
        // MEAN PR OF TIMESTAMP
        let validRange = args.state.heatmapRanges[0];
        if (
          d[currentView] >= validRange.min &&
          d[currentView] <= validRange.max
        )
          return colorScale(d[currentView]);
        else return invalidRangeFill;
      } else {
        currentSeasonInRelativePr =
          currentSeasonInRelativePr + (i !== 0 && i % 3 === 0 ? 1 : 0);
        if (i % 12 === 0) currentSeasonInRelativePr = 0;
        // RELATIVE PR
        let historicalMeanThisSeason =
          args.HistoricalMean[currentView][currentSeasonInRelativePr];
        let diff = d[currentView] - historicalMeanThisSeason;
        let diffRatio = diff / historicalMeanThisSeason;

        // checking min max to update the slider
        minPrHeatMap = minPrHeatMap < diffRatio ? minPrHeatMap : diffRatio;
        maxPrHeatMap = maxPrHeatMap > diffRatio ? maxPrHeatMap : diffRatio;

        let diffPercentage = diffRatio;
        let validRange = args.state.heatmapRanges[1];
        if (
          diffPercentage >= validRange.min &&
          diffPercentage <= validRange.max
        )
          return colorScaleRelative(diffRatio);
        else return invalidRangeFill;
      }
    })
    .on("mouseover", function (d, index) {
      trackSeasonInMouseOver = Math.floor((index%12)/3);
      // trackSeasonInMouseOver + (index !== 0 && index % 3 === 0 ? 1 : 0);
      if (index % 12 === 0) trackSeasonInMouseOver = 0;

      let historicalMeanThisSeason =
        args.HistoricalMean[currentView][trackSeasonInMouseOver];
      let diff = d[currentView] - historicalMeanThisSeason;
      let diffPercentage = (diff / historicalMeanThisSeason) * 100;

      d3.select(this).style("stroke", "black").style("stroke-width", "2");
      tooltip.transition().duration(250).style("opacity", 0.9);

      tooltip
        .html(
          "<span>Regional Mean HeatMap</span></br><span> Year: " +
            (startYear + Math.floor(index / 12)) +
            "</span><br/>" +
            "<span> Mean PRCP: " +
            d[currentView].toFixed(2) +
            " kgm<sup>-2</sup>s<sup>-1</sup></span><br/>" +
            "<span> Historical Mean: " +
            historicalMeanThisSeason.toFixed(2) +
            "</span>" +
            "<span> Difference: " +
            diffPercentage.toFixed(2) +
            "%</span>"
        )
        .style("left", d3.event.pageX + 30 + "px")
        .style("top", d3.event.pageY - 20 + "px");
    })
    .on("mouseout", function (d) {
      d3.select(this).style("stroke", "none");
      tooltip.transition().duration(450).style("opacity", 0);
    });

  // Checking the min and max of PR vs historical ratio; supposed to update state only for the first time
  if (
    minPrHeatMap !== statePrHeatMapRange.min ||
    maxPrHeatMap !== statePrHeatMapRange.max
  ) {
    let heatmapRanges = args.state.heatmapRanges;
    heatmapRanges[1] = {
      min: minPrHeatMap,
      max: maxPrHeatMap,
    };

    args.setState({ heatmapRanges: heatmapRanges }, () => {
      args.removeHeatMap();
      drawHeatMap(args);
    });
  }

  let xScale = d3.scaleLinear().range([0, lineChartWidth]).domain([0, 7]);

  let xScaleT = d3.scaleLinear().range([0, lineChartWidth]).domain([-8, 42]);

  let yScale = d3
    .scaleLinear()
    .range([marginalHeight, 0])
    .domain([args.startYear, args.endYear-1]);

  svg
    .append("g")
    .attr("class", "y axis")
    .attr("transform", "translate(-10,0)")
    .call(
      d3.axisLeft(yScale).ticks(2).tickValues([args.startYear, args.endYear-1]).tickFormat(d3.format("d"))
    );

  let line = d3
    .line()
    .x(function (d) {
      return xScale(d.value);
    }) // set the x values for the line generator
    .y(function (d, index) {
      return yScale(d.year);
    })
    .curve(d3.curveMonotoneY); // set the y values for the line generator

  let lineT = d3
    .line()
    .x(function (d) {
      return xScaleT(d.value);
    }) // set the x values for the line generator
    .y(function (d, index) {
      return yScale(d.year);
    })
    .curve(d3.curveMonotoneY);

  let drawLine = (data, id, transX, lineF, stroke = "black") => {
    svg
      .append("path")
      .datum(data)
      .attr("class", "line line" + id)
      .attr("transform", "translate(" + transX + ", " + 0 + ")")
      // .attr("transform", "translate(" + (xScale.bandwidth()/2) + ", " + 0 + ")")
      .style("stroke", stroke)
      .style("stroke-width", 2)
      .style("opacity", 0.8)
      .attr("d", lineF);
  };

  // gridlines in x axis function
  function make_x_gridlines() {
    return d3.axisBottom(xScale).ticks(5);
  }

  // gridlines in y axis function
  function make_y_gridlines() {
    return d3.axisLeft(yScale).ticks(5);
  }

  if (args.state.expand) {
    // DUMMY DATA ::
    _.forEach(_.range(0, 4), (s, index) => {
      let transX = (recW - 2) * 3 * (s + 1) + 100 * s;

      // add the X gridlines
      svg
        .append("g")
        .attr("class", "grid th-grid")
        .attr("transform", "translate(" + transX + "," + marginalHeight + ")")
        .call(make_x_gridlines().tickSize(-marginalHeight).tickFormat(""));

      // add the Y gridlines
      svg
        .append("g")
        .attr("class", "grid th-grid")
        .attr("transform", "translate(" + transX + "," + 0 + ")")
        .call(make_y_gridlines().tickSize(-lineChartWidth).tickFormat(""));

      // season label
      svg
        .append("text")
        .attr("transform", "translate(" + (transX - recW * 1.8) + "," + 0 + ")")
        .attr("x", 0)
        .attr("y", -5)
        .attr("class", "color-range-label season-label")
        .text(_.capitalize(args.seasonList[index]));

      let groupedData = _.map(_.range(args.startYear, args.endYear), (y) => {
        return { year: y, value: args.PRSRM[y][s][currentView.toString()],  value_t: args.TASMAXSRM[y][s][currentView.toString()]  };
      });

      drawLine(groupedData, index, transX, line, args.seasonalMeanPrLineColor);

      // // draw hidden bars for mouseover and mouseleave event
      svg
        .selectAll(`.hidden-bar-${index}`)
        .data(groupedData)
        .enter()
        .append("rect")
        .attr("class", (d) => `overlay hidden-bar-${index}`)
        .attr("y", function (d, i) {
          // console.log(d)
          // return 0
          return yScale(d.year);
        })
        .attr("x", xScale(0))
        .attr("width", lineChartWidth)
        .attr(
          "height",
          ( yScale(args.startYear) - yScale(args.endYear)) / groupedData.length
        )
        .attr("fill", "red")
        .style("opacity", 0)
        .attr("transform", "translate(" + transX + ", " + 0 + ")")
        .on("mouseover", (d, i) => {
          d3.select(`.circle-precipitation-${index}-${d.year}`).attr("r", 3);
          d3.select(`.circle-temperature-${index}-${d.year}`).attr("r",3);
          // tooltip.style("display", null);
          tooltip.transition().duration(450).style("opacity", 0.8);
        })
        .on("mouseout", (d, i) => {
          d3.select(`.circle-precipitation-${index}-${d.year}`).attr("r",0);
          d3.select(`.circle-temperature-${index}-${d.year}`).attr("r",0);
          // tooltip.style("display", "none");
          tooltip.transition().duration(450).style("opacity", 0);
        })
        .on("mousemove", (d, i) => {
          // // [DY] I have unknown issue why +5 - 100 is required ;
          let t_year = d.year;
          let t_posY =d3.select(`.circle-precipitation-${index}-${d.year}`).node().getBoundingClientRect().top - 50 -5;

          d3.select(`.circle-precipitation-${index}-${d.year}`).attr("r",3);
          d3.select(`.circle-temperature-${index}-${d.year}`).attr("r",3);
          
          let t_poxX = d3.event.pageX + xScale(d.value);

          tooltip.style("opacity", 0.8);

          tooltip
            .html(
              "<span><b>Seasonal Regional Mean</b></span><br/>" +
                "<span> Year: " +
                t_year +
                "</span><br/>" +
                "<span> Precipitation: " +
                d.value.toFixed(2) +
                // " (kgm<sup>-2</sup>s<sup>-1</sup>)</span><br/>" +
                " (mm/day)</span><br/>" +
                "<span> Max Temp: " +
                d.value_t.toFixed(2) +
                " (<sup>o</sup>C)</span><br/>"
            )
            .style("left", (t_poxX) + "px")
            .style("top", t_posY + "px");
        });

      // hiddenCircle for precipitation
      svg
        .selectAll(`circle-precipitation-${index}`)
        .data(groupedData)
        .enter()
        .append("circle") // Uses the enter().append() method
        .attr("class", (d) => `circle-precipitation-${index}-${d.year}`) // Assign a class for styling
        .attr("cy", function (d, i) {
          // return xScale(d.pr);
          // if (d.year) return yScale(d.year);
          return yScale(d.year);
        })
        .attr("cx", function (d) {
          return xScale(d.value);
        })
        .attr("r", 0)
        .style("fill", "blue")
        .style("opacity", 0.5)
        .attr("transform", "translate(" + transX + ", " + 0 + ")");

      svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(" + transX + ", 0)")
        .call(d3.axisTop(xScaleT).ticks([4]).tickSize(2));

      groupedData = _.map(_.range(args.startYear, args.endYear), (y) => {
        return { year: y, value: args.TASMAXSRM[y][s][currentView.toString()] };
      });

      drawLine(
        groupedData,
        index + 10,
        transX,
        lineT,
        args.seasonalMeanTasmaxLineColor
      );

      svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(" + transX + ", " + marginalHeight + ")")
        .call(d3.axisBottom(xScale).ticks([5]).tickSize(2));

      
      // hiddenCircle for Temperature
      svg
        .selectAll(`circle-temperature-${index}`)
        .data(groupedData)
        .enter()
        .append("circle") // Uses the enter().append() method
        .attr("class", (d) => `circle-temperature-${index}-${d.year}`) // Assign a class for styling
        .attr("cy", function (d, i) {
          // return xScale(d.pr);
          // if (d.year) return yScale(d.year);
          return yScale(d.year);
        })
        .attr("cx", function (d) {
          return xScaleT(d.value);
        })
        .attr("r", 0)
        .style("fill", "red")
        .style("opacity", 0.5)
        .attr("transform", "translate(" + transX + ", " + 0 + ")");
    });
  } else {
    _.forEach(_.range(0, 4), (s, index) => {
      let transX = recW * 3 * (s + 1);
      // season label
      svg
        .append("text")
        .attr("transform", "translate(" + (transX - recW * 1.8) + "," + 0 + ")")
        .attr("x", 0)
        .attr("y", -5)
        .attr("class", "color-range-label season-label")
        .text(_.capitalize(args.seasonList[index]));
    });
  }
};
export default drawHeatMap;
