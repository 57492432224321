import React, {useEffect} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import _ from "lodash";
import mainRoutes from "./manage/routes/main";
import AlertController from "./utils/AlertController";
import PageNotFound from "./components/views/404";

export default function Routes(props) {
  const routeChangeComponentMount = props.onRouteChangeComponentMount;
  const location = useLocation();

  useEffect(() => {
    let locationKey = location.pathname.replace(/[/]/g, "");
    let pageLabel = "-";
    // console.log(mainRoutes[locationKey].renderProps);
    try {pageLabel = mainRoutes[locationKey].renderProps.config.pageLabel;}
    catch (e) { console.log("page label not found for '" + locationKey + "'"); }
    routeChangeComponentMount(pageLabel);
  }, [location, routeChangeComponentMount]);

  let windowHeight = window.innerHeight;
  let windowWidth = window.innerWidth;
  const defaultRouteRenderProps = {
    routeChangeComponentMount: routeChangeComponentMount,
    alertController: new AlertController({setAlert: props.setAlert}),
    // canvasHeight: windowHeight - windowHeight * 0.15 - 50,
    canvasHeight: windowHeight,
    // canvasWidth: windowWidth - windowWidth * 0.2
    canvasWidth: windowWidth // - 30 - 50 // for side nav and default margin
  };

  const routes = _.map(mainRoutes, (item, key) => {
    if (item.path && item.component) {
      // sending default props with the render props of every component
      item.renderProps = _.merge(
        item.renderProps || {},
        defaultRouteRenderProps
      );

      return (
        <Route
          exact
          path={item.path}
          key={key}
          render={() => (
            <item.component
              {...item.renderProps}
            />
          )}
        />
      );
    }
  });

  return (
    <main>
      <Switch key={"app-switch"}>
        {routes}

        {/* 404 */}
        <Route key={"app-route"} component={PageNotFound} />
      </Switch>
    </main>
  );
}