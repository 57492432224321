import React from "react";


export default {
  experiment: ['historical', 'rcp26', 'rcp45', 'rcp60', 'rcp85'],
  variable: ['pr', 'tasmax', 'tasmin'],
  model: ['access1-0', 'bcc-csm1-1', 'bcc-csm1-1-m', 'bnu-esm', 'canesm2',
    'ccsm4', 'cesm1-bgc', 'cesm1-cam5', 'cmcc-cm', 'cnrm-cm5',
    'csiro-mk3-6-0', 'ens-avg', 'fgoals-g2', 'fio-esm', 'gfdl-cm3',
    'gfdl-esm2g', 'gfdl-esm2m', 'giss-e2-h-cc', 'giss-e2-r',
    'giss-e2-r-cc', 'hadgem2-ao', 'hadgem2-cc', 'hadgem2-es', 'inmcm4',
    'ipsl-cm5a-lr', 'ipsl-cm5a-mr', 'ipsl-cm5b-lr', 'miroc-esm',
    'miroc-esm-chem', 'miroc5', 'mpi-esm-lr', 'mpi-esm-mr',
    'mri-cgcm3', 'noresm1-m'],
  year: {min: 1950, max: 2099},
  month: {min:1, max:12}
}
