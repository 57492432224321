import _, {fromPairs} from "lodash";
import React from "react";
import DataServerConfig from "../../manage/requestProcessor/dataServer";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import {Container, Row, Col} from "react-bootstrap";
import {UserIcon_md_white, UserIcon_md_blue} from "../../utils/IconContainer";
import ReactDOMServer from "react-dom/server";
import CookieManager from "../../utils/CookieManager";
import ManageUserData from "../../requestProcessors/manageUserData";

const d3 = require("d3");

let tempDataRepo = {
  west: [],
  northwest: [],
  southwest: [],
  midwest: [],
  southeast: [],
  northeast: [],
  "m-atlantic": [],
};

const renderThumbnails = (args) => {
  let currentIndex = args.endYearIndex; // year index
  let dataIndex = -12;
  let year = args.endYear; // axis is reversed

  return _.map(_.chunk(args.indexes, args.rowSize), (yearIndex) => {
  // return _.map(_.chunk(args.indexes, args.rowSize), (yearIndex) => {
    // console.log(yearIndex);
    yearIndex = _.reverse(yearIndex);
    // console.log(yearIndex);
    dataIndex = dataIndex + 12;
    let seasonalSummary = {chart: "seasonalSummary"};
    if (args.state.expand) {
      yearIndex.splice(3, 0, seasonalSummary);
      yearIndex.splice(7, 0, seasonalSummary);
      yearIndex.splice(11, 0, seasonalSummary);
      yearIndex.splice(15, 0, seasonalSummary);
    }

    return (
      <div className={"g-row slideshow"}>
        {renderYearThumbnails(
          _.concat(yearIndex, [{chart: "bar"}]),
          currentIndex--,
          dataIndex,
          year--,
          args
        )}
      </div>
    );
  });
};

const renderYearThumbnails = (
  yearIndex,
  currentIndex,
  monthIndex,
  currentYear,
  args
) => {
  // let yearData = _.values(args.rawHeatData[currentYear]);
  let yearData = _.concat(
    _.slice(_.values(args.rawHeatData[currentYear]), 2),
    _.slice(_.values(args.rawHeatData[currentYear+1]), 0, 2)
    );
  let monthInYearIndex = 0;
  let seasonIndex = 0;
  let image_url =
    DataServerConfig.Server +
    DataServerConfig.ServerAPI.FetchNEXDCP30Thumbnail.path;
  let annotationOverview = args.state.annotationOverview;
  let annotationCircleColor = "white";
  let cookieManager = new CookieManager();
  let userDataManager = new ManageUserData();

  // // console.log(args.state.annotationOverview)

  // to fit the width and height to upper heatmap
  // [DY] 2021.02.24
  // [RN] the thumbnail width and height are intended to be fixed (to maintain regional position)
  // let seasonalGap = args.state.barChartWidth;
  // let heatmapWidth = args.heatmapRef.clientWidth - 40 - seasonalGap * 4.5;
  let recW = args.thWidth;

  if (args.state.heatmapCellWidth == null) {
    args.setState({heatmapCellWidth: recW});
  }

  return _.map(yearIndex, (index, i) => {
    if (index.chart === "bar") {
      // shows a summary for the entire year
      return (
        <div className={"g-col slide summary"}>
          <HorizontalBarChart
            data={_.map(args.BarData[currentIndex], (v, k, d) =>
              k !== "year" ? {Exp: d.year, label: k, value: v} : undefined
            ).filter((e) => e && e.label && e.value)}
            info={{year: currentYear - 1, regionList: args.regionList}}
            height={30}
            width={args.state.barChartWidth}
            removeXAxis={true}
            removeYAxis={true}
            padding={0.002}
            margin={{top: 0, right: 10, bottom: 0, left: 5}}
            xDomain={[0, 5]}
            colorScale={args.regionalColorScale}
          />
        </div>
      );
    } else if (index.chart === "seasonalSummary") {
      let data = [];
      let currentMonth = monthInYearIndex;
      // console.log(currentYear-1, currentMonth);
      let seasonData = _.slice(yearData, currentMonth - 3, currentMonth);
      _.forEach(_.range(0, 7), (r) => {
        let sdr = _.map(seasonData, (sd) => sd[r]);
        data.push({
          Exp: r + monthIndex + currentYear, // refactor later to season name + year
          label: r,
          value: _.mean(sdr),
        });
      });
      return (
        <div className={"g-col slide summary"}>
          <HorizontalBarChart
            data={data}
            info={{
              season: args.seasonList[seasonIndex++],
              year: currentYear - 1,
              regionList: args.regionList,
            }}
            height={30}
            width={args.state.barChartWidth}
            removeXAxis={true}
            removeYAxis={true}
            padding={0.002}
            margin={{top: 0, right: 10, bottom: 0, left: 5}}
            xDomain={[0, 5]}
            colorScale={args.regionalColorScale}
            focusBar={args.state.currentView}
          />
        </div>
      );
    } else {
      monthInYearIndex++;

      let abs_url =
        image_url +
        "?file_name=" +
        index.filePrefix +
        "&index_name=" +
        index.filename;

      let divider = args.state.expand ? 4 : 3;
      annotationCircleColor = "white";

      if (annotationOverview != null && annotationOverview.length > 0) {
        for (let item of annotationOverview) {
          let name = item.name;
          let isInclude = index.filename.includes(name);

          if (isInclude) {
            annotationCircleColor = "red";
            break;
          }
        }
      }

      let curRegion = args.regionList[args.state.currentView];

      if (
        tempDataRepo[curRegion][index.date] != undefined &&
        tempDataRepo[curRegion][index.date].length > 0
      ) {
        annotationCircleColor = "red";
      }

      let thRender = (
        <>
          <svg
            className={"slide-background"}
            id={"th-grid-" + monthIndex++}
            style={{
              backgroundImage: "url(" + abs_url + ")",
              backgroundSize:
              args.regionMap[args.state.currentView].backgroundSize,
              backgroundPosition:
              args.regionMap[args.state.currentView].backgroundPosition,
              height: args.thHeight,
              width: recW - 2, // args.thWidth
              marginRight: (i + 1) % divider != 0 ? 0 : "6px",
              border: abs_url.includes(
                args.state.selectedIndex.filename.split(".")[0]
              )
                ? "2px solid red"
                : null,
            }}
            onContextMenu={async (e) => {
              e.preventDefault();
              // let userDataManager = new ManageUserData();

              let thumbnail_annotation_tooltip = document.getElementById(
                "thumbnail-annotation-tooltip"
              );

              if (thumbnail_annotation_tooltip != null) return;

              let target = e.target;

              target.style.border = "2px solid black";
              args.thTooltip.transition().duration(200).style("opacity", 1);
              // d3.selectAll(".thumbnail-annotation-circle").style("border", null);

              let tooltipPosX = e.clientX <= 200 ? 210 : e.clientX;
              let tooltipPosY = e.clientY <= 275 ? 280 : e.clientY;


              // datasource: ex.CESM1-CAM5/pr_amon_BCSD_rcp85_r1i1p1_CONUS_CESM1-CAM5_208601-209012/NEX-DCP30_20860131_3.geojson
              // model + filename + sourcename
              let comments = [];
              let geoJSONTableID = -1;
              let dataSource = index.filePrefix + index.filename;
              // "CESM1-CAM5/pr_amon_BCSD_rcp85_r1i1p1_CONUS_CESM1-CAM5_208601-209012/NEX-DCP30_20860131_3.geojson";

              // console.log(dataSource)
              // console.log(index.filePrefix)
              // console.log(index.filename)
              // console.log(abs_url)
              await userDataManager
                .commentMarkersOnGeoJSON(dataSource)
                .then((res) => {
                  // console.log(res);

                  // check if only position is null if necessary
                  // let markers = res.markers.filter(marker=>marker.position == "[None, None]");

                  let markers = res.markers;

                  geoJSONTableID = res.geojson_table_id
                  comments = markers.map(item => item.comments).flat(2)

                });

              let tooltipContent = (color) => (
                <Container
                  className="annotation-panel"
                  id="thumbnail-annotation-tooltip"
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                    // border: "3px solid #007bff",
                    opacity: "0.95",
                    overflow: "hidden",
                    paddingRight: "0",
                  }}
                >
                  <Row style={{height: "100%"}}>
                    <Col
                      lg={6}
                      md={6}
                      style={{
                        position: "relative",
                        left: "-7%",
                        overflow: "hidden",
                        height: "100%",
                        marginRight: "0px",
                        marginLeft: "0px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <Row
                        id="thumbnail-annotation-tooltip-image"
                        style={{
                          height: "80%",
                          width: "100%",
                          position: "relative",
                          // left: "-30%",
                          top: "-30%",

                          // backgroundImage: `url(" + ${abs_url} + ")`,
                        }}
                      >
                        <img
                          src={abs_url}
                          style={{
                            width: "100%",
                            height: "180%",
                          }}
                        ></img>
                      </Row>
                      <Row
                        style={{
                          height: "20%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "left",
                        }}
                      >
                        <Col lg={12} md={12}>
                          <Row
                            style={{
                              height: "20%",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <span
                              className="btn btn-sm btn-secondary"
                              style={{marginLeft: "10px"}}
                            >
                              Date: {index.date}
                            </span>

                            <button
                              className={"btn btn-primary btn-sm popup-button"}
                              id="thumbnail-annotation-close-btn"
                              type={"button"}
                            >
                              Close
                            </button>
                            <button
                              className={"btn btn-primary btn-sm popup-button"}
                              id="thumbnail-annotation-submit-btn"
                              type={"button"}
                            >
                              Submit
                            </button>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      style={{
                        height: "100%",
                        position: "relative",
                        left: "-7%",
                        marginLeft: "0px",
                        paddingTop: "0.5rem",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <Row
                        style={{
                          height: "50%",
                          width: "110%",
                          overflow: "auto",
                        }}
                      >
                        <div
                          id="thumbnail-annotation-tooltip-annotation-container"
                          style={{
                            width: "110%",
                            display: "flex",
                            flexDirection: "column",
                            // overflow: "auto",
                          }}
                        >
                          {comments.length === 0 ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "2px dotted black",
                                borderRadius: "5px",
                                margin: "auto",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0 25%",
                                  fontSize: "2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                No Comment
                              </p>
                            </div>
                          ) : (
                            comments.map((item) => {
                              return (
                                <div className={"comment"}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className={"comment-time"}>
                                      {item.posted}
                                    </div>
                                  </div>
                                  <div className={"comment-text"}>
                                    {item.comment}
                                  </div>
                                </div>
                              );
                            })
                          )}
                          {/* {tempDataRepo[
                            args.regionList[args.state.currentView]
                          ][index.date] == undefined ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "2px dotted black",
                                borderRadius: "5px",
                                margin: "auto",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0 25%",
                                  fontSize: "2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                No Comment
                              </p>
                            </div>
                          ) : (
                            tempDataRepo[
                              args.regionList[args.state.currentView]
                            ][index.date].map((item) => {
                              return (
                                <div className={"comment"}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className={"comment-time"}>
                                      {item.dateTime}
                                    </div>
                                  </div>
                                  <div className={"comment-text"}>
                                    {item.text}
                                  </div>
                                </div>
                              );
                            })
                          )} */}
                        </div>
                      </Row>
                      <hr
                        style={{
                          borderTop: "2px double black",
                          marginTop: "0.3rem",
                          marginBottom: "0.5rem",
                        }}
                      ></hr>

                      <Row
                        style={{height: "30%", width: "100%"}}
                      >
                        <Col
                          lg={2}
                          md={2}
                          style={{
                            padding: "0 0 0 0",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "space-between",
                          }}
                        >
                          <Row
                            style={{
                              margin: "0 auto 3% auto",
                              padding: "0  0 auto",
                            }}
                          >
                            {color === "white" ? (
                              <UserIcon_md_white/>
                            ) : (
                              <UserIcon_md_blue/>
                            )}
                          </Row>
                          <Row>{cookieManager.getUsername()}</Row>
                        </Col>

                        <Col lg={10} md={10} style={{padding: "0 0 0 2%"}}>
                          <textarea
                            id="thumbnail-annotation-tooltip-text"
                            className={"form-control input-sm"}
                            style={{width: "110%", height: "100%"}}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              );

              args.thTooltip
                .html(
                  ReactDOMServer.renderToStaticMarkup(tooltipContent("blue"))
                )
                .style("width", "650px")
                .style("background-image", "url()")
                .style("pointer-events", "auto")
                .style("left", tooltipPosX - 200 + "px")
                .style("top", tooltipPosY - 275 + "px");

              document
                .getElementById("thumbnail-annotation-close-btn")
                .addEventListener("click", () => {
                  target.style.border = null;

                  args.thTooltip.html("");

                  args.thTooltip.transition().duration(500).style("opacity", 0);
                });

              document
                .getElementById("thumbnail-annotation-submit-btn")
                .addEventListener("click", () => {
                  let curRegion = args.regionList[args.state.currentView];
                  let today = new Date()
                    .toJSON()
                    .slice(0, 16)
                    .replace(/-/g, "/");

                  today = today.replace("T", " ");
                  let textValue = document.getElementById(
                    "thumbnail-annotation-tooltip-text"
                  ).value;

                  // if (tempDataRepo[curRegion][index.date] == undefined) {
                  //   tempDataRepo[curRegion][index.date] = [];
                  // }

                  let tempAnnotationObj = {
                    dateTime: today,
                    text: textValue,
                  };

                  if (comments.length === 0) {
                    // if (tempDataRepo[curRegion][index.date].length == 0) {
                    document.getElementById(
                      "thumbnail-annotation-tooltip-annotation-container"
                    ).innerHTML = "";
                  }

                  // tempDataRepo[curRegion][index.date].push(tempAnnotationObj);


                  userDataManager
                    .postUserComment(
                      cookieManager.getUsername(),
                      undefined,
                      textValue,
                      [null, null],
                      geoJSONTableID,
                      dataSource
                    )
                    .then((res) => {
                      console.log(res)
                    });

                  document.getElementById(
                    "thumbnail-annotation-tooltip-text"
                  ).value = "";

                  // update the annotation view
                  let temp = document.createElement("div");
                  temp.innerHTML = `  <div class="comment">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div class="comment-time">
                      ${today}
                    </div>
                  </div>
                  <div class="comment-text">
                    ${textValue}
                  </div>
                </div>`;

                  document
                    .getElementById(
                      "thumbnail-annotation-tooltip-annotation-container"
                    )
                    .appendChild(temp);

                  d3.select(`#thumbnail-grid-circle-${index.date}`).attr(
                    "fill",
                    "red"
                  );
                });
            }}
            onClick={() => {
              let thumbnail_annotation_tooltip = document.getElementById(
                "thumbnail-annotation-tooltip"
              );

              if (thumbnail_annotation_tooltip != null) {
              } else {
                args.setState({selectedIndex: index, showSpinner: true}, () =>
                  window.setTimeout(
                    () => args.setState({showSpinner: false}),
                    8000
                  )
                );
              }
            }}
            onMouseEnter={(event) => {
              let thumbnail_annotation_tooltip = document.getElementById(
                "thumbnail-annotation-tooltip"
              );

              if (thumbnail_annotation_tooltip != null) {
              } else {
                event.target.style.border = "2px solid black";
                args.thTooltip.transition().duration(200).style("opacity", 1);
                // d3.selectAll(".thumbnail-annotation-circle").style("border", null);

                let tooltipContent = //`<div class='preview-info' style="display: relative, top= ${event.clientX} , left=${event.clientY} ">` +
                  `<div class='preview-info' id='thumbnail-image-tooltip'>` +
                  "<span class='btn btn-sm btn-secondary'>Date: " +
                  index.date +
                  " </span>" +
                  "</div>";

                let tooltipPosX = event.clientX <= 200 ? 210 : event.clientX;
                let tooltipPosY = event.clientY <= 275 ? 280 : event.clientY;

                args.thTooltip
                  .html(tooltipContent)
                  .style("width", "400px")
                  .style("background-image", "url(" + abs_url + ")")
                  .style("pointer-events", "none")
                  .style("left", tooltipPosX - 200 + "px")
                  .style("top", tooltipPosY - 275 + "px")
                  .style("width", "460px !important");
              }
            }}
            onMouseLeave={(event) => {
              let tooltip = document.getElementById(
                "thumbnail-annotation-tooltip"
              );

              if (tooltip != null) return;

              tooltip = document.getElementById("thumbnail-image-tooltip");

              event.target.style.border = abs_url.includes(
                args.state.selectedIndex.filename.split(".")[0]
              )
                ? "2px solid red"
                : null;

              args.thTooltip.transition().duration(500).style("opacity", 0);
            }}
          >
            <circle
              id={`thumbnail-grid-circle-${index.date}`}
              cx={recW - 8}
              cy={args.thHeight - 8}
              r="3"
              stroke="black"
              stroke-width="1"
              fill={annotationCircleColor}
            />
          </svg>
        </>
      );

      return <div className={"g-col slide"}>{thRender}</div>;
    }
  });
};

export default renderThumbnails;
