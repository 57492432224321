import React from "react";
import _ from "lodash";
import "../../../stylesheets/charts/legends/colorRange.css";

const d3 = require("d3");

export default class ColorRangeLegend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: this.props.label,
      height: this.props.height,
      width: this.props.width,
      barHeight: this.props.barHeight || 10,
      barWidth: this.props.barWidth || 10,
      containerWidth: this.props.width - 20,
      containerHeight: this.props.height - 20,
      data: this.props.parseData
        ? this.parseGeoData(this.props.data)
        : this.props.data,
      marginLeft: 10,
      marginTop: 10,
      scale: this.props.scale,
    };
  }

  parseGeoData = (data) => {
    let labels = [];
    let labelColors = [];
    _.forEach(data.features, (item, index) => {
      if (labels.indexOf(item.properties.title) === -1) {
        labels.push(item.properties.title);
        labelColors.push(item.properties.fill);
      }
    });
    // console.log(data.features[0]);
    // console.log(JSON.stringify({labels: labels, colors: labelColors}));
    // console.log({labels: labels, colors: labelColors});
    return { labels: labels, colors: labelColors };
  };

  componentDidMount() {
    // this.appendLoading();
    this.drawColorRange();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.width !== this.props.width) {
      this.setState(
        { width: this.props.width, containerWidth: this.props.width - 20 },
        () => {
          this.removeColorRange();
          this.drawColorRange();
        }
      );
    }
    // TODO check if this hampering the update
    if (prevProps.data !== this.props.data) {
      this.appendLoading();
      this.setState(
        {
          data: this.props.parseData
            ? this.parseGeoData(this.props.data)
            : this.props.data,
        },
        () => {
          this.removeColorRange();
          this.drawColorRange();
        }
      );
    }
  }

  appendLoading = (translateX, translateY) => {
    this.removeColorRange();
    let svg = this.createSVG(0, 0);
    this.appendLabelToSVG(svg, "Loading...");
  };

  createSVG = (translateX = 0, translateY = 0) => {
    return d3
      .select(this.colorRangeRef)
      .append("svg")
      .attr("width", this.state.width + this.state.marginLeft)
      .attr("height", this.state.containerHeight + this.state.marginTop)
      .attr("transform", "translate(" + translateX + ", " + translateY + ")");
  };

  appendLabelToSVG = (svg, label) => {
    svg
      .append("text")
      .attr("x", 10)
      .attr("y", this.state.Top)
      .attr("class", "color-range-label")
      .style("font-size", "11px")
      .style("font-weight", "bold")
      .attr(
        "transform",
        `translate(${
          this.state.barWidth + this.state.marginLeft + 20
        },${0}) rotate(90)`
      )
      .text(label);
  };

  drawColorRange() {
    let scale = this.state.scale;
    let labels = this.state.data.labels;
    let labelColors = this.state.data.colors;
    // console.log(labelColors);
    let labelLength = labels.length;
    let containerHeight = this.state.containerHeight;
    let containerWidth = this.state.containerWidth;

    let itemHeight = containerHeight / labelLength - 1;
    let itemWidth = containerWidth / labelLength - 1;

    let marginLeft = this.state.marginLeft;
    let marginTop = this.state.marginTop;
    let data = this.state.data;

    let yScale = d3
      .scaleLinear()
      .domain(scale)
      .range([containerHeight - labelLength, 0]);

    let svg = this.createSVG();
    this.appendLabelToSVG(svg, this.state.label);

    // console.log(labelColors);

    // console.log(
    //   (this.colorScaleRelative = d3
    //     .scaleDiverging((t) => d3.interpolateRdYlBu(1 - t))
    //     // .scaleLinear()
    //     // .range(["#b40128", "#f9f9d9", "#065e9e"])
    //     .domain([-1, 0, 1]))
    // );

    var defs = svg.append("defs");

    //Append a linearGradient element to the defs and give it a unique id
    var linearGradient = defs
      .append("linearGradient")
      .attr("id", "linear-gradient");

    //Vertical gradient
    linearGradient
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    //Set the color for the start (0%)
    linearGradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", labelColors[labelColors.length - 1]); //light blue

    linearGradient
      .append("stop")
      .attr("offset", "60%")
      .attr("stop-color", labelColors[Math.ceil(labelColors.length/2)-1]); //light blue

    //Set the color for the end (100%)
    linearGradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", labelColors[0]); //dark blue

    svg
      .append("g")
      .attr("class", "color-range-rects")
      .append("rect")
      .attr("x", 14)
      .attr("y", marginTop)
      .attr("width", this.state.barWidth)
      .attr("height", itemHeight * data.labels.length)
      .style("fill", "url(#linear-gradient)")
      .attr("transform", `translate(${this.state.marginLeft},${0})`);

    // svg
    //   .append("g")
    //   .attr("class", "color-range-rects")
    //   .selectAll(".rects")
    //   .data(d3.range(0, data.labels.length, 1))
    //   .enter()
    //   .append("rect")
    //   .attr("x", 14)
    //   .attr("height", itemHeight)
    //   .attr("y", (d, i) => marginTop + (labelLength-1-i) * itemHeight)
    //   .attr("width", this.state.barWidth)
    //   .attr("fill", (d, i) => labelColors[i])
    //   .attr("stroke", "#9d9d9d")
    //   .attr("stroke-width", "0.5px")
    //   .attr("transform",`translate(${ this.state.marginLeft },${0})`);

    svg
      .append("g")
      .attr("class", "color-range")
      .attr(
        "transform",
        "translate(" +
          (this.state.marginLeft + this.state.barWidth) +
          ", " +
          marginTop +
          ")"
      )
      .call(d3.axisLeft(yScale)
        .ticks([6])
        .tickSize(2)
      ); // Create an axis component with d3.axisBottom
  }

  removeColorRange() {
    d3.select(this.colorRangeRef).select("svg").remove();
  }

  render() {
    return <div ref={(ref) => (this.colorRangeRef = ref)}></div>;
  }
}
