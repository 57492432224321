import _ from "lodash";
import React from "react";
import sampleData from "../../data/sampleAreaData";
import ChartWrapper from "../wrapper/ChartWrapper";
const d3 = require("d3");


export default class AreaChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartWrapper = new ChartWrapper(this, "area-chart", this.props);
    this.margin = _.cloneDeep(this.chartWrapper.margin);
    this.state = {
      height: this.props.height,
      width: this.props.width,
      data: this.props.data || sampleData
    }
  }

  componentDidMount() {
    let fetchedData = [];
    _.forEach(sampleData, (datum) => {
      datum.date = d3.timeParse("%Y-%m-%d")(datum.date);
      fetchedData.push(datum);
    });
    this.setState({data: fetchedData});
    // this.createChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.removeChart();
    this.createChart();
  }

  createChart() {
    let margin = this.margin,
      width = this.state.width - margin.left - margin.right,
      height = this.state.height - margin.top - margin.bottom;

    let data = this.state.data;

    let svg = d3.select(this.graph)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    let x = d3.scaleTime()
      .domain(d3.extent(data, function(d) { return d.date; }))
      .range([ 0, width ]);
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Add Y axis
    let y = d3.scaleLinear()
      .domain([0, d3.max(data, function(d) { return +d.value; })])
      .range([ height, 0 ]);
    svg.append("g")
      .call(d3.axisLeft(y));

    // Add the area
    svg.append("path")
      .datum(data)
      .attr("fill", "#d5eeff")
      .attr("stroke", "#1e88e5")
      .attr("stroke-width", 1.5)
      .attr("d", d3.area()
        .x(function(d) { return x(d.date) })
        .y0(y(0))
        .y1(function(d) { return y(d.value) })
      )
  }

  removeChart() {
    d3.select(this.graph).select("svg").remove();
  }

  render() {
    return(
      <div className={"chart area-chart"} ref={ref=>this.graph=ref}> </div>
    );
  }
}
