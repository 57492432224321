import _ from "lodash";
import React from "react";
import "../../stylesheets/charts/DoughnutChart.css";


const d3 = require("d3");
class DoughnutChart extends React.Component {
  constructor(props) {
    super(props);
    this.default = {
      height: 320,
      width: 350,
      margin: 20,
      data: {a: 9, b: 20, c:30, d:8},
      inSignificantGroupKey: "Others",
      inSignificantThresholdInPercentage: 5
    };

    this.state = {
      rawData: this.props.data || this.default.data,
      data: this.props.data || this.default.data,
      height: this.props.height || this.default.height,
      width: this.props.width || this.default.width,
      inPercentage: this.props.inPercentage || false,
      groupInSignificant: true,
      fillPercentageHeight: this.props.fillPercentageHeight || 0.5
    };
  }

  componentDidMount() {
    this.createDonutChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // this.removeDonutChart();
    // this.createDonutChart();
  }

  reDrawDonutChart() {
    this.removeDonutChart();
    this.createDonutChart();
  }

  convertDataInPercentage = (data) => {
    let convertedData = {};
    let sumValues = _.sum(_.values(data));
    let inSignificantValuesSum = 0;
    let inSignificantDataDetails = {};
    _.forEach(data, (value, key) => {
      let calculatedPercentage = value/sumValues * 100;
      if (this.state.groupInSignificant && calculatedPercentage < this.default.inSignificantThresholdInPercentage) {
        inSignificantValuesSum = inSignificantValuesSum + calculatedPercentage;
        inSignificantDataDetails[key] = calculatedPercentage;
      }
      else {
        convertedData[key] = calculatedPercentage;
      }
    });
    if (this.state.groupInSignificant && inSignificantValuesSum > 0)
      convertedData[this.default.inSignificantGroupKey] = inSignificantValuesSum;

    return convertedData;
  };

  updateDataInState = (data) => {
    let rawData = _.clone(data);
    if (this.state.inPercentage)
      data = this.convertDataInPercentage(data);
    this.setState({
      rawData: rawData,
      data: data
    });
    this.reDrawDonutChart();
  };

  removeDonutChart() {
    d3.select(this.graph).select("svg").remove();
  }

  createDonutChart() {
    // set the dimensions and margins of the graph

    let clientHeight = this.state.height;
    let clientWidth = this.state.width;

    // console.log(clientHeight)
    // console.log(clientWidth)

    let width = clientWidth,
      height = clientHeight,
      // let width = this.state.width,
      // height = this.state.height,
      margin = this.default.margin;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    let radius = Math.min(width, height) / 2 - margin;

    // append the svg object to the div called 'my_dataviz'
    let svg = d3.select(this.graph)
      .append("svg")
      .attr(
        "viewBox",
        "0 0 " + (clientWidth) + " " + clientHeight
      )
      // .attr("width", "100%")
      // .attr("height", "100%")
      .append("g")
      .attr("transform", "translate(" + (width +margin)/ 2 + "," + (height + margin) / 2 + ")");

    let data = this.state.data,
      rawData = this.state.rawData;

    // set the color scale
    let color = d3.scaleOrdinal()
      .domain(Object.keys(data))
      .range(d3.schemeDark2);

    // Compute the position of each group on the pie:
    let pie = d3.pie()
      .sort(null) // Do not sort group by size
      // .startAngle(1.1*Math.PI)
      // .endAngle(3.1*Math.PI)
      .value(function(d) {return d.value; });
    let data_ready = pie(d3.entries(data));

    // The arc generator
    let arc = d3.arc()
      .innerRadius(radius * 0.5)         // This is the size of the donut hole
      .outerRadius(radius * 0.8);

    // Another arc that won't be drawn. Just for labels positioning
    let outerArc = d3.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    // Create Tooltips
    // Define the div for the tooltip
    let div = d3.select("body").append("div")
      .attr("class", "tooltip").style("left","100px").style("top", "-100px");

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr("class", "arcs")
      .on("mouseover", function (d) {
        let tooltipContent = "<div>" +
          "<div><b>"+d.data.key+"</b></div>" +
          "<div>Vote: "+ rawData[d.data.key]+"</div>" +
          "<div>Percentage: "+ parseFloat(d.data.value).toFixed(2) +"%</div>" +
          "</div>";
        div.transition()
          .duration(200)
          .style("opacity", .9);

        div.html(tooltipContent)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      })
      .transition().delay(function(d, i) {
      return i * 500;
    })
      .duration(500)
      .attrTween('d', function(d) {
        let i = d3.interpolate(d.startAngle+0.1, d.endAngle);
        return function(t) {
          d.endAngle = i(t);
          return arc(d)
        }
      })
      .attr('d', arc)
      .attr('fill', function(d){ return(color(d.data.key)) });

    // Add the polylines between chart and labels:
    svg
      .selectAll('allPolylines')
      .data(data_ready)
      .enter()
      .append('polyline')
      .attr("class", "d-line")
      .attr('points', function(d) {
        let posA = arc.centroid(d); // line insertion in the slice
        let posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        let posC = outerArc.centroid(d); // Label position = almost the same as posB
        let midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 0.95 * (midAngle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC]
      });

    // Add the polylines between chart and labels:
    svg
      .selectAll('allLabels')
      .data(data_ready)
      .enter()
      .append('text')
      .text( function(d) { return d.data.key } )
      .attr('transform', function(d) {
        let pos = outerArc.centroid(d);
        let midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.99 * (midAngle < Math.PI ? 1 : -1);
        return 'translate(' + pos + ')';
      })
      .style('text-anchor', function(d) {
        let midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return (midAngle < Math.PI ? 'start' : 'end')
      });

    // Add the chart title
    svg.append("text")
      .attr("class", "chart-title")
      .text("Feature");
  }

  render() {
    return (
      <div className={"donut-chart"} ref={ref => this.graph = ref}>
      </div>
    );
  }
}

export default DoughnutChart;
