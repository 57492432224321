import _ from "lodash";
import React from "react";
import DOM from "@observablehq/stdlib/src/dom/index";
import ChartWrapper from "../wrapper/ChartWrapper";
// import SampleData from "../../data/sampleTreeMapData";
import SampleData from "../../data/regional-seasonal-treemap";
import "../../stylesheets/charts/hierarchical_treemap.css";
import SummaryChartNavigation from "../navigation/SummaryChartNavigation";

const d3 = require("d3");

const TreemapOrderEnum = {
  YEAR: "year",
  TEMPERATURE: "temperature",
  PRECIPITATION: "precipitation",
};

class TreeMap extends React.Component {
  constructor(props) {
    super(props);

    this.default = {
      data: SampleData,
    };
    let data = null;

    if (this.props.doDrawRegionFirst) {
      data = this.transformData4Treemap_regional_seasonal(
        this.props.regionalSeasonalData
      );
    } else {
      // draw season first
      data = this.transformData4Treemap_seasonal_regional(this.props.data);
    }

    // let data =
    
    this.state = {
      data: data, //this.transformData4Treemap_seasonal_regional(this.props.data) || SampleData,
      // data: this.transformData4Treemap_regional_seasonal(this.props.data) || SampleData,
      doDrawRegionFirst: this.props.doDrawRegionFirst,
      width: this.props.width,
      height: this.props.height,
      colorScale: this.props.colorScale,
      orderOption: TreemapOrderEnum.YEAR,
      seasonalLevel: this.props.seasonalLevel || null,
      regionalLevel: this.props.regionalLevel || null,
      doReordering: this.props.doReordering || false,
      isExpand: this.props.isExpand || false,
      setSeasonFunction_parent: this.props.setSeasonFunction_parent || null,
      showPopover:false
    };

    this.chartWrapper = new ChartWrapper(this, "treeMap", this.props);
    this.margin = this.props.margin || _.cloneDeep(this.chartWrapper.margin);
  }

  transformData4Treemap_seasonal_regional(data) {
    let treeData = [];

    let regionItems = [];
    for (let region of Object.keys(data)) {
      // console.log(region);
      let seasons = Object.keys(data[region]);
      let seasonItems = [];
      for (let season of seasons) {
        let years = Object.keys(data[region][season]);

        let yearItems = [];
        for (let year of years) {
          let temp = {
            name: data[region][season][year]["year"],
            value: {
              pr: data[region][season][year]["pr"],
              tasmax: data[region][season][year]["tasmax"],
            },
          };
          yearItems.push(temp);
        }

        let seasonItem = { name: season, children: yearItems };
        seasonItems.push(seasonItem);
      }
      let regionItem = { name: region, children: seasonItems };
      regionItems.push(regionItem);
    }

    // console.log(regionItems);

    return { name: "root", children: regionItems };
  }

  transformData4Treemap_regional_seasonal(data) {
    let treeData = [];

    let regionItems = [];
    // console.log(data);

    for (let region of Object.keys(data)) {
      let seasons = Object.keys(data[region]);
      let seasonItems = [];

      for (let season of seasons) {
        let years = Object.keys(data[region][season]);

        let yearItems = [];
        for (let year of years) {
          let temp = {
            name: data[region][season][year]["year"],
            value: {
              pr: data[region][season][year]["pr"],
              tasmax: data[region][season][year]["tasmax"],
            },
          };
          yearItems.push(temp);
        }

        let seasonItem = { name: season, children: yearItems };
        seasonItems.push(seasonItem);
      }
      let regionItem = { name: region, children: seasonItems };
      regionItems.push(regionItem);
    }

    return { name: "root", children: regionItems };
  }

  componentDidMount() {
    // this.createTreeMap();
    this.createTreeMap_dy();
  }

  removeChart() {
    d3.select(this.treeMapRef).html("");
  }

  componentDidUpdate(prevProp, prevState) {
    if (
      prevState.orderOption !== this.state.orderOption ||
      this.state.doReordering
      // prevState.seasonalLevel != this.state.selectedSeason ||
      // prevState.regionalLevel != this.state.selectedRegion
    ) {
      this.removeChart();
      this.createTreeMap_dy();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      seasonalLevel: nextProps.selectedSeason,
      regionalLevel: nextProps.selectedRegion,
      doReordering: nextProps.doReordering,
    });
  }

  removeTreeMap() {
    d3.select(this.treeMapRef).select("svg").remove();
  }

  generateData() {
    var firstLevel = d3.range(10, 17).map(function (d, i) {
      return { name: Number(d * d).toString(), size: d * d, index: i };
    });

    var result = {
      name: "squared",
      index: 0,
      children: firstLevel,
    };

    result.children.forEach(function (d) {
      d.children = Number(d.size)
        .toString()
        .split("")
        .filter(function (s) {
          return +s;
        })
        .map(function (s, i) {
          return { name: d.name + "." + s, size: +s, index: i };
        });

      d.children.forEach(function (sub_d) {
        sub_d.children = [...Array(1000).keys()].map(function (s, i) {
          return { name: sub_d + "-" + s, size: +s, index: i };
        });
      });
    });

    return result;
  }

  treemapSpiral(parent, x0, y0, x1, y1) {
    var EAST = 0;
    var SOUTH = 1;
    var WEST = 2;
    var NORTH = 3;
    var direction = EAST;

    var nodes = parent.children;
    var node;

    var n = nodes.length;
    var i = -1;
    var newX0 = x0;
    var newX1 = x1;
    var newY0 = y0;
    var newY1 = y1;

    var availWidth = x1 - x0;
    var availHeight = y1 - y0;

    var avgAspectRatio = 0;
    var nodeAspectRatio = 0;

    var segment = [];
    var segmentSum = 0;
    var nodesSum = 0;

    for (i = n; i--; ) nodesSum += nodes[i].value;

    i = -1;

    while (++i < n) {
      node = nodes[i];

      segment.push(node);

      segmentSum += node.value;

      if (direction === EAST) {
        // Update positions for each node.
        segment.forEach(function (d, i, arr) {
          d.x0 = i ? arr[i - 1].x1 : newX0;
          d.x1 = d.x0 + (d.value / segmentSum) * availWidth;
          d.y0 = newY0;
          d.y1 = newY0 + (segmentSum / nodesSum) * availHeight;
        });
      } else if (direction === SOUTH) {
        segment.forEach(function (d, i, arr) {
          d.x0 = newX1 - (segmentSum / nodesSum) * availWidth;
          d.x1 = newX1;
          d.y0 = i ? arr[i - 1].y1 : newY0;
          d.y1 = d.y0 + (d.value / segmentSum) * availHeight;
        });
      } else if (direction === WEST) {
        segment.forEach(function (d, i, arr) {
          d.x1 = i ? arr[i - 1].x0 : newX1;
          d.x0 = d.x1 - (d.value / segmentSum) * availWidth;
          d.y0 = newY1 - (segmentSum / nodesSum) * availHeight;
          d.y1 = newY1;
        });
      } else if (direction === NORTH) {
        segment.forEach(function (d, i, arr) {
          d.x1 = newX0 + (segmentSum / nodesSum) * availWidth;
          d.x0 = newX0;
          d.y1 = i ? arr[i - 1].y0 : newY1;
          d.y0 = d.y1 - (d.value / segmentSum) * availHeight;
        });
      }

      // Compute new aspect ratio.
      nodeAspectRatio =
        direction & 1
          ? (node.y1 - node.y0) / (node.x1 - node.x0)
          : (node.x1 - node.x0) / (node.y1 - node.y0);

      avgAspectRatio = d3.sum(segment, function (d) {
        return direction & 1
          ? (d.y1 - d.y0) / (d.x1 - d.x0)
          : (d.x1 - d.x0) / (d.y1 - d.y0);
      });

      if (segment.length > 1 || direction != EAST) {
        if (direction === EAST) {
          newY0 = node.y1;
          availHeight = newY1 - newY0;
        } else if (direction === SOUTH) {
          newX1 = node.x0;
          availWidth = newX1 - newX0;
        } else if (direction === WEST) {
          newY1 = node.y0;
          availHeight = newY1 - newY0;
        } else if (direction === NORTH) {
          newX0 = node.x1;
          availWidth = newX1 - newX0;
        }

        nodesSum -= segmentSum;
        segment.length = 0;
        segmentSum = 0;
        avgAspectRatio = 0;
        direction = (direction + 1) % 4;
      }
    }
  }

  treemapBrick(parent, x0, y0, x1, y1) {
    var EAST = 0;
    var SOUTH = 1;
    var WEST = 2;
    var NORTH = 3;

    let curDirection = 0;

    var direction = EAST;

    var nodes = parent.children;
    var node;

    // console.log(parent)
    // if (parent.depth == 1) return;

    var n = nodes.length;
    var i = -1;
    var newX0 = x0;
    var newX1 = x1;
    var newY0 = y0;
    var newY1 = y1;

    var availWidth = x1 - x0;
    var availHeight = y1 - y0;

    var avgAspectRatio = 0;
    var nodeAspectRatio = 0;

    var segment = [];
    var segmentSum = 0;
    var nodesSum = 0;

    for (i = n; i--; ) nodesSum += nodes[i].value;

    i = -1;

    if (nodes[0].depth <= 2) {
      while (++i < n) {
        node = nodes[i];

        segment.push(node);

        segmentSum += node.value;

        if (direction === EAST) {
          // Update positions for each node.
          segment.forEach(function (d, i, arr) {
            d.x0 = i ? arr[i - 1].x1 : newX0;
            d.x1 = d.x0 + (d.value / segmentSum) * availWidth;
            d.y0 = newY0;
            d.y1 = newY0 + (segmentSum / nodesSum) * availHeight;
          });
        } else if (direction === SOUTH) {
          segment.forEach(function (d, i, arr) {
            d.x0 = newX1 - (segmentSum / nodesSum) * availWidth;
            d.x1 = newX1;
            d.y0 = i ? arr[i - 1].y1 : newY0;
            d.y1 = d.y0 + (d.value / segmentSum) * availHeight;
          });
        } else if (direction === WEST) {
          segment.forEach(function (d, i, arr) {
            d.x1 = i ? arr[i - 1].x0 : newX1;
            d.x0 = d.x1 - (d.value / segmentSum) * availWidth;
            d.y0 = newY1 - (segmentSum / nodesSum) * availHeight;
            d.y1 = newY1;
          });
        } else if (direction === NORTH) {
          segment.forEach(function (d, i, arr) {
            d.x1 = newX0 + (segmentSum / nodesSum) * availWidth;
            d.x0 = newX0;
            d.y1 = i ? arr[i - 1].y0 : newY1;
            d.y0 = d.y1 - (d.value / segmentSum) * availHeight;
          });
        }

        // Compute new aspect ratio.
        nodeAspectRatio =
          direction & 1
            ? (node.y1 - node.y0) / (node.x1 - node.x0)
            : (node.x1 - node.x0) / (node.y1 - node.y0);

        avgAspectRatio = d3.sum(segment, function (d) {
          return direction & 1
            ? (d.y1 - d.y0) / (d.x1 - d.x0)
            : (d.x1 - d.x0) / (d.y1 - d.y0);
        });

        if (segment.length > 1 || direction != EAST) {
          if (direction === EAST) {
            newY0 = node.y1;
            availHeight = newY1 - newY0;
          } else if (direction === SOUTH) {
            newX1 = node.x0;
            availWidth = newX1 - newX0;
          } else if (direction === WEST) {
            newY1 = node.y0;
            availHeight = newY1 - newY0;
          } else if (direction === NORTH) {
            newX0 = node.x1;
            availWidth = newX1 - newX0;
          }

          nodesSum -= segmentSum;
          segment.length = 0;
          segmentSum = 0;
          avgAspectRatio = 0;
          direction = (direction + 1) % 4;
        }
      }
    } else {
      while (++i < n) {
        // console.log(i)
        // if (i < n) {
        node = nodes[i];

        segment.push(node);

        segmentSum += node.value;
        // }

        if (direction === EAST) {
          // Update positions for each node.
          segment.forEach(function (d, i, arr) {
            d.x0 = i ? arr[i - 1].x1 : newX0;
            d.x1 = d.x0 + (d.value / segmentSum) * availWidth;
            d.y0 = newY0;
            d.y1 = newY0 + (segmentSum / nodesSum) * availHeight;
          });
        } else if (direction === SOUTH) {
          segment.forEach(function (d, i, arr) {
            d.x0 = newX1 - (segmentSum / nodesSum) * availWidth;
            d.x1 = newX1;
            d.y0 = i ? arr[i - 1].y1 : newY0;
            d.y1 = d.y0 + (d.value / segmentSum) * availHeight;
          });
        } else if (direction === WEST) {
          segment.forEach(function (d, i, arr) {
            d.x1 = i ? arr[i - 1].x0 : newX1;
            d.x0 = d.x1 - (d.value / segmentSum) * availWidth;
            d.y0 = newY1 - (segmentSum / nodesSum) * availHeight;
            d.y1 = newY1;
          });
        } else if (direction === NORTH) {
          segment.forEach(function (d, i, arr) {
            d.x1 = newX0 + (segmentSum / nodesSum) * availWidth;
            d.x0 = newX0;
            d.y1 = i ? arr[i - 1].y0 : newY1;
            d.y0 = d.y1 - (d.value / segmentSum) * availHeight;
          });
        }

        // Compute new aspect ratio.
        nodeAspectRatio =
          direction & 1
            ? (node.y1 - node.y0) / (node.x1 - node.x0)
            : (node.x1 - node.x0) / (node.y1 - node.y0);

        avgAspectRatio = d3.sum(segment, function (d) {
          return direction & 1
            ? (d.y1 - d.y0) / (d.x1 - d.x0)
            : (d.x1 - d.x0) / (d.y1 - d.y0);
        });

        // If avg aspect ratio is small, update boundaries and start a new segment.
        if (avgAspectRatio / segment.length < 2) {
          // if (direction === EAST && avgAspectRatio / segment.length < 1) {
          // console.log("segment length: " + segment.length)

          // if (segment.length > 1 || direction != EAST) {

          if (direction === EAST) {
            // console.log("EAST")
            newY0 = node.y1;
            availHeight = newY1 - newY0;
          } else if (direction === SOUTH) {
            // console.log("SOUTH")
            newX1 = node.x0;
            availWidth = newX1 - newX0;
          } else if (direction === WEST) {
            // console.log("WEST")
            newY1 = node.y0;
            availHeight = newY1 - newY0;
          } else if (direction === NORTH) {
            // console.log("NORTH")
            newX0 = node.x1;
            availWidth = newX1 - newX0;
          }

          nodesSum -= segmentSum;
          segment.length = 0;
          segmentSum = 0;
          avgAspectRatio = 0;

          // direction = (direction + 1) % 2;
        }
      }
    }
  }

  sortDescending(a, b) {
    return b.height - a.height || b.value - a.value;
  }

  sortAscending(a, b) {
    return a.height - b.height || a.value - b.value;
  }

  getSeasonalPriority = (season) => {
    switch (season) {
      case "spring":
        return 1;
      case "summer":
        return 2;
      case "fall":
        return 4;
      case "winter":
        return 3;
      default:
        return 5;
    }
  };

  getValueByOrderOption = (data, orderOption) => {
    switch (orderOption) {
      case TreemapOrderEnum.YEAR:
        return data.name;
      case TreemapOrderEnum.PRECIPITATION:
        return -1 * data.value.pr;
      case TreemapOrderEnum.TEMPERATURE:
        return -1 * data.value.tasmax;
      default:
        return undefined;
    }
  };

  sortLeafDataByOrderOption = (data, orderOption) => {
    data.sort((itemA, itemB) => {
      let valA = this.getValueByOrderOption(itemA, orderOption);
      let valB = this.getValueByOrderOption(itemB, orderOption);

      return valA - valB;
    });
  };

  dataSeasonalReorder(d, seasonalOrder = true) {
    let regionalData = d.children;
    let orderOption = this.state.orderOption;
    if (regionalData == null) return null;

    // console.log(regionalData);
    // do seasonal reorder

    if (this.props.doDrawRegionFirst) {
      if (seasonalOrder)
        regionalData.forEach((item) =>
          item.children.sort((itemA, itemB) => {
            // console.log(itemA.name);
            let valA = this.getSeasonalPriority(itemA.name);
            let valB = this.getSeasonalPriority(itemB.name);

            return valA - valB;
          })
        );
    } else {
      regionalData.sort((itemA, itemB) => {
        // console.log(itemA.name);
        let valA = this.getSeasonalPriority(itemA.name);
        let valB = this.getSeasonalPriority(itemB.name);

        return valA - valB;
      });
    }

    // do reoder in option

    for (let regionalItem of regionalData) {
      let seasonalData = regionalItem.children;

      for (let subItem of seasonalData) {
        let leafData = subItem.children;
        this.sortLeafDataByOrderOption(leafData, orderOption);
      }
    }

    return d;
  }

  createTreeMap_dy() {
    let treeMapComponent = this;
    let stateData = this.state.data;
    let data = undefined;
    // filtering data if required
    // if (this.state.seasonalLevel) {
    //   let seasonIndex = this.getSeasonalPriority(this.state.seasonalLevel);
    //   stateData = stateData.children[seasonIndex];
    //   if (this.state.regionalLevel) {
    //     let regionIndex = _.findIndex(stateData.children, (d) => d.name === this.state.regionalLevel);
    //     stateData = stateData.children[regionIndex];
    //     this.sortLeafDataByOrderOption(stateData.children, this.state.orderOption);
    //   }
    //   else
    //     _.forEach(stateData.children, s => this.sortLeafDataByOrderOption(s.children, this.state.orderOption));
    //   data = stateData;
    //   console.log(data);
    // }
    // else
    data = this.dataSeasonalReorder(stateData);

    let width = this.state.width,
      height = Math.ceil(this.state.height)*0.95,
      margin = this.props.margin || {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0,
      },
      formatNumber = d3.format(",d"),
      transitioning;

    let intensityScale = d3
      .scaleLinear()
      .domain([-8, 17, 42])
      // .domain([268.88, 295, 313.47])
      .range(["#225da8", "#fffde6", "#a50026"]);

    let getValue = (d) => (d ? d.pr : undefined);

    const svg = d3
      .select(this.treeMapRef)
      .attr("class", "treemap-container")
      .attr("width",  width + margin.left + margin.right)
      .attr("height",  height + margin.top + margin.bottom)
      // .attr("viewBox", [
      //   0,
      //   0,
      //   width + margin.left + margin.right,
      //   height + margin.top + margin.bottom,
      // ])
      .style("font", "10px sans-serif")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .style("shape-rendering", "crispEdges");

    var x = d3.scaleLinear().domain([0, width]).range([0, width]);

    var y = d3.scaleLinear().domain([0, height]).range([0, height]);

    var treemap = d3
      .treemap()
      .size([width, height])
      .round(false)
      .paddingOuter(0.25)
      // .paddingInner(1)
      // .padding(2)
      // .tile(this.treemapSpiral);
      .tile(this.treemapBrick);

    var root = d3
      .hierarchy(data)
      .eachBefore(function (d) {
        d.id = (d.parent ? d.parent.id + "." : "") + d.data.name;
      })
      .sum((d) => getValue(d.value));

    var grandparent = svg.append("g").attr("class", "grandparent");
    let selectionWidth = width / 2;

    grandparent
      .append("rect")
      .attr("id", "selection-label")
      .attr("y", -margin.top)
      .attr("width", selectionWidth)
      .attr("height", margin.top);

    grandparent
      .append("text")
      .attr("id", "treemap-hierarchy")
      .attr("x", 6)
      .attr("y", 6 - margin.top)
      .attr("dy", ".75em");

    // sort options
    let sortOptions = svg.append("g").attr("class", "sort-options");

    let sortOptionWidth = selectionWidth * 0.25;
    sortOptions
      .append("rect")
      .attr("x", selectionWidth)
      .attr("y", -margin.top)
      .attr("width", sortOptionWidth)
      .attr("height", margin.top)
      .style("fill", "lightgray");

    sortOptions
      .append("text")
      .attr("x", 6 + selectionWidth)
      .attr("y", 6 - margin.top)
      .attr("dy", ".75em")
      .text("Sort");

    let sortOrderCount = 1;

    _.forEach(TreemapOrderEnum, (value, key) => {
      sortOptions
        .append("rect")
        .attr(
          "class",
          "sort-option " +
            key +
            (this.state.orderOption === value ? " selected" : "")
        )
        .attr("x", selectionWidth + sortOptionWidth * sortOrderCount)
        .attr("y", -margin.top)
        .attr("width", sortOptionWidth)
        .attr("height", margin.top)
        .on("click", ()=>{
          d3.selectAll(".sort-option").classed("selected", false);

          this.setState(
            {
              orderOption: value,
              doReordering: true,
            },
            () => d3.select("." + key).classed("selected", true)
          );
        });

      sortOptions
        .append("text")
        .attr("x", 3 + selectionWidth + sortOptionWidth * sortOrderCount)
        .attr("y", 6 - margin.top)
        .attr("dy", ".75em")
        .text(_.capitalize(key));

      sortOrderCount++;
    });

    // if (title) {
    //     $("#chart").prepend("<p class='title'>" + opts.title + "</p>");
    // }
    // if (data instanceof Array) {
    //     root = {
    //         key: rname,
    //         values: data
    //     };
    // } else {
    //     root = data;
    // }
    initialize(root);
    accumulate(root);
    layout(root);
    treemap(root);
    display(root);

    function initialize(root) {
      root.x = root.y = 0;
      root.x1 = width;
      root.y1 = height;
      root.depth = 0;
    }

    function accumulate(d) {
      // console.log('accumulate called ' + d.data.name);
      return (d._children = d.children)
        ? (d.value = d.children.reduce(function (p, v) {
            return p + accumulate(v);
          }, 0))
        : d.value;
    }

    function layout(d) {
      if (d._children) {
        d._children.forEach(function (c) {
          c.x0 = d.x0 + c.x0 * d.x1;
          c.y0 = d.y0 + c.y0 * d.y1;
          c.x1 *= d.x1 - d.x0;
          c.y1 *= d.y1 - d.y0;
          c.parent = d;
          layout(c);
        });
      }
    }

    function display(d) {
      grandparent
        .datum(d.parent)
        .on("click", transition)
        .select("text")
        .text(name(d));

      var g1 = svg.insert("g", ".grandparent").datum(d).attr("class", "depth");

      var g = g1.selectAll("g").data(d._children).enter().append("g");

      g.filter(function (d) {
        return d._children;
      })
        .classed("children", true)
        .on("click", (e) => {
          transition(e);
        });
      // .on("click", transition);

      var children = g
        .selectAll(".child")
        .data(function (d) {
          return d._children || [d];
        })
        .enter()
        .append("g");

      children
        .append("rect")
        .attr("class", "child")
        .call(rect)
        .append("title")
        .text(function (d) {

          if (d.depth > 2) {
            return (
              d.data.name +
              " ( pr: " +
              formatNumber(d.data.value.pr) +
              ", tas: " +
              formatNumber(d.data.value.tasmax) +
              ")"
            );
          }

          return d.data.name;
        });

      if (d.depth < 1) {
        let leafChildren = g
          .selectAll(".leafChild")
          .data((temp) => {
            // console.log(temp._children.map(item=>item._children).flat(2))
            return temp._children.map((item) => item._children).flat(2);
          })
          .enter()
          .append("g");

        leafChildren.append("rect").attr("class", "leafChild").call(rect);
      }

      g.append("rect").attr("class", "parent").call(rect);

      var t = g.append("text").attr("class", "ptext").attr("dy", ".75em");

      t.append("tspan").text(function (d) {
        let depth = d.depth;


        // if (depth <= 2) return d.data.name;

        if (depth <= 2 ) return d.data.name;


        if(depth > 2 && treeMapComponent.state.isExpand){
          return d.data.name.toString();  
        }else{
          return d.data.name.toString().substring(2);
        }
      });


      // t.append("tspan")
      //   .attr("dy", "1.0em")
      //   .text(function (d) {
      //     return formatNumber(d.value);
      //   });

      t.call(text);

      g.selectAll("rect")
        .style("fill", (d) => {
          if (d.depth <= 2) {
            // console.log(d.data.name)
            // console.log(color(d.data.name))
            return null;
          }

          if (Object.keys(d).includes("data"))
            if (Object.keys(d.data).includes("value")) {
              // console.log(d.data.value.tasmax);
              // console.log(intensityScale(d.data.value.tasmax));
              return intensityScale(d.data.value.tasmax);
            }
        })
        .style("opacity", (d) => {
          if (d.depth <= 2) return 0;
          else return 1;
        });

      function transition(d) {
        if (transitioning || !d) return;

        console.log(d)

        transitioning = true;

        var g2 = display(d),
          t1 = g1.transition().duration(() => {
            if (treeMapComponent.state.doReordering) return 1500;

            return 750;
          }),
          t2 = g2.transition().duration(() => {
            if (treeMapComponent.state.doReordering) return 1500;

            return 750;
          });

        // Update the domain only after entering new elements.
        x.domain([d.x0, d.x0 + (d.x1 - d.x0)]);
        y.domain([d.y0, d.y0 + (d.y1 - d.y0)]);

        // Enable anti-aliasing during the transition.
        svg.style("shape-rendering", null);

        // Draw child nodes on top of parent nodes.
        svg.selectAll(".depth").sort(function (a, b) {
          return a.depth - b.depth;
        });

        // Fade-in entering text.
        g2.selectAll("text").style("fill-opacity", 0);

        // Transition to the new view.
        t1.selectAll(".ptext").call(text).style("fill-opacity", 0);
        // t1.selectAll(".ctext").call(text2).style("fill-opacity", 0);
        t2.selectAll(".ptext").call(text).style("fill-opacity", 1);
        // t2.selectAll(".ctext").call(text2).style("fill-opacity", 1);
        t1.selectAll("rect").call(rect);
        t2.selectAll("rect").call(rect);

        // Remove the old node when the transition is finished.
        t1.remove().on("end", function () {
          svg.style("shape-rendering", "crispEdges");
          transitioning = false;
        });
      }
      
      if (treeMapComponent.state.doDrawRegionFirst) {
        if (d.depth === 0) {
          if (
            treeMapComponent.state.doReordering &&
            treeMapComponent.state.regionalLevel != null
          ) {
           
            transition(
              searchNodeByName(root, treeMapComponent.state.regionalLevel)
            );
          } else {
            treeMapComponent.setState({
              doReordering: false,
              regionalLevel: null,
            });
          }
        }

        if (d.depth === 1) {
          if (
            treeMapComponent.state.doReordering &&
            treeMapComponent.state.seasonalLevel != null
          ) {
            transitioning = false;

            // console.log(searchNodeByName(root, treeMapComponent.state.seasonalLevel));
            transition(
              searchNodeByName(d, treeMapComponent.state.seasonalLevel)
            );
          } else {
            treeMapComponent.state.setSeasonFunction_parent(null);

            treeMapComponent.setState({
              doReordering: false,
              seasonalLevel: null,
              regionalLevel: d.data.name,
            });
          }
        }

        if (d.depth === 2) {
          treeMapComponent.state.setSeasonFunction_parent(d.data.name)

          treeMapComponent.setState({
            doReordering: false,
            seasonalLevel: d.data.name,
          });
        }
      } else {
        if (d.depth === 0) {
          if (
            treeMapComponent.state.doReordering &&
            treeMapComponent.state.seasonalLevel != null
          ) {
            // console.log(searchNodeByName(root, treeMapComponent.state.seasonalLevel));
            transition(
              searchNodeByName(root, treeMapComponent.state.seasonalLevel)
            );
          } else {
            treeMapComponent.state.setSeasonFunction_parent(null)
            treeMapComponent.setState({
              doReordering: false,
              seasonalLevel: null,
            });
          }
        }

        if (d.depth === 1) {
          if (
            treeMapComponent.state.doReordering &&
            treeMapComponent.state.regionalLevel != null
          ) {
            transitioning = false;
            transition(
              searchNodeByName(d, treeMapComponent.state.regionalLevel)
            );
          } else {
            treeMapComponent.state.setSeasonFunction_parent(d.data.name)
            treeMapComponent.setState({
              doReordering: false,
              regionalLevel: null,
              seasonalLevel: d.data.name,
            });
          }
        }

        if (d.depth === 2) {
          treeMapComponent.setState({
            doReordering: false,
            regionalLevel: d.data.name,
          });
        }
      }

      return g;
    }

    function searchNodeByName(d, nodeName) {
      let items = d._children;
      let returnNode = null;

      for (let item of items) {
        if (item.data.name === nodeName) {
          returnNode = item;
        }

        let subItems = item._children;
        if (subItems === undefined || subItems === null) continue;

        for (let subItem of subItems) {
          if (subItem.data.name === nodeName) {
            returnNode = subItem;
          }
        }

        if (returnNode != null) break;
      }

      return returnNode;
    }

    function text(text) {
      text.selectAll("tspan").attr("x", function (d) {
        return x(d.x0) + 6;
      });

      text
        // .attr("transform", function (d) {
        //   let depth = d.depth;
        //   if (depth <= 2) return "rotate(0)";

        //   let parentWidth = this.parentElement.getBoundingClientRect().width;
        //   let parentHeight = this.parentElement.getBoundingClientRect().height;

        //   let rectWidth = this.parentElement.getElementsByTagName("rect")[1].getBoundingClientRect().width;
        //   let rectHeight = this.parentElement.getElementsByTagName("rect")[1].getBoundingClientRect().height;
        //   // let parentWidth = d3.select(this.parentElement.getElementsByTagName("rect")[0]).attr("width");
        //   // let parentHeight = d3.select(this.parentElement.getElementsByTagName("rect")[0]).attr("height")
        //   console.log(this.parentElement)

        //   console.log("width:" + parentWidth)
        //   console.log("height:" +parentHeight)

        //   console.log("rect width:" + rectWidth)
        //   console.log("rect height:" +rectHeight)

        //   console.log(parentWidth / parentHeight)
        //   console.log((parentWidth / parentHeight) >1)
        //   if ((parentWidth / parentHeight) > 1.5 && (rectWidth / rectHeight) >2.5 || parentWidth > 25) return "rotate(0)";

        //   let xTransition = parentWidth > 40 ? parentWidth / 2 : 20;

        //   return `rotate(-90, ${x(d.x0) + xTransition}, ${y(d.y0)})`;
        // })
        .attr("x", function (d) {
          let parentWidth = this.parentElement.getBoundingClientRect().width;
          let xTransition = parentWidth > 40 ? parentWidth / 2 : 20;

          return x(d.x0) + xTransition;
        })
        .attr("y", function (d) {
          return y(d.y0) + 3;
          // let depth = d.depth;
          // if (depth <= 2) return y(d.y0) + 3;

          // let parentWidth = this.parentElement.getBoundingClientRect().width;
          // let parentHeight = this.parentElement.getBoundingClientRect().height;

          // let rectWidth = this.parentElement.getElementsByTagName("rect")[1].getBoundingClientRect().width;
          // let rectHeight = this.parentElement.getElementsByTagName("rect")[1].getBoundingClientRect().height;

          // // let parentWidth = d3.select(this.parentElement.getElementsByTagName("rect")[0]).attr("width");
          // // let parentHeight = d3.select(this.parentElement.getElementsByTagName("rect")[0]).attr("height")

          // if (parentWidth / parentHeight>1.5  && (rectWidth / rectHeight) >2.5 || parentWidth > 25) return y(d.y0) + 3;

          // // if (parentHeight < parentWidth) return y(d.y0) + 3;

          // return y(d.y0) - 17;
        })

        .style("opacity", function (d) {
          var w = x(d.x1) - x(d.x0);
          return 1;

          return this.getComputedTextLength() < w - 6 ? 1 : 0;
        });
    }

    function text2(text) {
      text
        .attr("x", function (d) {
          return x(d.x1) - this.getComputedTextLength() - 6;
        })
        .attr("y", function (d) {
          return y(d.y1) - 6;
        })
        .attr("transform", (d) => {
          // console.log(d);
          let depth = d.depth;
          if (depth <= 2) return "rotate(0)";

          return "rotate(90)";
        })
        .style("opacity", function (d) {
          var w = x(d.x1) - x(d.x0);

          return this.getComputedTextLength() < w - 6 ? 1 : 0;
        });
    }

    function rect(rect) {
      rect
        .attr("x", function (d) {
          return x(d.x0);
        })
        .attr("y", function (d) {
          return y(d.y0);
        })
        .attr("width", function (d) {
          var w = x(d.x1) - x(d.x0);
          // console.log('id ' + d.id + ' rect width ' + w);
          return w;
        })
        .attr("height", function (d) {
          var h = y(d.y1) - y(d.y0);
          // console.log('id ' + d.id + ' rect height ' + h);
          return h;
        });
    }

    function name(d) {
      return d.parent ? name(d.parent) + " / " + d.data.name : d.data.name;
    }

    //
    // treemap(root);

    // let cell = svg
    //   .selectAll("g")
    //   .data(root.leaves())
    //   .enter()
    //   .append("g")
    //   .attr("transform", function (d) {
    //     return "translate(" + d.x0 + "," + d.y0 + ")";
    //   });

    // cell
    //   .append("rect")
    //   .attr("id", (d) => {
    //     console.log(d);
    //     return (d.leafUid = DOM.uid("leaf")).id;
    //   })

    //   .attr("width", function (d) {
    //     return d.x1 - d.x0;
    //   })
    //   .attr("height", function (d) {
    //     return d.y1 - d.y0;
    //   })
    //   // .attr("stroke", function (d) {
    //   //   return d3.color(color(d.parent.data.index || d.data.index)).darker(1);
    //   // })
    //   .attr("fill", (d) => intensityScale(d.data.value.tasmax));

    // cell
    //   .append("clipPath")
    //   .attr("id", (d) => (d.clipUid = DOM.uid("clip")).id)
    //   .append("use")
    //   .attr("xlink:href", (d) => d.leafUid.href);

    // cell
    //   .append("text")
    //   .attr("clip-path", (d) => d.clipUid)
    //   .selectAll("tspan")
    //   .data((d) =>
    //   {
    //     // console.log(d)
    //     return [d.data]
    //     return getValue(d.data.value).split(/(?=[A-Z][a-z])|\s+/g)
    //   })
    //   .enter()
    //   .append("tspan")
    //   .attr("x", 3)
    //   .attr(
    //     "y",
    //     (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`
    //   )
    //   .text(function (d) {
    //     // return d;
    //     // console.log(d)
    //     return d.name + " : " + d.value.pr
    //   });
  }

  createTreeMap() {
    let width = this.state.width;
    let height = this.state.height;
    let data = this.state.data;
    // let colorScale = this.state.colorScale;
    // let name = d => d.ancestors().reverse().map(d => d.data.name).join("/");
    // let format = d3.format(",d");
    // let intensityScale = d3.scaleLinear().domain([268.88, 313.47]).range([0.2, 1]);
    let intensityScale = d3
      .scaleLinear()
      .domain([-8, 17, 42])
      // .domain([268.88, 295, 313.47])
      .range(["#225da8", "#fffde6", "#a50026"]);
    let getValue = (d) => (d ? d.pr : undefined);
    // let getIntensity = (d) => d.tasmax? intensityScale(d.tasmax): 0.6;

    let tile = (node, x0, y0, x1, y1) => {
      d3.treemapBinary(node, 0, 0, width, height);

      for (const child of node.children) {
        child.x0 = x0 + (child.x0 / width) * (x1 - x0);
        child.x1 = x0 + (child.x1 / width) * (x1 - x0);
        child.y0 = y0 + (child.y0 / height) * (y1 - y0);
        child.y1 = y0 + (child.y1 / height) * (y1 - y0);
      }
    };

    // console.log(data);
    // console.log(
    //   d3
    //     .hierarchy(data)
    //     .sum((d) => getValue(d.value))
    //     .sort((a, b) => getValue(b.value) - getValue(a.value))
    // );

    let treeMap = (data) =>
      d3
        .treemap()
        // .tile(tile)
        .tile(this.treemapSpiral)
        .size([width, height])
        // .paddingTop(3)
        // .paddingLeft(3)
        // .paddingRight(3)
        // .paddingBottom(3)
        .paddingInner(1)
        .padding(2)(
        d3
          .hierarchy(data)
          .sum((d) => getValue(d.value))
          .sort((a, b) => getValue(b.value) - getValue(a.value))
      );

    const root = treeMap(data);

    const svg = d3
      .select(this.treeMapRef)
      .append("svg")
      .attr("viewBox", [0, 0, width, height])
      .style("font", "10px sans-serif");

    const leaf = svg
      .selectAll("g")
      .data(root.leaves())
      .join("g")
      .attr("transform", (d) => `translate(${d.x0},${d.y0})`);
    // leaf.append("title")
    //   .text(d => `${d.ancestors().reverse().map(d => d.data.name).join("/")}\n${format(d.value)}`);

    leaf
      .append("rect")
      .attr("id", (d) => (d.leafUid = DOM.uid("leaf")).id)
      .attr("fill", (d) => intensityScale(d.data.value.tasmax))
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0);

    leaf
      .append("clipPath")
      .attr("id", (d) => (d.clipUid = DOM.uid("clip")).id)
      .append("use")
      .attr("xlink:href", (d) => d.leafUid.href);

    leaf
      .append("text")
      .attr("clip-path", (d) => d.clipUid)
      .selectAll("tspan")
      .data((d) => getValue(d.data.value).split(/(?=[A-Z][a-z])|\s+/g))
      .join("tspan")
      .attr("x", 3)
      .attr(
        "y",
        (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`
      )
      .attr("fill-opacity", (d, i, nodes) =>
        i === nodes.length - 1 ? 0.7 : null
      )
      .text((d) => d);

    // svg
    //   .selectAll("titles")
    //   .data(root.descendants().filter(function(d){return d.depth===1}))
    //   .enter()
    //   .append("text")
    //   .attr("x", function(d){ return d.x0+3})
    //   .attr("y", function(d){ return d.y0+8})
    //   .text(function(d){ return _.startCase(d.data.name) })
    //   .attr("font-size", "12px")
    //   .attr("fill",  "black" )
  }

  render() {
    // return <div ref={(ref) => (this.treeMapRef = ref)}></div>;
    return (
      <div>
        <div
          style={{cursor: "pointer", marginLeft: "0.5rem", fontWeight: "bold", fontSize: "0.7rem"}}>
          <SummaryChartNavigation
            current={"treemap"}
            header={"PR & TASMAX TreeMap"}
            body={"Tree map presents the mean precipitation and maximum temperature. " +
            "Precipitation decides the cell dimension whereas temperature denotes the color intensity." +
            "Sorting options reorders cells to display different perspectives."
            }
            onChartChange={this.props.onChartChange}
          />

          {/*PR & TASMAX TreeMap*/}
          {/*<CaretRightFill id="popover-description" style={{margin: "auto 0"}}/>*/}
          {/*<Popover*/}
          {/*  placement="right"*/}
          {/*  isOpen={this.state.showPopover}*/}
          {/*  target={"popover-description"}*/}
          {/*>*/}
          {/*  <PopoverHeader>Objective</PopoverHeader>*/}
          {/*  <PopoverBody>*/}
          {/*    Tree map presents the mean precipitation and maximum temperature.*/}
          {/*    Precipitation decides the cell dimension whereas temperature denotes the color intensity.*/}
          {/*    Sorting options reorders cells to display different perspectives.*/}
          {/*  </PopoverBody>*/}
          {/*</Popover>*/}

        </div>
        <div>
          <svg style={{marginTop: "1.65rem"}} ref={(ref) => (this.treeMapRef = ref)}> </svg>
        </div>
      </div>
    );
  }
}

export default TreeMap;
