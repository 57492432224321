import _ from "lodash";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import ChartWrapper from "../wrapper/ChartWrapper";
import GeoSpatial from "../charts/TestComponentExp.GeoSpatial";
import drawHeatMap from "../../utils/exploreNetCDF/DrawCustomHeatMap";
import renderThumbnails from "../../utils/exploreNetCDF/DrawThumbnailGrid";
import renderLegend from "../../utils/exploreNetCDF/DrawHeatmapLegend";
import {Popover, PopoverHeader, PopoverBody} from "reactstrap";
import {
  returnLineChart,
  returnBarchart,
  returnTreemap,
} from "../../utils/exploreNetCDF/DrawSummaryCharts";
// import {CaretRightFill} from 'react-bootstrap-icons'

import RangeSlider from "react-dual-rangeslider";
import ManageDataSet from "../../requestProcessors/manageDataSets";

import "../../stylesheets/views/TestComponent.css";
import "../../stylesheets/SliderNavigator.css";
// style: thumbnail container scrollbar
import "../../stylesheets/views/scrollbar_thin.css";
// style: spinner
import "../../stylesheets/views/testComponentExp.spinner.css";
import { ic_filter_tilt_shift } from "react-icons-kit/md";
import { jsx } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
// import Spinner2 from "../../utils/exploreNetCDF/DrawSpinner";

import ManageUserData from "../../requestProcessors/manageUserData";
import HorizontalNavigation from "../navigation/HorizontalNavigation";
import {InfoIcon} from "../../utils/IconContainer";

const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
let d3 = require("d3");

class TestComponentExp extends React.Component {
  constructor(props) {
    super(props);
    this.canvasHeight = this.props.canvasHeight;
    this.canvasWidth = this.props.canvasWidth;
    this.config = this.props.config;

    this.Index = this.props.Index;
    this.HeatData = this.props.HeatData;
    this.BarData = this.props.BarData;
    this.PRSRM = this.props.PRSRM;
    this.TASMAXSRM = this.props.TASMAXSRM;
    this.HistoricalMean = this.props.HistoricalMean;
    this.PredictionModel = this.props.modelName;
    this.PredictionScenarios = this.props.scenarioSet;
    this.applyParsingToIndexFileName =
      this.props.applyParsingToIndexFileName || false;

    this.dataProcessor = new ManageDataSet();
    this.default = {
      prHeatMapFilter: {
        //  corresponds to `this.state.relativeHeatmapIntensity`
        1: { min: -1, max: 1, minRange: 0.15 },
        0: { min: 0, max: 10, minRange: 1 },
      },
    };

    // INDEX RELATED DEFAULT DATA
    this.startYear = this.props.startYear;
    this.endYear = this.props.endYear;
    this.endYearIndex = this.props.endYearIndex;
    this.startIndex = this.props.startIndex;
    this.hmStartIndex = this.props.hmStartIndex? this.props.hmStartIndex: this.startIndex;
    this.indexLength = this.props.indexLength;
    this.indexes = _.slice(
      this.Index.index,
      this.startIndex,
      this.startIndex + this.indexLength
    );
    this.indexes = _.reverse(this.indexes);

    this.state = {
      height: this.props.canvasHeight - 50,
      width: this.props.canvasWidth - 20,
      yDomain: this.props.yDomain || _.range(2007, 2057, 1),
      currentView: 4,
      expand: false,
      // expand: false,
      relativeHeatmapIntensity: true,
      heatmapRanges: {
        //  corresponds to `this.state.relativeHeatmapIntensity`
        1: {
          min: this.default.prHeatMapFilter[1].min,
          max: this.default.prHeatMapFilter[1].max,
        },
        0: {
          min: this.default.prHeatMapFilter[0].min,
          max: this.default.prHeatMapFilter[0].max,
        },
      },
      seasonalRegionalParameters: { seasons: "", regions: "" },
      seasonalRegionalResponseData: null,
      regionalSeasonalResponseData: null,
      selectedIndex: this.indexes[0],
      inspectedIndex: undefined, // from heat map click
      // visualizations under the map view
      doVisContainerUpdate: true,
      visContainer_currentSelection: "barchart",
      treeMap: null,
      treeMap_prev_seasonalLevel: null,
      visContainer_linechart: null,
      visContainer_barchart: null,
      visContainer: null,
      // visContainerHeight: 440,
      // thumbnail chart
      thumbnailChart: null,
      // for expand option
      heatmapCellWidth: null,
      // legend view
      legendContainer: null,
      //spinner
      showSpinner: true,
      // showSpinner: false,
      // legend next to heatmap
      heatmapLegend: null,
      //annotation
      annotationOverview: null,
      showHeatmapPopover: false,
      showThumbnailPopover:false
    };
    this.chartWrapper = new ChartWrapper(this, "?", this.props);

    // GRID AND HEATMAP RELATED DEFAULT DATA
    this.userDataManager = new ManageUserData();

    this.rowSize = 12;
    this.thHeight = 35;
    this.thWidth = 50;
    this.gridHeight = (this.indexLength / this.rowSize) * this.thHeight;
    this.gridWidth = this.rowSize * this.thWidth;
    this.rawHeatData = this.HeatData;
    this.heatData = _.cloneDeep(this.HeatData);

    // Left layout sizing
    this.state.barChartWidth = this.thWidth * 2; // intermediate bar chart and line charts in the heat map/grid
    this.gridExpandWidth = this.gridWidth + this.state.barChartWidth * 4; // total expanded width
    this.leftAxisWidthWithMargin = 50;
    this.yearlyMeanHistogram = this.state.barChartWidth;
    this.gridHeatMapWidth = () =>
      this.state.expand ? this.gridExpandWidth : (this.gridWidth+30);
    this.sideNavWidth = this.canvasWidth * 0.03;
    this.leftLayoutWidth = () =>
      this.gridHeatMapWidth() +
      this.leftAxisWidthWithMargin +
      this.yearlyMeanHistogram +
      0; // scroll bar width;
    this.navbarWidth = () => this.gridExpandWidth + this.leftAxisWidthWithMargin + this.yearlyMeanHistogram + 15;
    this.rightChartWidth = () =>
      this.canvasWidth - this.leftLayoutWidth(); // - this.sideNavWidth;
    // this.geoSpatialLabelWidth = () => this.rightChartWidth() - 30;
    this.geoSpatialLabelWidth = () => this.canvasWidth - this.navbarWidth() - 30;
    this.geoSpatialLabelFieldWidth = (d = 3.2) => this.geoSpatialLabelWidth() / d;
    this.heatMapHeight = this.canvasHeight * 0.28 - 17;
    this.thMatrixHeight = this.canvasHeight * 0.7 - 5 - this.heatMapHeight *0.1 - 45;

    // right layout sizing
    this.infoHeight = 55;
    this.geoSpatialViewHeight = this.canvasHeight * 0.5;
    this.summaryChartHeight = () =>
      this.canvasHeight - this.infoHeight - this.geoSpatialViewHeight - 10;

    this.visContainerElem = ["treemap", "barchart", "linechart"];

    this.seasonColorScale = this.config.seasonColorScale;
    //seasonal multiple line chart
    this.lineChartSetting = { hover: "seasons", click: "regions" };
    this.minMeanPrecipitation = 0;
    this.maxMeanPrecipitation = 5;
    /////////////////////////////////////
    this.regionalColorScale = this.config.regionalColorScale;
    this.seasonList = this.config.seasonList;
    this.regionList = this.config.regionList;
    this.regionIndex = this.config.regionIndex;
    this.regionAcronyms = this.config.regionAcronyms;
    this.regionMap = this.config.regionThPositionMap;

    // TOOLTIP ELEMENT TO USE IN HEATMAP VIS
    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "image-tooltip")
      .style("background", "white")
      .style("left", "100px")
      .style("top", "-100px")
      .style("height", "110px")
      .style("width", "170px");

    // TOOLTIP ELEMENT TO USE IN THUMBNAIL GRID
    this.thTooltip = d3
      .select("body")
      .append("div")
      .attr("class", "image-tooltip th-tooltip")
      .style("background-color","white")
      .style("left", "100px")
      .style("top", "-100px");

    this.alertController = this.props.alertController;

    this.RelPrColorLabels = [-1, -0.5, 0, 0.5, 1];
    this.colorScaleRelative = d3.scaleDiverging(t => d3.interpolateRdBu(1 - t))
      // .scaleLinear()
      // .range(["#b40128", "#f9f9d9", "#065e9e"])
      .domain([1, 0, -1]);

    this.PrColorLabels = _.range(0, 10, 1);

    this.colorScalePr = d3.scaleDiverging(t => d3.interpolateYlGnBu(1 - t))
      // .scaleLinear()
      // .range(["#fafdce", "#36abc3", "#2296c1", "#102369"])
      .domain([10, 5, 0]);
    // this.colorScalePr = d3
    //   .scaleLinear()
    //   .range(["#fafdce", "#36abc3", "#2296c1", "#102369"])
    //   .domain([0, 10]);
    this.seasonalMeanPrLineColor = "#1c60a9";
    this.seasonalMeanTasmaxLineColor = "#a8a8a8";
    this.sChartWidth = 550;

    this.drawHeatMap = drawHeatMap;
    this.renderThumbnails = renderThumbnails;
    this.renderLegend = renderLegend;
    this.returnLineChart = returnLineChart;
    this.returnBarchart = returnBarchart;
    this.returnTreemap = returnTreemap;
  }

  componentDidMount() {
    this.drawAxis();
    this.prepareHeatMapData();

    this.renderLegend(this);
    this.drawHeatMap(this);
    this.getSeasonalRegionalMeanData();
    // this.renderThumbnails(this);

    this.getAnnotationMeta();
    this.mapRef.setStateView(this.regionList[this.state.currentView]);

    // [DY] To remove the spinner after 5 seconds later than opening the interface.
    // I do like this as I have no clear idea to remove spinner exactly after calling all the thumbnail images
    // window.setTimeout(()=>this.setState({showSpinner: false}), 5000);
    // when the map is not requesting the data from Tilestache
    window.setTimeout(() => this.setState({ showSpinner: false }), 8000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.currentView !== this.state.currentView ||
      prevState.expand !== this.state.expand
    ) {
      this.eraseVisContainer();

      this.renderVisContainer();
    }

    if (
      prevState.currentView !== this.state.currentView ||
      prevState.expand !== this.state.expand ||
      prevState.relativeHeatmapIntensity !== this.state.relativeHeatmapIntensity
    ) {
      this.removeHeatMap();
      // this.removeThumbnails();
      this.removeLegend();

      this.drawHeatMap(this);

      this.mapRef.setStateView(this.regionList[this.state.currentView]);
    }

    if (this.state.legendContainer == null) {
      this.renderLegend(this);
    }

    if (prevState.currentView !== this.state.currentView) {
      this.mapRef.setStateView(this.regionList[this.state.currentView]);
    }

    if (
      this.state.doVisContainerUpdate === true &&
      this.state.visContainer_currentSelection === this.visContainerElem[0]
    ) {
      this.setState({
        visContainer: this.state.treeMap,
        doVisContainerUpdate: false,
      });
    } else if (
      // prevState.visContainer_barchart != this.state.visContainer_barchart &&
      this.state.doVisContainerUpdate === true &&
      this.state.visContainer_currentSelection === this.visContainerElem[1]
    ) {
      this.setState({
        visContainer: this.state.visContainer_barchart,
        doVisContainerUpdate: false,
      });
    } else if (
      this.state.doVisContainerUpdate === true &&
      // prevState.visContainer_linechart != this.state.visContainer_linechart &&
      this.state.visContainer_currentSelection === this.visContainerElem[2]
    ) {
      this.setState({
        visContainer: this.state.visContainer_linechart,
        doVisContainerUpdate: false,
      });
    }
  }


  getAnnotationMeta = ()=>{
    this.userDataManager
    .getAnnotatedGeoJSON(this.props.modelName)
    .then((res) => {
      this.setState({ annotationOverview: res.data });
      // return res
    });
  };


  parseIndexFileName = (f) => {
    if (!this.applyParsingToIndexFileName) return f;

    let firstIndex = f.indexOf("_") + 1;
    let lastIndex = f.indexOf("_", firstIndex);
    let t = f.substring(firstIndex, lastIndex);
    let date = new Date(
      parseInt(t.substring(0, 4)),
      parseInt(t.substring(4, 6)) - 1,
      parseInt(t.substring(6, 8))
    );
    // let date = new Date(d.getTime() + 96400);
    date.setDate(date.getDate() + 1);
    let monthStr =
      (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
    let dateStr =
      date.getFullYear().toString() +
      monthStr +
      (date.getDate() < 10 ? "0" : "") +
      date.getDate();
    return f.replace(t, dateStr);
  };

  setTreeMap_prev_seasonalLevel = (seasonalLevel) => {
    this.setState({ treeMap_prev_seasonalLevel: seasonalLevel });
  };

  getSeasonalRegionalMeanData = () => {
    this.dataProcessor
      .getSeasonalRegionalMean(
        this.state.seasonalRegionalParameters.seasons,
        this.state.seasonalRegionalParameters.regions,
        this.PredictionModel
      )
      .then((res) => {
        if (res.data) {
          let treeMap = this.returnTreemap(
            this,
            this.setTreeMap_prev_seasonalLevel,
            res.regional_seasonal_data,
            res.data
          );
          let linechart = this.returnLineChart(this, res.data);
          let barchart = this.returnBarchart(this);

          this.setState({
            seasonalRegionalResponseData: res.data,
            regionalSeasonalResponseData: res.regional_seasonal_data,
            treeMap: treeMap,
            visContainer_barchart: barchart,
            visContainer_linechart: linechart,
            visContainer: barchart,
            // visContainer: treeMap,
          });
        }
      });
  };

  prepareHeatMapData = () => {
    let heatData = [];
    let flatYearMonths = _.values(this.HeatData);
    _.forEach(flatYearMonths, (ym) => {
      let flatMonths = _.values(ym);
      heatData = _.concat(heatData, flatMonths);
      // heatData = _.concat(flatMonths, heatData);
    });
    // let heatDataLastIndex = heatData.length-1;
    // this.heatData = _.slice(heatData, heatDataLastIndex-this.startIndex-this.indexLength, heatDataLastIndex-this.startIndex);
    this.heatData = _.slice(heatData, this.hmStartIndex, this.hmStartIndex+this.indexLength);
    // console.log(this.HeatData);
    // console.log(_.values(this.HeatData));
    // console.log(this.heatData);
    // this.heatData = _.reverse(
    //   _.slice(heatData, this.startIndex, this.startIndex + this.indexLength)
    // );
  };

  eraseVisContainer() {
    d3.select(this.visContainerRef).select("svg").remove();
  }

  renderVisContainer() {
    d3.select(this.visContainerRef).select("svg").remove();

    let treeMap = this.returnTreemap(this, this.setTreeMap_prev_seasonalLevel);
    let linechart = this.returnLineChart(this);
    let barchart = this.returnBarchart(this);

    this.setState({
      visContainer: null,
      treeMap: treeMap,
      visContainer_barchart: barchart,
      visContainer_linechart: linechart,
      doVisContainerUpdate: true,
    });
  }

  drawAxis() {
    let height = this.gridHeight;
    let x = d3
      .scaleLinear()
      .range([0, height])
      // .padding(0.2)
      .domain([this.endYear, this.startYear]);

    let svg = this.chartWrapper.getSVG(this.xAxisRef, 20, height, {
      top: 0,
      left: 30,
      bottom: 0,
      right: 5,
    });
    let xAxis = d3
      .axisLeft(x)
      .tickSizeOuter(1)
      .ticks([50])
      .tickFormat(d3.format("d"));

    svg
      .append("g")
      .attr(
        "transform",
        "translate(" + this.thWidth * 0.3 + "," + -this.thHeight / 2 + ")"
      )
      .attr("class", "y-axis")
      .call(xAxis)
      .append("text")
      .style("text-anchor", "end")
      .style("font-weight", "bold")
      .text("Value");
  }

  removeHeatMap = () => {
    d3.select(this.heatmapRef).select("svg").remove();
  };

  removeThumbnails = () => {
    d3.select(this.gridRef).select("div").remove();
  };

  renderRangeSlider = () => {
    // this third party component does not handle `componentDidUpdate`
    let rangeSliderChange = (state, updateFilter) => {
      let heatMapFilter = this.state.heatmapRanges;
      heatMapFilter[updateFilter].max = state.max;
      heatMapFilter[updateFilter].min = state.min;

      this.setState({ heatmapRanges: heatMapFilter }, () => {
        this.removeHeatMap();
        this.drawHeatMap(this);
      });
    };

    return (
      <div>
        <div
          style={{ display: this.state.relativeHeatmapIntensity ? "" : "none" }}
        >
          <RangeSlider
            min={this.default.prHeatMapFilter[1].min*100}
            max={this.default.prHeatMapFilter[1].max*100}
            minRange={this.default.prHeatMapFilter[1].minRange*100}
            onChange={(state) => {
              let s = {
                min: state.min / 100,
                max: state.max / 100
              };
              rangeSliderChange(s, 1);
            }}
            // style={{ transform:"rotate(90)"}}
            step={0.5}
          />
        </div>
        <div
          style={{
            display: !this.state.relativeHeatmapIntensity ? "" : "none",
          }}
        >
          <RangeSlider
            min={this.default.prHeatMapFilter[0].min}
            max={this.default.prHeatMapFilter[0].max}
            minRange={this.default.prHeatMapFilter[0].minRange}
            onChange={(state) => {
              rangeSliderChange(state, 0);
            }}
            step={0.5}
          />
        </div>
      </div>
    );
  };

  renderSpinner(){
    if(!this.state.showSpinner)
      this.setState({showSpinner:"true"}, ()=> window.setTimeout(() => this.setState({ showSpinner: false }), 3000))
  }



  removeLegend() {
    this.setState({ legendContainer: null });
    // d3.select(".heatmap-legend-container").select("svg").remove();
    // document.getElementById("#test-component-exp-legend").innerHTML = "";
  }

  onClickTreemap = () =>
    this.setState({
      visContainer: this.state.treeMap,
      visContainer_currentSelection: this.visContainerElem[0],
    });

  onClickBarchart = () =>
    this.setState({
      visContainer: this.state.visContainer_barchart,
      visContainer_currentSelection: this.visContainerElem[1],
    });

  onClickLinechart = () =>
    this.setState({
      visContainer: this.state.visContainer_linechart,
      visContainer_currentSelection: this.visContainerElem[2],
    });


  render() {
    return (
      <Container
        fluid={"xs"}
        style={{ height: this.canvasHeight, width: this.canvasWidth }} //, overflow: "hidden" }}
      >
        <Row>
          {/* <Spinner2 loading={true}/> */}
          {/* SPINNER */}
          {this.state.showSpinner ? (
            <div
              className="test-component-spinner-outer"
              style={{
                paddingLeft: "40%",
                paddingTop: "20%",
                height: this.canvasHeight,
                width: this.canvasWidth,
              }}
            >
              <ScaleLoader
                className="test-component-spinner"
                color={"#007bff"}
                loading={this.state.showSpinner}
                css={jsx}
                height={150}
                width={20}
                radius={10}
                margin={10}
              />
              {/* 
              <Spinner
                animation="border"
                variant="primary"
                className="test-component-spinner" */}
              {/* /> */}
            </div>
          ) : null}

          {/**/}
          <div>
            <div
              style={{
                width: this.navbarWidth(),
                // height: this.canvasHeight,
                float: "left"
              }}
              className="spatio-tem4poral-vis-container"
            >
              <div className={"side-icon-navigation"} style={{
                // width: this.leftLayoutWidth(),
                height: 40
              }}>
                <HorizontalNavigation
                  height={40}
                  width={this.navbarWidth()}
                  regionalColorScale={this.regionalColorScale}
                  regionAcronyms={this.regionAcronyms}
                  regionIndex={this.regionIndex}
                  currentView={this.state.currentView}
                  onClickRegionAcronyms={(currentIndex) => {
                    this.setState({ showSpinner: true }, () => {
                      this.setState({ currentView: currentIndex }, () =>
                        this.setState({ showSpinner: false })
                      );
                    });
                  }}
                  //expand toggle
                  isExpand={this.state.expand}
                  onClickExpand={() => {
                    this.setState({ expand: !this.state.expand });
                  }}
                  //relative PR
                  isRelativeHeatmapIntensity={this.state.relativeHeatmapIntensity}
                  onClickRelativeHeatmapIntensity={() => {
                    this.setState({
                      relativeHeatmapIntensity: !this.state
                        .relativeHeatmapIntensity,
                    });
                  }}
                  currentModel={this.props.modelLabel}
                />
              </div>
            </div>
            {/* METADATA for the model and selected contour */}
            <div
              className={"geo-spatial-label2"}
              style={{
                width: this.geoSpatialLabelWidth(),
                float: "left"
              }}
            >
              <div
                className={"df fl"}
                style={{ width: this.geoSpatialLabelFieldWidth(5) }}
              >
                <span className={"df-title"}>Variable</span>
                <span className={"df-value"}>Pr</span>
              </div>
              <div
                className={"df fl"}
                style={{ width: this.geoSpatialLabelFieldWidth(2.2) }}
              >
                <span className={"df-title"}>Model</span>
                <span className={"df-value"}><b>{this.props.modelLabel}</b></span>
              </div>
              <div
                className={"df fl"}
                style={{ width: this.geoSpatialLabelFieldWidth(3.5) }}
              >
                <span className={"df-title"}>Index</span>
                <span className={"df-value"}>
                      {this.parseIndexFileName(
                        this.state.selectedIndex.filename
                      )
                        .split(".")[0]
                        .substring(10)}
                    </span>
              </div>
              {/*<div className={"df fl"} style={{width: this.geoSpatialLabelFieldWidth()}}>*/}
              {/*  <span className={"df-title"}>RCP4.5</span>*/}
              {/*</div>*/}
            </div>
          </div>
          {/**/}

          {/* LEFT LAYOUT*/}
          <div
            style={{
              width: this.leftLayoutWidth(),
              height: this.canvasHeight - 50,
            }}
            className="spatio-tem4poral-vis-container"
          >
            <Row
             style={{cursor: "pointer",
             height:this.heatMapHeight*0.1, position:"absolute", marginLeft:"0.8rem", marginTop:"0.3rem", fontWeight:"bold", fontSize:"0.7rem"}}>
              Temporal Heatmap
              {/*<CaretRightFill id={"popover-heatmap"} style={{margin:"auto"}}/>*/}
              <span
                id={"popover-heatmap"}
                style={{paddingLeft: '0.4rem'}}
                onMouseLeave={()=>this.setState({showHeatmapPopover:!this.state.showHeatmapPopover})}
                onMouseOver={()=>this.setState({showHeatmapPopover:!this.state.showHeatmapPopover})}
              ><InfoIcon/></span>

              <Popover
                  placement="right"
                  isOpen={this.state.showHeatmapPopover}
                  target={"popover-heatmap"}
                  className={"title-popover"}
                  //toggle={()=>this.setState({showThumbnailPopover:!this.state.showThumbnailPopover})}
                  >
                    <PopoverHeader>Objective</PopoverHeader>
                    <PopoverBody>
                      Interactive heat map to act as a navigational component for the precipitation contours by clicking the cell.
                      The intensity in the heat map shows by toggling the Relative PR: <br/>
                      1. The comparison between the current month's mean vs historical mean from 2006-2035. <br/>
                      2. The mean precipitation in a month.
                    </PopoverBody>
                    </Popover>

            </Row>
            <Row
              className="region-nav-container"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                // marginBottom: 10,
                height: this.heatMapHeight*1,
                width: "100%",
              }}
            >
              <Col lg={12} md={12} style={{ display: "flex", paddingLeft:"5px" }}>
                <div
                  className={"grid-heatmap fl"}
                  ref={(ref) => (this.heatmapRef = ref)}
                  style={{
                    height: "100%",
                    width: this.gridHeatMapWidth(),
                  }}
                >
                  {" "}
                </div>
                {this.state.legendContainer != null ? (
                  this.state.legendContainer
                ) : (
                  <div
                    id="test-component-exp-legend"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      border: "3px solid #007bff",
                      alignItems: "center",
                      height: this.heatMapHeight,
                      width: this.state.barChartWidth,
                      position: "relative",
                      left: 45,
                      // paddingLeft: "1em",
                      // left: this.state.expand
                      //   ? 10
                      //   : -(this.state.barChartWidth * 3) + 10,
                    }}
                  >
                    {" "}
                  </div>
                )}
              </Col>
            </Row>

            <Row
              onMouseLeave={() => this.setState({showThumbnailPopover: !this.state.showThumbnailPopover})}
              onMouseOver={() => this.setState({showThumbnailPopover: !this.state.showThumbnailPopover})}
              style={{
                cursor: "pointer",
                marginTop: "0.5rem",
                height: this.heatMapHeight * 0.1,
                marginLeft: "0.8rem",
                fontWeight: "bold",
                fontSize: "0.7rem"
              }}>
              Contour Matrix
              <span
                id={"popover-thumbnailGrid"}
                style={{paddingLeft: '0.4rem'}}
                onMouseLeave={() => this.setState({showThumbnailPopover: !this.state.showThumbnailPopover})}
                onMouseOver={() => this.setState({showThumbnailPopover: !this.state.showThumbnailPopover})}
              ><InfoIcon/></span>

              <Popover
                placement="right"
                isOpen={this.state.showThumbnailPopover}
                target={"popover-thumbnailGrid"}
                className={"title-popover"}
              >
                <PopoverHeader>Objective</PopoverHeader>
                <PopoverBody>Spatiotemporal visualization of monthly precipitation contour, grouped by season. A
                  reconfigurable view to individually inspect each region.</PopoverBody>
              </Popover>
            </Row>
            <Row style={{  height: this.thMatrixHeight }}>
              <Col lg={12} md={12} className={"eliminate-padding"} style={{"height": "inherit"}}>
                <div
                  className={"th-grid-vertical scrollbar-round thin"}
                  id={"th-grid-vertical"}
                  style={{ height: this.thMatrixHeight }}
                  ref={(ref) => (this.thGridRef = ref)}
                >
                  <div
                    className={"axis-left"}
                    ref={(ref) => (this.xAxisRef = ref)}
                    style={{ float: "left" }}
                  >
                    {" "}
                  </div>
                  <div
                    className={"g-grid slider-navigator"}
                    style={{ marginLeft: 0, float: "left" }}
                    ref={(ref) => (this.gridRef = ref)}
                  >
                    {/*{this.state.thumbnailChart}*/}
                    {this.renderThumbnails(this)}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            style={{
              width: this.rightChartWidth(),
              position: "relative",
            }}
          >
            {/* RIGHT LAYOUT */}
            {/* TOP VIEW */}
            <Row>
              {/* MAP VISUALIZATION */}
              <Row>
                <GeoSpatial
                  ref={(ref) => (this.mapRef = ref)}
                  // ref={(ref) => this.renderedGeoRefs.push(ref)}
                  height={this.geoSpatialViewHeight}
                  width={this.rightChartWidth() - 10}
                  zoom={3}
                  // mapURL={this.default.layerOptions[this.state.mapLayer]}
                  enableComment={true}
                  regionBorder={true}
                  removeLegend={false}
                  scale={[0, 15]}
                  parseData={true}
                  // label={this.renderLabel(this.state.selectedIndexData)}
                  dataSourceOption={"external"}
                  dataFileName={
                    this.state.selectedIndex.filePrefix +
                    this.parseIndexFileName(this.state.selectedIndex.filename)
                  }
                  setSelectedRegion={(regionName) => {
                    let rIndex = this.regionIndex[regionName];

                    if (rIndex != this.state.currentView) {
                      this.setState({
                        currentView: rIndex,
                        showSpinner: true,
                      });
                      window.setTimeout(
                        () => this.setState({ showSpinner: false }),
                        4000
                      );
                    }
                  }}
                  isExpanded={!this.state.expand}
                  curModel={this.props.modelName}
                  updateAnnotationOverview={(annotationOverview) =>
                    this.setState({ annotationOverview: annotationOverview })
                  }
                  renderSpinner={this.renderSpinner}
                />
              </Row>
            </Row>
            {/* BOTTOM VIEW */}
            <Row>
              {/* DYNAMIC CONTAINER : TIME CHARTS, TREEMAP, BAR CHARTS */}
              <div
                id="testComponentExp-vis-container"
                ref={(ref) => (this.visContainerRef = ref)}
                style={{
                  width: this.rightChartWidth() - 10,
                  height: this.summaryChartHeight(),
                }}
              >
                {this.state.visContainer}
              </div>
            </Row>
          </div>
        </Row>
      </Container>
    );
  }
}

export default TestComponentExp;
