import React from "react";
// import Home from "../../components/views/home";
import DataSelection from "../../components/views/visrec/dataSelection";
import DataTransformation from "../../components/views/visrec/dataTransformation";
import Dashboard from "../../components/views/visrec/visDashbaord";
import ChartCollection from "../../components/views/chartCollection";
import ExploreChart from "../../components/views/exploreCharts";
import DataSets from "../../components/views/Datasets";
import ExploreNetCDF from "../../components/views/exploreNetCDF";
import CompareGeoJSON from "../../components/views/compareGeoJSON";

// import cHome from "../viewConfigs/home";
import cDataSelection from "../viewConfigs/dataSelection";
import cDataTransformation from "../viewConfigs/dataTransformation";
import cVisDashboard from "../viewConfigs/visDashboard";
import cChartCollection from "../viewConfigs/chartCollection";
import cExploreChart from "../viewConfigs/exploreCharts";
import cDataSets from "../viewConfigs/datasets";
import cExploreNetCDF from "../viewConfigs/exploreNetCDF";
import cCompareGeoJSON from "../viewConfigs/exploreGeoJSON";
import TestComponentExp from "../../components/views/TestComponentExp";

// DATA CONFIG
// USE CASE 1
import GISSIndex from "../../data/GISS_E2_R/GISS-index";
import GISSBarData from "../../data/GISS_E2_R/pr_yearly_regional_mean";
import GISSHeatData from "../../data/GISS_E2_R/monthly_regional_mean";
import GISSPRSRM from "../../data/GISS_E2_R/pr_yearly_seasonal_regional_mean";
import GISSTASMAXSRM from "../../data/GISS_E2_R/tasmax_K_yearly_seasonal_regional_mean";
import GISSHistoricalMean from "../../data/GISS_E2_R/historical_regional_seasonal_mean_2006_2035";

// USE CASE 2
import CESM1Index from "../../data/CESM1-CAM5/CESM1-index";
import CESM1BarData from "../../data/CESM1-CAM5/pr_yearly_regional_mean";
import CESM1HeatData from "../../data/CESM1-CAM5/monthly_regional_mean";
import CESM1PRSRM from "../../data/CESM1-CAM5/pr_yearly_seasonal_regional_mean";
import CESM1TASMAXRM from "../../data/CESM1-CAM5/tasmax_K_yearly_seasonal_regional_mean";
import CESM1HistoricalMean from "../../data/CESM1-CAM5/historical_regional_seasonal_mean_2006_2035";

// USE CASE 3
import CESM1RCP26Index from "../../data/CESM1-CAM5-RCP26/CESM1-CAM5-RCP26-index";
import CESM1RCP26BarData from "../../data/CESM1-CAM5-RCP26/pr_yearly_regional_mean";
import CESM1RCP26HeatData from "../../data/CESM1-CAM5-RCP26/monthly_regional_mean";
import CESM1RCP26PRSRM from "../../data/CESM1-CAM5-RCP26/pr_yearly_seasonal_regional_mean";
import CESM1RCP26TASMAXRM from "../../data/CESM1-CAM5-RCP26/tasmax_K_yearly_seasonal_regional_mean";
import CESM1RCP26HistoricalMean from "../../data/CESM1-CAM5-RCP26/historical_regional_seasonal_mean_2006_2035";

// USE CASE 4
import CESM1RCP45Index from "../../data/CESM1-CAM5-RCP45/CESM1-CAM5-RCP45-index";
import CESM1RCP45BarData from "../../data/CESM1-CAM5-RCP45/pr_yearly_regional_mean";
import CESM1RCP45HeatData from "../../data/CESM1-CAM5-RCP45/monthly_regional_mean";
import CESM1RCP45PRSRM from "../../data/CESM1-CAM5-RCP45/pr_yearly_seasonal_regional_mean";
import CESM1RCP45TASMAXRM from "../../data/CESM1-CAM5-RCP45/tasmax_K_yearly_seasonal_regional_mean";
import CESM1RCP45HistoricalMean from "../../data/CESM1-CAM5-RCP45/historical_regional_seasonal_mean_2006_2035";


const mainRoutes = {
  // "": {
  //   path: "/",
  //   component: Home,
  //   renderProps: {
  //     config: cHome
  //   }
  // }
  "": {
    path: "/",
    component: TestComponentExp,
    renderProps: {
      modelName: "CESM1-CAM5",
      modelLabel: "CESM1-CAM5 RCP8.5",
      modelKey: "CESM1-CAM5",
      scenarioSet: ['CESM1-CAM5', 'CESM1-CAM5-RCP45', 'CESM1-CAM5-RCP26'],
      config: cExploreNetCDF,
      Index: CESM1Index,
      HeatData: CESM1HeatData,
      BarData: CESM1BarData,
      PRSRM: CESM1PRSRM,
      TASMAXSRM: CESM1TASMAXRM,
      HistoricalMean: CESM1HistoricalMean,
      applyParsingToIndexFileName: false,

      startYear: 2036,
      endYear: 2086,
      endYearIndex: 100,
      startIndex: 63,
      hmStartIndex: 614,
      indexLength: 600,
    }
  }
  ,"giss-e2-r": {
    path: "/giss-e2-r",
    component: TestComponentExp,
    renderProps: {
      modelName: "GISS-E2-R",
      modelLabel: "GISS-E2-R RCP8.5",
      modelKey: "GISS-E2-R",
      scenarioSet: ['GISS-E2-R'],
      config: cExploreNetCDF,
      Index: GISSIndex,
      HeatData: GISSHeatData,
      BarData: GISSBarData,
      PRSRM: GISSPRSRM,
      TASMAXSRM: GISSTASMAXSRM,
      HistoricalMean: GISSHistoricalMean,
      applyParsingToIndexFileName: true,

      startYear: 2036,
      endYear: 2086,
      endYearIndex: 100,
      startIndex: 590,
      indexLength: 600,
   }
  }
  ,"cesm1-cam5": {
    path: "/cesm1-cam5",
    component: TestComponentExp,
    renderProps: {
      modelName: "CESM1-CAM5",
      modelLabel: "CESM1-CAM5 RCP8.5",
      modelKey: "CESM1-CAM5",
      scenarioSet: ['CESM1-CAM5', 'CESM1-CAM5-RCP45', 'CESM1-CAM5-RCP26'],
      config: cExploreNetCDF,
      Index: CESM1Index,
      HeatData: CESM1HeatData,
      BarData: CESM1BarData,
      PRSRM: CESM1PRSRM,
      TASMAXSRM: CESM1TASMAXRM,
      HistoricalMean: CESM1HistoricalMean,
      applyParsingToIndexFileName: false,

      startYear: 2036,
      endYear: 2086,
      endYearIndex: 100,
      startIndex: 63,
      hmStartIndex: 614,
      indexLength: 600,
   }
  }
  ,"cesm1-cam5-rcp26": {
    path: "/cesm1-cam5-rcp26",
    component: TestComponentExp,
    renderProps: {
      modelName: "CESM1-CAM5-RCP26",
      modelLabel: "CESM1-CAM5 RCP2.6",
      modelKey: "CESM1-CAM5",
      scenarioSet: ['CESM1-CAM5', 'CESM1-CAM5-RCP45', 'CESM1-CAM5-RCP26'],
      config: cExploreNetCDF,
      Index: CESM1RCP26Index,
      HeatData: CESM1RCP26HeatData,
      BarData: CESM1RCP26BarData,
      PRSRM: CESM1RCP26PRSRM,
      TASMAXSRM: CESM1RCP26TASMAXRM,
      HistoricalMean: CESM1RCP26HistoricalMean,
      applyParsingToIndexFileName: false,

      startYear: 2036,
      endYear: 2086,
      endYearIndex: 79,
      startIndex: 363,
      indexLength: 600,
   }
  }
  ,"cesm1-cam5-rcp45": {
    path: "/cesm1-cam5-rcp45",
    component: TestComponentExp,
    renderProps: {
      modelName: "CESM1-CAM5-RCP45",
      modelLabel: "CESM1-CAM5 RCP4.5",
      modelKey: "CESM1-CAM5",
      scenarioSet: ['CESM1-CAM5', 'CESM1-CAM5-RCP45', 'CESM1-CAM5-RCP26'],
      config: cExploreNetCDF,
      Index: CESM1RCP45Index,
      HeatData: CESM1RCP45HeatData,
      BarData: CESM1RCP45BarData,
      PRSRM: CESM1RCP45PRSRM,
      TASMAXSRM: CESM1RCP45TASMAXRM,
      HistoricalMean: CESM1RCP45HistoricalMean,
      applyParsingToIndexFileName: false,

      startYear: 2036,
      endYear: 2086,
      endYearIndex: 79,
      startIndex: 363,
      indexLength: 600,
   }
  }
  ,"exp": {
    path: "/exp",
    component: ExploreNetCDF,
    renderProps: {
      config: cExploreNetCDF
    }
  },
  "datasets": {
    path: "/datasets",
    component: DataSets,
    renderProps: {
      config: cDataSets
    }
  }
  ,"data-selection": {
    path: "/data-selection",
    component: DataSelection,
    renderProps: {
      config: cDataSelection
    }
  }
  ,"data-transformation": {
    path: "/data-transformation",
    component: DataTransformation,
    renderProps: {
      config: cDataTransformation
    }
  }
  ,"vis-recommendation": {
    path: "/vis-recommendation",
    component: Dashboard,
    renderProps: {
      config: cVisDashboard
    }
  }
  ,"charts": {
    path: "/charts",
    component: ChartCollection,
    renderProps: {
      config: cChartCollection
    }
  }
  ,"explore-chart": {
    path: "/explore-chart",
    component: ExploreChart,
    renderProps: {
      config: cExploreChart
    }
  }
  ,"explore-netcdf": {
    path: "/explore-netcdf",
    component: ExploreNetCDF,
    renderProps: {
      config: cExploreNetCDF
    }
  }
  ,"compare-geojson": {
    path: "/compare-geojson",
    component: CompareGeoJSON,
    renderProps: {
      config: cCompareGeoJSON
    }
  }
};

export default mainRoutes;
