import _ from "lodash";
import React from "react";
import "../../stylesheets/charts/lines.css";
import ChartWrapper from "../wrapper/ChartWrapper";
const d3 = require("d3");

export default class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: this.props.width || 960,
      height: this.props.height || 540,
      xDomain: this.props.xDomain || [0, 11],
      yRange: [0, 7],
      xLabel: this.props.xLabel || undefined,
      tickSize: this.props.tickSize || [10, 10],
      lineStrokeWidth: this.props.lineStrokeWidth || 1,
      data: this.props.data || this.prepareDummyData(),
      colorScale: this.props.colorScale || d3.schemeTableau10,
      selectedState: this.props.selectedState,
      removeAxisBottom: this.props.removeAxisBottom || false,
      removeAxisLeft: this.props.removeAxisLeft || false,
      trendline: this.props.trendline && true,
    };
    this.chartWrapper = new ChartWrapper(this, "lineChart", this.props);
    this.margin = this.props.margin || _.cloneDeep(this.chartWrapper.margin);
    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "image-tooltip")
      .style("left", "100px")
      .style("top", "-100px")
      .style("background", "white")
      .style("height", "100px")
      .style("width", "180px");
  }

  componentDidMount() {
    this.createLineChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //[DY] 2021.02.22
    //// Does this container update?
    // if (prevProps.data !== this.props.data) {
    //   this.setState(
    //     {
    //       data: this.props.data,
    //       xDomain: this.props.xDomain,
    //       // yRange: this.props.yRange
    //     },
    //     () => {
    //       this.removeLineChart();
    //       this.createLineChart();
    //     }
    //   );
    // }
  }

  removeLineChart() {
    d3.select(this.lineChart).select("svg").remove();
  }

  focusSingleLine = (index) => {
    d3.selectAll(".line").style("opacity", 0.1);
    d3.select(".line" + index).style("opacity", 0.8);

    d3.selectAll(".trendline").style("opacity", 0.1);
    d3.select(".tl" + index).style("opacity", 0.8);
  };

  restateLinesVisibility = () => {
    d3.selectAll(".line").style("opacity", 0.6);
    d3.selectAll(".trendline").style("opacity", 0.6);
  };

  appendDataPointCircle = (
    svg,
    dataset,
    xScale,
    yScale,
    color,
    c,
    variable
  ) => {

    let lineContainer = svg
      .selectAll(".dot")
      .data(dataset)
      .enter();

      let hiddenBarContainer = svg
      .selectAll(".bar")
      .data(dataset)
      .enter();

    lineContainer
      .append("circle") // Uses the enter().append() method
      .attr("class", (d)=>`dot dot-${d.year}`) // Assign a class for styling
      .attr("cx", function (d, i) {
        // return xScale(d.pr);
        if (d.year) return xScale(d.year);
        return xScale(i);
      })
      .attr("cy", function (d) {
        return yScale(d[variable]);
      })
      .attr("r", 2)
      .style("fill", color)
      .style("opacity", 0.5);

    let margin = this.margin;
    let width = this.state.width - margin.left - margin.right,
      height = this.state.height - margin.top - margin.bottom; // Use the window's height

    hiddenBarContainer
      .append("rect")
      .attr("class", "overlay bar")
      .attr("x", function (d, i) {
        // return xScale(d.pr);
        if (d.year) return xScale(d.year);
        return xScale(i);
      })
      .attr("opacity", 0)
      .attr("y", yScale(7))
      .attr("width", width/dataset.length)
      .attr("height", height)
      .on("mouseover", (d, i) =>  {
        let t_year = d.year;
        lineContainer.select(`.dot-${t_year}`).node().style.fill = color;
        lineContainer.select(`.dot-${t_year}`).attr("r", 2);
        this.tooltip.style("display", null);
      })
      .on("mouseout", (d, i) => {
        let t_year = d.year;
        lineContainer.select(`.dot-${t_year}`).node().style.fill = color;
        lineContainer.select(`.dot-${t_year}`).attr("r", 2);
        this.tooltip.style("display", "none");
      })
      .on("mousemove", (d,i) => {
        // [DY] I have unknown issue why +5 - 100 is required ;
        let t_year = d.year;
        // // circle position - tooltip height - a little margin
        let t_posY =lineContainer.select(`.dot-${t_year}`).node().getBoundingClientRect().top - 100 -5; 

        // console.log(lineContainer.select(`.dot-${t_year}`).node()).style("fill", "red")
        lineContainer.select(`.dot-${t_year}`).node().style.fill =  "red";
        lineContainer.select(`.dot-${t_year}`).attr("r", 4);
        // lineContainer.select(`.dot.dot-${t_year}`)._groups[0].attr("fill", "red"),attr("r", 4)
        //.attr("fill", "red"),attr("r", 4)
        // mouse position - tooltip width - a little margin
        let t_poxX = d3.event.pageX -90;

        this.tooltip.style("opacity", 0.8);

        this.tooltip
          .html(
            "<span><b>Seasonal Regional Mean</b></span><br/>" +
              "<span> Year: " +
              t_year +
              "</span><br/>" +
              "<span> Precipitation: " +
              d.pr +
              // " (kgm<sup>-2</sup>s<sup>-1</sup>)</span><br/>" +
              " (mm/day)</span><br/>" +
              "<span> Max Temp: " +
              d.tasmax +
              " (<sup>o</sup>C)</span><br/>"
          )
          .style("left", (t_poxX) + "px")
          .style("top", t_posY + "px");
      })
  };

  appendTrendLine = (svg, dataset, xScale, yScale, color, c) => {
    let xLabels = _.map(dataset, (d, i) => (d.year ? d.year : i));
    let xSeries = d3.range(1, xLabels.length + 1);
    let ySeries = _.map(dataset, (d) => (d.pr ? d.pr : d));

    let leastSquaresCoeff = this.leastSquares(xSeries, ySeries);

    // apply the results of the least squares regression
    let x1 = xLabels[0];
    let y1 = leastSquaresCoeff[0] + leastSquaresCoeff[1];
    let x2 = xLabels[xLabels.length - 1];
    let y2 = leastSquaresCoeff[0] * xSeries.length + leastSquaresCoeff[1];
    let trendData = [[x1, y1, x2, y2]];

    let trendLine = svg.selectAll(".trendline" + c).data(trendData);

    trendLine
      .enter()
      .append("line")
      .attr("class", "trendline tl" + c)
      .attr("x1", function (d) {
        return xScale(d[0]);
      })
      .attr("y1", function (d) {
        return yScale(d[1]);
      })
      .attr("x2", function (d) {
        return xScale(d[2]);
      })
      .attr("y2", function (d) {
        return yScale(d[3]);
      })
      .style("stroke", "#042249")
      .style("stroke-dasharray", "4, 2")
      .style("opacity", 0.8)
      .style("stroke-width", 2);
  };

  // [DY] 210119 linetrend for tas
  appendTrendLine_tas = (svg, dataset, xScale, yScale, color, c) => {
    let xLabels = _.map(dataset, (d, i) => (d.year ? d.year : i));
    let xSeries = d3.range(1, xLabels.length + 1);
    let ySeries = _.map(dataset, (d) => (d.tasmax ? d.tasmax : d));

    let leastSquaresCoeff = this.leastSquares(xSeries, ySeries);

    // apply the results of the least squares regression
    let x1 = xLabels[0];
    let y1 = leastSquaresCoeff[0] + leastSquaresCoeff[1];
    let x2 = xLabels[xLabels.length - 1];
    let y2 = leastSquaresCoeff[0] * xSeries.length + leastSquaresCoeff[1];
    let trendData = [[x1, y1, x2, y2]];

    let trendLine = svg.selectAll(".trendline" + c).data(trendData);

    trendLine
      .enter()
      .append("line")
      .attr("class", "trendline tl" + c)
      .attr("x1", function (d) {
        return xScale(d[0]);
      })
      .attr("y1", function (d) {
        return yScale(d[1]);
      })
      .attr("x2", function (d) {
        return xScale(d[2]);
      })
      .attr("y2", function (d) {
        return yScale(d[3]);
      })
      .style("stroke", color)
      .style("stroke-dasharray", "4, 2")
      .style("opacity", 0.8)
      .style("stroke-width", 2);
  };

  appendLine = (svg, line, dataset, color, c) => {
    svg
      .append("path")
      .datum(dataset) // 10. Binds data to the line
      .attr("class", "line line" + c) // Assign a class for styling
      .style("stroke", color) // color)
      .style("stroke-width", this.state.lineStrokeWidth)
      .style("opacity", 0.4)
      .attr("d", line);
    // .on("mouseover", (d, i) => this.focusSingleLine(c))
    // .on("mouseout", () => this.restateLinesVisibility());
  };

  prepareDummyData() {
    let resData = [];
    let data = [
      0.68,
      1.16,
      1.45,
      1.51,
      0.7,
      0.93,
      1.04,
      1.05,
      1.27,
      1.3,
      1.33,
      1.15,
      1.05,
      1.3,
      1.11,
      1.45,
      1.02,
      0.78,
      0.91,
      0.98,
      0.96,
      0.9,
      0.98,
      1.04,
      1.12,
      0.77,
      1.0,
      1.34,
      0.82,
      0.85,
      0.97,
      0.66,
      1.09,
      1.33,
      1.47,
      1.25,
      1.29,
      0.64,
      1.62,
      0.76,
      1.25,
      1.66,
      0.88,
      0.85,
      1.2,
      0.93,
      1.15,
      1.62,
      1.42,
      1.09,
      1.27,
      1.1,
      1.35,
      0.78,
      0.69,
      1.44,
      1.37,
      1.4,
      1.2,
      1.32,
    ];
    _.forEach(_.range(5), (i) => {
      let dataset = _.slice(data, i * 12, i * 12 + 12);
      resData.push(dataset);
    });
    return resData;
  }

  createLineChart() {
    let margin = this.margin,
      width = this.state.width - margin.left - margin.right, // Use the window's width
      height = this.state.height - margin.top - margin.bottom; // Use the window's height

    // The number of datapoints
    let yRange = this.state.yRange;

    let colorScale = this.state.colorScale;

    // 5. X scale will use the index of our data
    let xScale = d3.scaleLinear().range([0, width]); // output
    // console.log(this.state.data[0]);
    if (
      this.state.data.length > 0 &&
      this.state.data[0].length > 0 &&
      this.state.data[0][0].year
    )
      xScale.domain(
        d3.extent(this.state.data[0], function (d) {
          return d.year;
        })
      );
    // input
    else xScale.domain([0, 11]);
    // 6. Y scale will use the randomly generate number
    let yScale = d3
      .scaleLinear()
      .domain(yRange) // input
      .range([height, 0]); // output

    // 7. d3's line generator
    let line = d3
      .line()
      .x(function (d, i) {
        if (d.year) return xScale(d.year);
        return xScale(i);
      }) // set the x values for the line generator
      .y(function (d) {
        if (d.pr) return yScale(d.pr);
        return yScale(d);
      }); // set the y values for the line generator
    // .curve(d3.curveMonotoneX); // apply smoothing to the line

    // [DY] 20210119
    // let yRange_tas = d3.extent(this.state.data[0].map((item) => item.tasmax));
    let yRange_tas = [-8, 42];
    let yScale_tas = d3
      .scaleLinear()
      .domain(yRange_tas) // input
      .range([height, 0]); // output

    let line_tas = d3
      .line()
      .x(function (d, i) {
        if (d.year) return xScale(d.year);
        return xScale(i);
      }) // set the x values for the line generator
      .y(function (d) {
        // console.log(yScale_tas(d.tasmax))
        if (d.tasmax) return yScale_tas(d.tasmax);
        return yScale_tas(d);
      });
    // gridlines in x axis function
    function make_x_gridlines() {
      return d3.axisBottom(xScale).ticks(5);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(yScale).ticks(5);
    }

    // 1. Add the SVG to the page and employ #2
    let svg = d3
      .select(this.lineChart)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // if (this.state.axisGrid) {
    // add the X gridlines
    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + height + ")")
      .call(make_x_gridlines().tickSize(-height).tickFormat(""));

    // add the Y gridlines
    svg
      .append("g")
      .attr("class", "grid")
      .call(make_y_gridlines().tickSize(-width).tickFormat(""));
    // }

    if (!this.state.removeAxisBottom) {
      // 3. Call the x axis in a group tag
      svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(
          d3
            .axisBottom(xScale)
            .tickFormat(d3.format(""))
            .ticks([this.state.tickSize[0]])
        ); // Create an axis component with d3.axisBottom
    }

    if (!this.state.removeAxisLeft) {
      // 4. Call the y axis in a group tag
      svg
        .append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(yScale).ticks([this.state.tickSize[1]])); // Create an axis component with d3.axisLeft

      svg
        .append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(" + width + ", 0)")
        .call(d3.axisRight(yScale_tas).ticks([this.state.tickSize[1]])); // Create an axis component with d3.axisLeft
    }

    if (this.state.xLabel) {
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", (width + margin.left + margin.right) / 2)
        .attr("y", height + margin.top)
        // .attr("y", width + (margin.right / 2))
        .attr("dy", ".75em")
        // .attr("transform", "translate(0, " + (height*0.2) + ") rotate(-90)")
        // .attr("transform", "rotate(-90)")
        .style("font-size", "10px")
        .text(this.state.xLabel);
    }

    if (this.props.yLabel) {
      let leftYLabel = this.props.yLabel;
      if (Array.isArray(leftYLabel)) {
        let rightYlabel = leftYLabel[1];
        leftYLabel = leftYLabel[0];

        svg
          .append("text")
          .attr("class", "y label")
          .attr("text-anchor", "end")
          .attr("y", width + margin.right / 2)
          .attr("dy", ".75em")
          .attr("transform", "translate(0, " + height * 0.2 + ") rotate(-90)")
          // .attr("transform", "rotate(-90)")
          .style("font-size", "10px")
          .text(rightYlabel);
      }
      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        // .attr("x", height/2)
        .attr("y", 0 - margin.left)
        .attr("dy", ".75em")
        .attr("transform", "translate(0, " + height * 0.2 + ") rotate(-90)")
        // .attr("transform", "rotate(-90)")
        .style("font-size", "10px")
        .text(leftYLabel);
    }

    // 9. Append the path, bind the data, and call the line generator
    let prColor = "#1c60a9";
    let tasmaxColor = "#656565";
    _.forEach(this.state.data, (dataset, i) => {
      // this.appendLine(svg, line, dataset, colorScale[i], i);
      // this.appendDataPointCircle(svg, dataset, xScale, yScale, colorScale[i], i, "pr");

      this.appendLine(svg, line, dataset, prColor, i);
      this.appendDataPointCircle(
        svg,
        dataset,
        xScale,
        yScale,
        prColor,
        i,
        "pr"
      );

      if (this.state.trendline)
        this.appendTrendLine(svg, dataset, xScale, yScale, prColor, i);

      // [DY] 210119
      this.appendLine(svg, line_tas, dataset, tasmaxColor, i);
      if (this.state.trendline)
        this.appendTrendLine_tas(
          svg,
          dataset,
          xScale,
          yScale_tas,
          tasmaxColor,
          i
        );
    });

    if (this.props.legends) {
      let onMouseOver = (index) => this.focusSingleLine(index);
      let onMouseOut = () => this.restateLinesVisibility();
      // this.chartWrapper.addLegend(svg, this.props.legends, colorScale, width, {
      this.chartWrapper.addLegend(svg, this.props.legends, ["gray"], width, {
        x: 10,
        y: 2,
        colorFunc: null,
        c: 0,
        onMouseOver: onMouseOver,
        onMouseOut: onMouseOut,
      });
    }
    if (this.props.legends2) {
      let colorFunc = (t) => {
        if (t === this.state.selectedState) return "#007bff";
        else return "lightgray";
      };
      let onClick = (t) => {
        this.setState({ selectedState: t });
        this.props.updateLineChartData(t);
      };
      this.chartWrapper.addLegend(
        svg,
        this.props.legends2,
        _.map(this.props.legends2, (d) => "gray"),
        width,
        { x: 10, y: 20, colorFunc: colorFunc, c: 1, onClick: onClick }
      );
    }
  }

  leastSquares = (xSeries, ySeries) => {
    let reduceSumFunc = function (prev, cur) {
      return prev + cur;
    };

    let xBar = (xSeries.reduce(reduceSumFunc) * 1.0) / xSeries.length;
    let yBar = (ySeries.reduce(reduceSumFunc) * 1.0) / ySeries.length;

    let ssXX = xSeries
      .map(function (d) {
        return Math.pow(d - xBar, 2);
      })
      .reduce(reduceSumFunc);

    let ssYY = ySeries
      .map(function (d) {
        return Math.pow(d - yBar, 2);
      })
      .reduce(reduceSumFunc);

    let ssXY = xSeries
      .map(function (d, i) {
        return (d - xBar) * (ySeries[i] - yBar);
      })
      .reduce(reduceSumFunc);

    let slope = ssXY / ssXX;
    let intercept = yBar - xBar * slope;
    let rSquare = Math.pow(ssXY, 2) / (ssXX * ssYY);

    return [slope, intercept, rSquare];
  };

  render() {
    return <div ref={(ref) => (this.lineChart = ref)}></div>;
  }
}
