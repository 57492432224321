import React from "react";
import {
  ChartIcon, ComparisonIcon, DataSelectionIcon,
  DataTransformationIcon, DocumentationIcon, FileUploadIcon,
  HomeIcon,
  RecommendationIcon,
  SettingsIcon
} from "../../utils/IconContainer";

export const sideNavigationItems = [
  {
    name: "home",
    label: "Home",
    pathname: "/",
    icon: HomeIcon
  }
  ,{
    name: "explore-netcdf",
    label: "Explore NetCDF",
    pathname: "/explore-netcdf/",
    icon: RecommendationIcon
  }
  ,{
    name: "compare-geojson",
    label: "Compare GeoJSON",
    pathname: "/compare-geojson/",
    icon: ComparisonIcon
  }
  // ,{
  //   name: "data-selection",
  //   label: "Dataset Selection",
  //   pathname: "/data-selection/",
  //   icon: DataSelectionIcon
  // }
  // ,{
  //   name: "data-transformation",
  //   label: "Data Transformation",
  //   pathname: "/data-transformation/",
  //   icon: DataTransformationIcon
  // }
  // ,{
  //   name: "visrec",
  //   label: "Vis Recommendation",
  //   pathname: "/vis-recommendation/",
  //   icon: RecommendationIcon
  // }
];

export const documentationNavItems = [
  {
    name: "charts",
    label: "Charts",
    pathname: "/charts/",
    icon: ChartIcon
  }
  ,{
    name: "documentation",
    label: "Documentation",
    pathname: "/documentation/",
    icon: DocumentationIcon
  }
];

export const settingsNavItems = [
  {
    name: "datasets",
    label: "Datasets",
    pathname: "/datasets/",
    icon: FileUploadIcon
  }
  ,{
    name: "settings",
    label: "Settings",
    pathname: "/settings/",
    icon: SettingsIcon
  }
];
