import React from "react";
import sampleData from "../../data/sample-horizontal-stacked-bar-chart";
import ChartWrapper from "../wrapper/ChartWrapper";
import _ from "lodash";

const d3 = require("d3");
class HorizontalStackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartWrapper = new ChartWrapper(this, "bar-chart", this.props);
    this.default = {
      data: sampleData,
      margin: _.cloneDeep(this.chartWrapper.margin)
    };
    this.state = {
      data: this.props.data || this.default.data,
      width: this.props.width || 920,
      height: this.props.height || 540,
      xRange: this.props.xRange || [0, 16],
      yDomain: this.props.yDomain || _.range(1985, 2099, 1),
      colorScale: this.props.colorScale || ["steelblue", "darkorange", "lightblue", "#01b6cb"]
    };

    this.tooltip = d3.select("body").append("div")
      .attr("class", "image-tooltip")
      .style("background", 'white')
      .style("left","100px")
      .style("top", "-100px")
      .style("height", "90px")
      .style("width", "150px");

    this.margin = this.default.margin;
    this.margin.top = 20;
    this.margin.right = 15;
    this.margin.left = 35;
    this.margin.bottom = 30;
  }

  componentDidMount() {
    this.createBarChart();
  }

  removeChart = () => {
    d3.select(this.graph).select("svg").remove();
    d3.select(this.graphXAxis).select("svg").remove();
  };

  createBarChart = () => {
    // let speed = 0;
    // let barHeight = 10;
    let tooltip = this.tooltip;
    let groupData = this.state.data;
    let movingAverages = [];
    let seasons = ['s0', 's1', 's2', 's3'];
    // let seasonLabels = ['Spring', 'Summer', 'Fall', 'Winter'];
    let quartileOpacityMap = {Lower: 0.2, Second: 0.5, Third: 0.8, Upper: 1};
    let inverseQuartileMap = _.invert(quartileOpacityMap);
    let seasonColors = this.state.colorScale;

    let margin = this.margin,
      width = this.state.width - margin.left - margin.right,
      height = this.state.height - margin.top - margin.bottom;

    let seasonQuantiles = {};
    _.forEach(_.range(0, seasons.length, 1), s => {
      let seasonData = _.map(groupData, d => d['s'+s]);
      seasonQuantiles['s'+s] = d3.scaleQuantile().range(_.values(quartileOpacityMap)).domain(seasonData);
    });

    // let chartHeight = barHeight*114; // barHeight * numberOfYears
    let chartHeight = height;
    let x  = d3.scaleBand().range([0, width]).padding(0.2).domain(this.state.yDomain);
    let z = d3.scaleOrdinal()
      .range(seasonColors)
      .domain(seasons);

    let chartMargin = _.clone(margin);
    // chartMargin.top = 5;
    let svg = this.chartWrapper.getSVG(this.graph, width, chartHeight, chartMargin);

    // this.chartWrapper.addLegend(svg, seasonLabels, seasonColors, width);

    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "x axis")
      .call(d3.axisBottom(x).tickValues(_.concat(_.range(2098, 1990, -15), [1985])))
      .append("text")
      .attr("y", x.bandwidth())
      .style("text-anchor", "end")
      .style('font-weight','bold')
      .text("Value");

    let axisHeight = chartHeight / seasons.length;
    _.forEach(_.range(0, seasons.length, 1), i => {
      let yScaleMin = axisHeight * i;
      let yScaleMax = axisHeight * (i+1);

      let y = d3.scaleLinear().range([yScaleMin, yScaleMax]).domain([4, 0]);
      svg.append("g")
        .attr("width", 25)
        .attr("height", axisHeight)
        .append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(y).tickValues(_.range(0, seasons.length, 1)));

      svg.selectAll(".bar bar"+i)
        .data(groupData)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", function(d) { return x(d.year); })
        .attr("width", x.bandwidth())
        .attr("y", function(d) { return y(d['s'+i]); })
        .attr("height", function(d) { return yScaleMax - y(d['s'+i]); })
        .style("fill", z('s'+i))
        .on("mouseover", function (d, index) {
          let season = 's'+i;
          tooltip.transition()
            .duration(200)
            .style("opacity", .9);

          tooltip.html("<span> Year: " + d.year + "</span><br/>" +
            "<span> Precipitation: " + d[season].toFixed(2) + "</span><br/>" +
            "<span> Moving Avg: " + movingAverages[index].toFixed(2) + "</span><br/>" +
            "<span>" + inverseQuartileMap[seasonQuantiles[season](d[season])] + " Quarter</span>"
          )
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px");
        })
        .on("mouseout", (d) => {
          tooltip.transition()
            .duration(500)
            .style("opacity", 0);
        })
        .style("opacity", function (d) {
          let season = 's'+i;
          let value = d[season];
          return seasonQuantiles[season](value);
        });

      let starting_from = 5;
      let line = d3.line()
        .x(function (d) {
          return x(d.year);
        }) // set the x values for the line generator
        .y(function (d, index) {
          let base = i * seasons.length;
          let from_index = starting_from + index-4;
          let to_index = starting_from + index;
          if (from_index >= 0 && to_index <= groupData.length) {
            let subset = _.map(_.slice(groupData, from_index, to_index), d => d['s'+i]);
            let ma = _.mean(subset);
            movingAverages.push(ma);
            return y(ma);
          }
          movingAverages.push(base);
          return y(base);
        }); // set the y values for the line generator

      svg.append("path")
        .datum(_.slice(groupData, starting_from))
        .attr("class", "line line"+i)
        .attr("transform", "translate(" + (x.bandwidth()/2) + ", " + 0 + ")")
        .style('stroke', "black")
        .style('stroke-width', 2)
        .style('opacity', 0.5)
        .attr("d", line);
    });
    svg.append('line')
      .style("stroke", "black")
      .style("stroke-width", 2)
      .style('opacity', 0.5)
      .attr("x1", width - 90)
      .attr("y1", 0)
      .attr("x2", width - 75)
      .attr("y2", 0);

    svg.append('text')
      .attr("x", width - 70)
      .attr("y", 3)
      .text("Moving Avg")
      .attr("class", "textselected")
      .style("text-anchor", "start")
      .style("font-size", 11);
  };

  render() {
    return(
      <div>
        <div id={"horizontal-grouped-bar-chart-xaxis"} ref={(ref) => (this.graphXAxis = ref)}> </div>
        <div id={"horizontal-grouped-bar-chart"}
             ref={(ref) => (this.graph = ref)}
             style={{height: this.state.height, overflowY: "auto"}}
        > </div>
      </div>
    )
  }
}

export default HorizontalStackedBarChart;