import React from 'react';

class DimensionParser {
  //asumes that all data is json formatted
  constructor(data, onSelect, type){
    this.dims = this.getDims(data, type);
    this.onSelect = onSelect;


  }

  getDims(data){
    if(data.length <1){return [];}
    return Object.getOwnPropertyNames(data[0]);
  }
  /*
    data = incoming data
    ind = index of where name property is at
   */
  getNames(data, ind){

    if(data.length <1){return [];}
    let names = [];
    for(var i= 0; i <data.length; i++ ){
      names.push( data[i][ind] );
    }
    return names;

  }


  mapColumns(){
    const items = this.dims.map(function(d,i){
      return {
        title: d,
        width: 120,
        data: d,
      }});
    return items;

  }
}

export default DimensionParser;
