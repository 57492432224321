import _ from "lodash";
import boxPlotData from "../../data/boxPlotData";
import ChartWrapper from "../wrapper/ChartWrapper";

const React = require("react");
const d3 = require('d3');

class BoxPlot extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data || boxPlotData,
            headerW: this.props.width,
            height: this.props.height,
            width: this.props.width,
            display: true,
            defaultProps: {
                strokeColor: "#4b4b4b",
                strokeWidth: 1,
                barWidth: 30
            }
        };

        this.chartWrapper = new ChartWrapper(this, "box-plot", this.props);
        this.margin = _.cloneDeep(this.chartWrapper.margin);

        this.createBoxPlot = this.createBoxPlot.bind(this);
        this.removeBoxPlot = this.removeBoxPlot.bind(this);
    }

    componentDidMount() {
        this.createBoxPlot();
    }

    componentDidUpdate() {
        this.removeBoxPlot();
        this.createBoxPlot();
    }

    removeBoxPlot() {
        d3.select(this.graph).select("svg").remove();
    }

    createBoxPlot() {
        let margin = this.margin;
        let width = this.state.width - margin.left - margin.right;
        let height = this.state.height - margin.top - margin.bottom;
        let strokeColor = this.state.defaultProps.strokeColor;
        let strokeWidth = this.state.defaultProps.strokeWidth;
        let barWidth = this.state.defaultProps.barWidth;

        // START => REPLACE BY RETRIEVED DATA
        let globalCounts = [];
        let groupCounts = {};
        _.forEach(this.state.data, function (item, index) {
            let counts = item.counts;
            globalCounts = globalCounts.concat(counts);
            groupCounts[index] = counts.sort(sortNumber);
        });
        // END => REPLACE BY RETRIEVED DATA

        // Compute an ordinal xScale for the keys in boxPlotData
        let xScale = d3.scalePoint()
            .domain(Object.keys(groupCounts))
            .rangeRound([0, width])
            .padding([0.5]);

        // Compute a global y scale based on the global counts
        let min = d3.min(globalCounts);
        let max = d3.max(globalCounts);
        let yScale = d3.scaleLinear()
            .domain([min, max])
            .range([height, 0]);

        // append the svg obgect to the body of the page
        // appends a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        let svg = d3.select(this.graph).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // append a group for the box plot elements
        let g = svg.append("g");

        // Draw the box plot vertical lines
        let verticalLines = g.selectAll(".verticalLines")
            .data(this.state.data)
            .enter()
            .append("line")
            .attr("x1", function(datum) { return xScale(datum.key); })
            .attr("y1", function(datum) { return yScale(datum.whiskers[0]); })
            .attr("x2", function(datum) { return xScale(datum.key); })
            .attr("y2", function(datum) { return yScale(datum.whiskers[1]); })
            .attr("stroke", strokeColor)
            .attr("stroke-width", strokeWidth)
            .attr("fill", "none");

        // Draw the boxes of the box plot, filled and on top of vertical lines
        let rects = g.selectAll("rect")
            .data(this.state.data)
            .enter()
            .append("rect")
            .attr("width", barWidth)
            .attr("height", function(datum) {
                let quartiles = datum.quartile;
                let height =  yScale(quartiles[0]) - yScale(quartiles[2]);
                return height;
            })
            .attr("x", function(datum) { return xScale(datum.key) - (barWidth/2); })
            .attr("y", function(datum) { return yScale(datum.quartile[2]); })
            .attr("fill", function(datum) { return datum.color; })
            .attr("stroke", strokeColor)
            .attr("stroke-width", strokeWidth);

        // Now render all the horizontal lines at once - the whiskers and the median
        let horizontalLineConfigs = [
            // Top whisker
            {
                x1: function(datum) { return xScale(datum.key) - barWidth/2 },
                y1: function(datum) { return yScale(datum.whiskers[0]) },
                x2: function(datum) { return xScale(datum.key) + barWidth/2 },
                y2: function(datum) { return yScale(datum.whiskers[0]) }
            },
            // Median line
            {
                x1: function(datum) { return xScale(datum.key) - barWidth/2 },
                y1: function(datum) { return yScale(datum.quartile[1]) },
                x2: function(datum) { return xScale(datum.key) + barWidth/2 },
                y2: function(datum) { return yScale(datum.quartile[1]) }
            },
            // Bottom whisker
            {
                x1: function(datum) { return xScale(datum.key) - barWidth/2 },
                y1: function(datum) { return yScale(datum.whiskers[1]) },
                x2: function(datum) { return xScale(datum.key) + barWidth/2 },
                y2: function(datum) { return yScale(datum.whiskers[1]) }
            }
        ];

        for(let i=0; i < horizontalLineConfigs.length; i++) {
            let lineConfig = horizontalLineConfigs[i];

            // Draw the whiskers at the min for this series
            let horizontalLine = g.selectAll(".whiskers")
                .data(this.state.data)
                .enter()
                .append("line")
                .attr("x1", lineConfig.x1)
                .attr("y1", lineConfig.y1)
                .attr("x2", lineConfig.x2)
                .attr("y2", lineConfig.y2)
                .attr("stroke", strokeColor)
                .attr("stroke-width", strokeWidth)
                .attr("fill", "none");
        }

        // Move the left axis over 25 pixels, and the top axis over 35 pixels
        //let axisY = svg.append("g").attr("transform", "translate(25,0)");
        //let axisX = svg.append("g").attr("transform", "translate(35,0)");

        //x-axis
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .attr("class", "axis axis--x")
            .call(d3.axisBottom(xScale).tickFormat(d => "Class " + d));

        // Add the Y Axis
        svg.append("g")
            .attr("class", "axis  axis--y")
            .call(d3.axisLeft(yScale));

        // function boxQuartiles(d) {
        //     return [
        //         d3.quantile(d, .25),
        //         d3.quantile(d, .5),
        //         d3.quantile(d, .75)
        //     ];
        // }

        // Perform a numeric sort on an array
        function sortNumber(a,b) {
            return a - b;
        }
    }

    render() {
        const chartClassName = "chart boxplot resizable ui-widget-content ui-resizable ui-draggable ui-draggable-handle"
            + " " + this.props.eClassName;

        return(
            <div>
                {/*{this.chartHeaderController.getChartHeader(this.state.headerW)}*/}

                <div
                    className={chartClassName}
                    style={{
                        height: this.state.height,
                        width: this.state.width,
                        display: this.state.display
                    }}
                >
                    <div ref={graph => this.graph = graph}
                         style={{
                             width: (this.state.width),
                             height: (this.state.height),
                         }}
                    >
                    </div>

                </div>
            </div>
        );
    }
}

export default BoxPlot;
