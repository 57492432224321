import _ from "lodash";

import transferLearningCM from "../../data/TransferredPerformanceCM.csv";
// import Legend from "../common/parts/charts/legend";
import'../../stylesheets/charts/axis.css';
// import'./../StyleSheets/general-map.css';
import'../../stylesheets/charts/confusionMatrix.css';

// import 'bootstrap3/dist/css/bootstrap.css';
import DimensionParser from "../../utils/DimensionParser";
import ChartWrapper from "../wrapper/ChartWrapper";

let React = require('react');
let d3 = require("d3");

class Tabled extends React.Component {

    constructor(props){
        super(props);
        this.ref = this.null;
        this.onDelete = props.onDelete;
        this.sign = props.sign;
        this.node = null;
        this.parentRef = props.parentRef;
        this.data = [];
        this.dataRowCount = 0;

        this.matrixType = {
            "triangleMatrix": "triangle",
            "confusionMatrix": "confusion"
        };

        this.state = {
            dimension: new DimensionParser(this.props.data || []),
            maxDims: Number.MIN_SAFE_INTEGER,
            minDims: Number.MAX_SAFE_INTEGER,
            color: "green",
            title: "Test Title",
            legend: false,
            display: true,
            headerW: this.props.width,
            width: this.props.width,
            height: this.props.height,

            data: this.props.data || [],
            dataColumns: this.props.dataColumns || [],
            xLabel: this.props.xLabel || "Predicted Label",
            colorMapRange: this.props.colorMapRange || ["white", "#1e88e5"],
            domainRange: this.props.domainRange || [],
            matrixType: this.matrixType[this.props.type] || this.matrixType.confusionMatrix
        };

        this.updatePropsOnComponentUpdate = ['data', "dataColumns", "xLabel", "colorMapRange", "domainRange"];

        this.state.x = this.state.dimension.dims[0];
        this.state.y = this.state.dimension.dims[1];

        this.chartWrapper = new ChartWrapper(this, "table", this.props);
        this.margin = _.cloneDeep(this.chartWrapper.margin);

        //bind functions
        this.createTable = this.createTable.bind(this);
        this.removeConfusionMatrix = this.removeConfusionMatrix.bind(this);
    }

    readCSVFile = () => {
        let dataCount = 0;
        let dataSet = [];
        d3.csv(transferLearningCM, (data, err) => {
            if (this.dataRowCount <= dataCount) {
                dataSet.push(data);
                dataCount++;
            }
        }).then(data => {
            let maxDims = this.getMaxOfDims(data.columns, dataSet);
            let minDims = this.getMinOfDims(data.columns, dataSet);

            this.setState({
                dataColumns: data.columns,
                data: dataSet,
                maxDims: maxDims,
                minDims: minDims,
                domainRange: [minDims, maxDims]
            });
        });

        if (this.dataRowCount <= dataCount)
            this.dataRowCount = dataCount;
    };

    componentWillMount() {
        this.setMinMaxDims();
    }

    componentDidMount() {
        if (!this.props.dataColumns)
            this.readCSVFile();
        // this.$graph = $(this.graph);

        this.createTable();
        // this.setState({
        //     legend:true,
        // });
    }

    componentDidUpdate(prevProps, prevState, ss) {
        this.removeConfusionMatrix();
        // this.chartHeaderController.updateStateFromProps(this.props, this.updatePropsOnComponentUpdate);
        this.setMinMaxDims();
        this.createTable();
    }

    setMinMaxDims() {
        if (this.props.dataColumns && this.props.data) {
            this.state.dimension = new DimensionParser(this.props.data || []);
            this.state.minDims = this.props.domainRange[0];
            this.state.maxDims = this.props.domainRange[this.props.domainRange.length-1];
        }
    }

    removeConfusionMatrix() {
        d3.select(this.graph).select('svg').remove();
    }

    createTable() {
        this.removeConfusionMatrix();

        // if (typeof this.state.data ===)

        let dims = this.state.dataColumns;
        let reverse_dims = dims;

        if (this.state.matrixType === this.matrixType.triangleMatrix)
            reverse_dims = _.reverse(_.clone(dims));

        let tempdata = this.state.data;
        let domain = this.state.domainRange || [1, this.state.maxDims];

        const width = this.state.width;
        const height = this.state.height;
        const graph_width = width - this.margin.left  - this.margin.right;
        const graph_height = height - this.margin.top  - this.margin.bottom;

        this.node = d3.select(this.graph).append('svg').attr("class", "table").attr("height", height).attr("width", width);

        this.node = this.node.append("g")
            .attr('class', 'tableContainer')
            .attr("transform", "translate(" + (this.margin.left) + "," + this.margin.top + ")");

        this.numrows = tempdata.length;
        let numrows = this.numrows;

        let x = d3.scaleBand()
            .rangeRound([0, graph_width])
            .domain(dims);

        let y = d3.scaleBand()
            .rangeRound([0, graph_height]).domain(reverse_dims);

        this.colorMap = d3.scaleLinear().domain(domain)
            .range(this.state.colorMapRange);
        let colormap = this.colorMap;

        this.row = this.node.selectAll('.row')
            .data(tempdata)
            .enter().append('g')
            .attr("class", "row")
            .attr('transform', function (d, i) {
                return 'translate(0,' + y(dims[i]) + ')';
            });

        this.cell = this.row.selectAll(".cell")
            .data(tempdata)
            .enter().append('g')
            .attr('class', "cell")
            .attr("transform", function (d, i) {
                return "translate(" + x(dims[i]) + ", 0)";
            });
        let  it  = 0;
        let trueit = 0;
        let bucket = [];
        this.rect = this.cell.append('rect')
            .attr('width', x.bandwidth())
            .attr('height', y.bandwidth())
            .style('stroke-width', 0.2)
            .style('stroke-opacity', 0.7)
            .style('stroke', "black")
            .style('fill', (d, i) => {
                let ans =  colormap(d[dims[trueit]]);

                if (this.state.matrixType === this.matrixType.triangleMatrix) {
                    let pDim = dims[trueit], cDim = dims[i];

                    if (pDim === cDim || _.indexOf(bucket, pDim+cDim) === -1) {
                        bucket.push((pDim+cDim));bucket.push((cDim+pDim));
                    }
                    else {
                        ans = "#f6f6f6";
                    }
                }
                it = Math.floor((i+1)/ numrows);
                if(it === 1 ) trueit++;
                return ans;

            });

        it  = 0;
        trueit = 0;
        bucket = [];
        this.recttext = this.cell.append("text")
            .attr("class", "cell_label")
            .attr("dy", ".32em")
            .attr("x", x.bandwidth() / 2)
            .attr("y", y.bandwidth() / 2)
            .attr("text-anchor", "middle")
            // .style("fill", function(d, i) { return d >= 0.5 ? 'white' : 'black'; })//also fix color scales here
            .text((d, i) => {
                let ans =  d[dims[trueit]];

                if (typeof ans === "string")
                    ans = parseFloat(ans);

                // TO AVOID ERROR >> RESULTS WRONG PLOT
                else if (ans === undefined || ans === null)
                    ans = 0;

                ans = ans.toFixed(2);
                if (this.state.matrixType === this.matrixType.triangleMatrix) {
                    let pDim = dims[trueit], cDim = dims[i];

                    if (pDim === cDim || _.indexOf(bucket, pDim+cDim) === -1) {
                        bucket.push((pDim+cDim));bucket.push((cDim+pDim));
                    }
                    else {
                        ans = "";
                    }
                }
                it = Math.floor((i+1)/ numrows);
                if(it === 1 ) trueit++;
                return ans;
            });

        this.labels = this.node.append('g')
            .attr('class', 'labels');


        this.columnLabels = this.labels.selectAll(".column-label")
            .data(dims)
            .enter().append("g")
            .attr("class", "column-label")
            .attr("transform", function(d, i) { return "translate(" + x(dims[i]) + "," + graph_height + ")"; });

        this.columnLabelsText = this.columnLabels.append("text")
            .attr("class", "axis axis--x")
            .attr("x", x.bandwidth()/2)
            .attr("y", 10)
            // .attr("dy", ".32em")
            // move this to the css
            .attr("text-anchor", "middle")
            // .attr("transform", "rotate(-60)")
            .text(function(d, i) { return d; });

        this.xlabel = this.labels.append("g")
            .attr("class", "axis axis--x")
            .append('text')
            .attr("x",  width / 2)
            .attr("y",  graph_height + 30)
            // .attr("dy", ".16em")
            .attr("text-anchor", "end")
            .text(this.state.xLabel);

        this.rowLabels = this.labels.selectAll(".row-label")
            .data(reverse_dims)
            .enter().append("g")
            .attr("class", "row-label")
            .attr("transform", function(d, i) { return "translate(" + 0 + "," + y(reverse_dims[i]) + ")"; });

        /*this.rowLabels.style("stroke", "black")
            .style("stroke-width", "1px")
            .attr("x1", 0)
            .attr("x2", -5)
            .attr("y1", y.bandwidth() / 2)
            .attr("y2", y.bandwidth() / 2);*/

        this.rowText = this.rowLabels.append("text")
            .attr("class", "axis axis--y")
            .attr("x", -8)
            .attr("y", y.bandwidth() / 2)
            .attr("dy", ".32em")
            .attr("text-anchor", "end")
            .text(function(d, i) { return d; });
        //
    }

    getMaxOfDims(dims, data) {
        dims = dims || this.state.dimension.dims;
        data = data || this.data;
        let ans = Number.MIN_SAFE_INTEGER;
        let length = dims.length;

        data.map(function (d) {
            for (let i = 0; i < length; i++) {
                let n = parseInt(d[dims[i]]);
                if (ans < n) {
                    ans = n;
                }
            }
        });
        return ans;
    }

    getMinOfDims(dims, data) {
        dims = dims || this.state.dimension.dims;
        data = data || this.data;
        let ans = Number.MAX_SAFE_INTEGER;
        let length = dims.length;

        data.map(function (d) {
            for (let i = 0; i < length; i++) {
                let n = parseInt(d[dims[i]]);
                if (ans > n) {
                    ans = n;
                }
            }
        });
        return ans;
    }

    setLegend() {
        return (<div></div>);
    }

    render() {
        const chartClassName = "chart confusion_matrix resizable ui-widget-content ui-resizable ui-draggable ui-draggable-handle "
            + " " + this.props.eClassName;

        return (
            <div>
                {/*{this.chartHeaderController.getChartHeader(this.state.headerW + 13)}*/}
                <div
                    className={chartClassName}
                    style= {{height : this.state.height, width : this.state.width, display: this.state.display }}
                    ref = {el => this.el = el}>

                    {this.state.legend && this.setLegend()}

                    <div ref = {graph => this.graph = graph}
                         style= {{height : this.state.height, width : this.state.width}}
                         className={"graph-child table"}>
                    </div>
                </div>
            </div>
        )
    }
}

export default Tabled;
