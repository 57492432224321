import React from "react";
import L from "leaflet";

const AnnotationIcon = new L.Icon({
  iconUrl: require('../images/iconfinder_interface-solid-pin_6125236.svg'),
  
  // iconRetinaUrl: require('../images/comment.svg'),
  // iconRetinaUrl: require('../images/comment.svg'),
  iconSize: [20,20],
  iconAnchor: [10, 16],
  popupAnchor: [0, -15],
  // tooltipAnchor: [16, -28],
  // popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  // iconSize: new L.Point(60, 75),
  // className: 'leaflet-div-icon'
  // iconUrl: require('../images/comment.png'),
  // iconSize: [64,64],
  // iconAnchor: [32, 64],
  // popupAnchor: null,
  // shadowUrl: null,
  // shadowSize: null,
  // shadowAnchor: null
});

export default AnnotationIcon;
