import _ from "lodash";
import React from "react";
import { Row, Col } from "react-bootstrap";
import GeoSpatial from "../charts/GeoSpatial";
import SliderNavigator from "../navigation/SliderNavigator";
import "../../stylesheets/views/exploreNetCDF.css";
import SummaryCharts from "./sections/summaryCharts";
import MapViewController from "./mapViewController";
import ManageDataSet from "../../requestProcessors/manageDataSets";


class ExploreNetCDF extends React.Component {
  constructor(props) {
    super(props);

    this.canvasWidth = this.props.canvasWidth;
    this.canvasHeight = this.props.canvasHeight;

    // geo-spatial
    this.contentGap = 15;
    this.geoSpatialFrameHeight = this.canvasHeight - this.contentGap;
    this.chartWidth = this.canvasWidth-this.canvasWidth*(4/12)-this.contentGap;
    this.chartHeight = this.geoSpatialFrameHeight*0.85;
    this.controlWidth = this.chartWidth;
    this.controlHeight = this.geoSpatialFrameHeight*0.15;

    // summary charts
    this.sChartWidth = this.canvasWidth-this.canvasWidth*(8/12)-this.contentGap;
    this.sFrameHeight = this.canvasHeight - 20; // for chart margins;

    this.monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // this.filePrefix = "user_data/pr_amon_BCSD_rcp85_r1i1p1_CONUS_ACCESS1-0_208601-209012/";
    this.default = {
      geoSpatialViews: {
        single: "single",
        comparison: "comparison",
        multi: "multi",
      },
      layerOptions: {
        Standard: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        WikiMedia: "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png",
        Humanitarian: "http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        "OSM France": "http://a.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
      },
    };

    this.dataProcessor = new ManageDataSet();
    this.startIndex = 1; // first one
    this.secondIndex = 2;

    this.renderedGeoRefs = [];

    this.state = {
      datasetList: {},
      selectedDataset: {},
      dataColumns: [],
      timeStamps: [],
      selectedIndexData: null, // TODO replace with comparison view indices
      selectedSecondIndexData: null, // TODO replace with comparison view indices
      chartWidth:
        this.canvasWidth - this.canvasWidth * (4 / 12) - this.contentGap,
      chartHeight: this.canvasHeight * 0.78,
      controlWidth:
        this.canvasWidth - this.canvasWidth * (4 / 12) - this.contentGap,
      controlHeight: this.canvasHeight * 0.19 - this.contentGap,
      geoSpatialView: this.default.geoSpatialViews.single,
      mapLayer: "Standard",
      comparisonViewIndices: [1, 2],
      multiViewIndices: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      // dataDimensions: []

      //[DY] 2021.02.02
      selectedRegion: null,
      selectedSeason: null,
      doReordering: false
    };

    this.alertController = this.props.alertController;
  }

  componentDidMount() {
    this.dataProcessor.getTimeStampIndex().then((data) => {
      this.setState({
        timeStamps: data.index,
        selectedIndexData: data.index[this.startIndex],
        selectedSecondIndexData: data.index[this.secondIndex],
      });
    });
    // this.setState({
    //   timeStamps: DummyIndexData,
    //   selectedIndexData: DummyIndexData[this.startIndex],
    //   selectedSecondIndexData: DummyIndexData[this.secondIndex],
    // });
  }

  //[DY] 2021.02.02
  setSelectedRegion = (region) => {

    // if hierarhi order is season first and region next then selectedSeason should be filled.
    this.setState({
      selectedSeason: null,
      selectedRegion: region,
      doReordering: true,
    });
    // this.setState({ selectedRegion: region, doReordering: true });
  };
  //[DY] 2021.02.02
  setSelectedSeason = (season) => {
    this.setState({ selectedSeason: season, doReordering: true });
  };
  //[DY] 2021.02.02
  setSelectedSeason_Region = (season, region) => {
    this.setState({
      selectedSeason: season,
      selectedRegion: region,
      doReordering: true,
    });
  };

  getTimeLineInfo = (indexData) => {
    if (indexData) {
      let datetime = new Date(indexData.time);
      return (
        indexData.index +
        ". " +
        indexData.filename +
        " - " +
        this.monthNames[datetime.getMonth()] +
        ", " +
        datetime.getFullYear()
      );
    }
    return "";
  };

  getInfoByIndex = (index) => {
    return this.getTimeLineInfo(this.state.timeStamps[index]);
  };

  onDataSetChange = (index) => {
    this.setState({
      geoSpatialView: this.default.geoSpatialViews.single,
      selectedIndexData: this.state.timeStamps[index],
    });
  };

  changeMapURL = (label, url) => {
    this.setState({ mapLayer: label });
    _.forEach(this.renderedGeoRefs, (geoView) => {
      if (geoView !== null) geoView.mapURLChange(url);
    });
  };

  changeGeoSpatialView = (e, viewType) => {
    this.setState({ geoSpatialView: viewType });
    _.forEach(e.target.parentNode.childNodes, (n) => {
      n.classList.remove("btn-secondary");
      n.classList.remove("btn-primary");
      n.classList.add("btn-secondary");
    });
    e.target.classList.remove("btn-secondary");
    e.target.classList.add("btn-primary");
  };

  renderLabel = (indexData) => {
    return indexData ? (
      <div className={"md"}>
        <span className={"md-title"}>PRCP</span>
        <span className={"md-value"}>{indexData.date.substring(0, indexData.date.length-3)}</span>
      </div>
    ) : (
      ""
    );
  };

  renderGeoSpatialViews = (viewType) => {
    this.renderedGeoRefs = [];
    if (viewType === this.default.geoSpatialViews.single)
      return (
        <GeoSpatial
          ref={(ref) => this.renderedGeoRefs.push(ref)}
          height={this.chartHeight}
          width={this.state.chartWidth}
          zoom={4}
          mapURL={this.default.layerOptions[this.state.mapLayer]}
          enableComment={true}
          regionBorder={true}
          scale={[-1, 15]}
          label={this.renderLabel(this.state.selectedIndexData)}
          dataSourceOption={"external"}
          dataFileName={
            this.state.selectedIndexData
              ? this.state.selectedIndexData.filePrefix +
                this.state.selectedIndexData.filename
              : undefined
          }
          //[DY] 2020.02.02
          setSelectedRegion = {this.setSelectedRegion}
          setSelectedSeason = {this.setSelectedSeason}
          setSelectedSeason_Region = {this.setSelectedSeason_Region}

        />
      );
    else if (viewType === this.default.geoSpatialViews.comparison) {
      return (
        <Row>
          <Col sm={4}>
            <GeoSpatial
              ref={(ref) => this.renderedGeoRefs.push(ref)}
              height={this.chartHeight}
              width={this.state.controlWidth / 3}
              zoom={3}
              mapURL={this.default.layerOptions[this.state.mapLayer]}
              enableComment={true}
              regionBorder={true}
              scale={[-1, 15]}
              label={this.renderLabel(this.state.selectedIndexData)}
              dataSourceOption={"external"}
              dataFileName={
                this.state.selectedIndexData
                  ? this.state.selectedIndexData.filePrefix +
                    this.state.selectedIndexData.filename
                  : undefined
              }
            />
          </Col>
          <Col sm={4} ref={(ref) => (this.comparisonGeoView = ref)}>
            <GeoSpatial
              ref={(ref) => this.renderedGeoRefs.push(ref)}
              height={this.chartHeight}
              width={this.state.controlWidth / 3}
              zoom={3}
              mapURL={this.default.layerOptions[this.state.mapLayer]}
              regionBorder={true}
              scale={[-14, 14]}
              label={"PRCP Difference"}
              dataSourceOption={"external"}
              dataFileName={"test_ri2.geojson"}
              // dataFileName={this.filePrefix + this.state.selectedIndexData.filename}
            />
          </Col>
          <Col sm={4} ref={(ref) => (this.secondGeoView = ref)}>
            <GeoSpatial
              ref={(ref) => this.renderedGeoRefs.push(ref)}
              height={this.chartHeight}
              width={this.state.controlWidth / 3}
              enableComment={true}
              zoom={3}
              mapURL={this.default.layerOptions[this.state.mapLayer]}
              regionBorder={true}
              scale={[-1, 15]}
              label={this.renderLabel(this.state.selectedSecondIndexData)}
              dataSourceOption={"external"}
              dataFileName={
                this.state.selectedSecondIndexData
                  ? this.state.selectedSecondIndexData.filePrefix +
                    this.state.selectedSecondIndexData.filename
                  : undefined
              }
            />
          </Col>
        </Row>
      );
    } else if (viewType === this.default.geoSpatialViews.multi) {
      let rowCount = 3;
      return (
        <div>
          {_.map(_.range(rowCount), (c) => {
            let indexList = _.slice(
              this.state.multiViewIndices,
              c * rowCount,
              c * rowCount + 3
            );
            return (
              <Row>
                {_.map(indexList, (i) => {
                  let indexData = this.state.timeStamps[i];
                  let filePrefix = indexData.filePrefix;
                  let fileName = indexData.filename;
                  return (
                    <Col sm={4}>
                      <GeoSpatial
                        ref={(ref) => this.renderedGeoRefs.push(ref)}
                        height={this.chartHeight / rowCount}
                        width={this.state.controlWidth / 3}
                        enableComment={true}
                        zoom={3}
                        disableZoomControl={true}
                        controlTopLeft={false}
                        lat={36.47022751501865}
                        lng={-98.84786345942193}
                        mapURL={this.default.layerOptions[this.state.mapLayer]}
                        regionBorder={true}
                        scale={[-1, 15]}
                        label={this.renderLabel(indexData)}
                        dataSourceOption={"external"}
                        dataFileName={filePrefix + fileName}
                      />
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
      );
    }
  };

  render() {
    return (
      <div style={{ height: this.canvasHeight, width: this.canvasWidth }}>
        <Row className={"custom-layout"}>
          <Col sm={4}>
            <SummaryCharts
              filePrefix={this.filePrefix}
              sChartWidth={this.sChartWidth}
              sFrameHeight={this.sFrameHeight}
              // [DY] 2020.02.02
              // doReordering={true}
              // selectedRegion={"west"}
              // selectedSeason={"summer"}
              doReordering={this.state.doReordering}
              selectedRegion={this.state.selectedRegion}
              selectedSeason={this.state.selectedSeason}
            />
          </Col>
          <Col sm={8}>
            <div className={"content-wrapper component right"}>
              {this.renderGeoSpatialViews(this.state.geoSpatialView)}
            </div>
            <div
              className={"content-wrapper component right"}
              style={{ height: this.controlHeight }}
            >
              <MapViewController
                changeGeoSpatialView={this.changeGeoSpatialView}
                geoSpatialViews={this.default.geoSpatialViews}
                layerOptions={this.default.layerOptions}
                timestamps={this.state.timeStamps}
                alertController={this.alertController}
                changeMapURL={this.changeMapURL}
              />
              <SliderNavigator
                height={this.controlHeight - 20}
                width={this.controlWidth - 40}
                selected={this.startIndex}
                info={this.getTimeLineInfo(this.state.selectedIndexData)}
                timestamps={this.state.timeStamps}
                // dataFilePrefix={this.filePrefix}
                onHoverInfo={this.getInfoByIndex}
                onTimeChange={this.onDataSetChange}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ExploreNetCDF;
