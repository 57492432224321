import React from "react";
import {withBaseIcon} from "react-icons-kit";

import {
  ic_bubble_chart, ic_playlist_add_check, ic_transform, ic_home, ic_file_upload, ic_settings,
  ic_touch_app, ic_multiline_chart, ic_description, ic_keyboard_tab, ic_library_add, ic_view_column
} from "react-icons-kit/md/";
import {userCircle, lineChart, barChart, thLarge, infoCircle} from "react-icons-kit/fa";
import {ic_insert_chart} from 'react-icons-kit/md/ic_insert_chart'
import {th} from 'react-icons-kit/fa/th'
// import {boxBlank, coins, optionsSettings} from "react-icons-kit/metrize";
// import {spectrum} from 'react-icons-kit/iconic/spectrum'


const BrandIconContainer = withBaseIcon({
  size: 42, style: {color: "#f8f8f8"}
});

const ChartIconContainer = withBaseIcon({
  size: 24, style: {color: "#f8f8f8"}
});

const SidebarIconContainer = withBaseIcon({
  size: 24, style: {color: "#f8f8f8"}
});

const UserIconContainer = withBaseIcon({
  size: 24, style: {color: "#154b8f"}
});

const UserIconContainer_md_white = withBaseIcon({
  size: 50, style: {color: "#f8f8f8"}
});
const UserIconContainer_md_blue = withBaseIcon({
  size: 50, style: {color: "#007bff"}
});


const OptionIconContainer = withBaseIcon({
  size: 20, style: {color: "gray"}
});

const OptionIconContainerWhite = withBaseIcon({
  size: 20, style: {color: "white"}
});

const OptionIconContainerBlue = withBaseIcon({
  size: 20, style: {color: "#007bff"}
});

const SmallOptionIconContainerBlue = withBaseIcon({
  size: 16, style: {color: "#0060bf"}
});

export const NavIcon = () => <BrandIconContainer icon={ic_bubble_chart}/>;

// SIDEBAR ICON
export const DataSelectionIcon = () => <SidebarIconContainer icon={ic_playlist_add_check}/>;
export const DataTransformationIcon = () => <SidebarIconContainer icon={ic_transform}/>;
export const HomeIcon = () => <SidebarIconContainer icon={ic_home}/>;
export const ComparisonIcon = () => <SidebarIconContainer icon={ic_view_column}/>;
export const RecommendationIcon = () => <SidebarIconContainer icon={ic_touch_app}/>;
export const FileUploadIcon = () => <SidebarIconContainer icon={ic_file_upload}/>;
export const SettingsIcon = () => <SidebarIconContainer icon={ic_settings}/>;
export const ChartIcon = () => <SidebarIconContainer icon={ic_multiline_chart}/>;
export const DocumentationIcon = () => <SidebarIconContainer icon={ic_description}/>;
export const UserIcon = () => <UserIconContainer icon={userCircle}/>;
export const UserIcon_md_blue = () => <UserIconContainer_md_blue icon={userCircle}/>;
export const UserIcon_md_white = () => <UserIconContainer_md_white icon={userCircle}/>;
export const UserIcon_md = () => <UserIconContainer_md_white icon={userCircle}/>;

// OPTION ICON
export const EnterIcon = () => <OptionIconContainer icon={ic_keyboard_tab}/>;
export const AddIcon = () => <OptionIconContainerWhite icon={ic_library_add}/>;
export const GridIcon = () => <OptionIconContainerBlue icon={th}/>;

export const ThIcon = () => <SmallOptionIconContainerBlue icon={thLarge}/>;
export const InfoIcon = () => <SmallOptionIconContainerBlue icon={infoCircle}/>;

// CHART ICON
export const LineChartIcon = () => <ChartIconContainer icon={lineChart}/>;
export const BarChartIcon = () => <ChartIconContainer icon={barChart}/>;
export const TreeMapChartIcon = () => <ChartIconContainer icon={ic_insert_chart}/>;