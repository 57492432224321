import React from "react";


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div>dashboard</div>
    );
  }
}

export default Dashboard;